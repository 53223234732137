import React from "react";

import { Outlet, useRoutes } from "react-router-dom";

// Import scss
import "./assets/scss/theme.scss";
import "./assets/scss/gomelius.scss";
import "./assets/scss/select-filter.scss";

import { AuthProvider } from "./contexts/authContext";
import routes from "./routes";
import { BroadcastProvider } from "./contexts/broadcastContext";

const App = () => {
  const content = useRoutes(routes);
  return (
    <React.Fragment>
      <AuthProvider>
        <BroadcastProvider>
          {content}
          <Outlet />
        </BroadcastProvider>
      </AuthProvider>
    </React.Fragment>
  );
};

export default App;
