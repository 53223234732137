import classNames from "classnames";
import { mapToCssModules } from "src/SmartR/Utils/utils";
import { Icon } from "@ailibs/feather-react-ts";

interface BotaoConfiguracaoPropTypes {
  className?: string;
  onClickHandle?: any;
}
export function BotaoConfiguracao(props: BotaoConfiguracaoPropTypes) {
  const { className, onClickHandle } = props;

  const classes = mapToCssModules(
    classNames(className, "btn btn-secondary btn-sm btn-config"),
  );
  return (
    <>
      <button type="button" onClick={onClickHandle} className={classes}>
        <Icon name="settings" className="icon-md" />
        {""}
      </button>
    </>
  );
}
