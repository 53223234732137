export const WebHelper = {
  downloadFileLink: function (url, name) {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = name;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },
  downloadFile: async (url, fileName) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = fileName;
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Erro ao baixar o arquivo:", error);
    }
  },
  cleanFileName: function (name) {
    if (name) {
      var charactersNotAllowed = /[\|&;\$%@"<>\(\)\+,\[\]\{\}\/\\]/g;
      var cleanName = name.replace(charactersNotAllowed, "");

      return cleanName;
    }
    return "";
  },  
};
