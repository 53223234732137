import React, { useState, ReactNode } from "react";
import { TabProps } from "./Tab";
import classNames from "classnames";
import { mapToCssModules } from "src/SmartR/Utils/utils";

interface NavTabProps {
  children: ReactNode;
  className?: string;
  onTabChange?: (
    prevTabId: string | number | undefined,
    newTabId: string | number | undefined
  ) => void; // Evento onTabChange
}

export const NavTab = ({ className, children, onTabChange }: NavTabProps) => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = React.Children.toArray(
    children
  ) as React.ReactElement<TabProps>[];

  const handleTabClick = (tabIndex: number) => {
    const prevTab = tabs[activeTab];
    const newTab = tabs[tabIndex];

    // Disparar evento onLeave na aba anterior
    if (prevTab?.props.onLeave) {
      prevTab.props.onLeave();
    }

    // Disparar evento onEnter na nova aba
    if (newTab?.props.onEnter) {
      newTab.props.onEnter();
    }

    // Disparar o evento de mudança de aba
    if (onTabChange) {
      onTabChange(
        prevTab?.props.identificador ?? activeTab,
        newTab?.props.identificador ?? tabIndex
      );
    }

    // Atualizar a aba ativa
    setActiveTab(tabIndex);
  };

  let classes = mapToCssModules(
    classNames(className, "nav-tabs-custom nav nav-tabs")
  );

  return (
    <>
      <ul className={classes}>
        {tabs.map((tab, index) => (
          <li
            className={classNames(tab.props.className, "nav-item")}
            key={index}
          >
            <a
              className={`nav-link ${activeTab === index ? "active" : ""}`}
              onClick={() => handleTabClick(index)}
              href="#"
            >
              {tab.props.title}
            </a>
          </li>
        ))}
      </ul>

      <div className="tab-content p-3 text-muted">
        {tabs.map((tab, index) =>
          // Passar a propriedade isActive para cada Tab
          React.cloneElement(tab, { isActive: activeTab === index })
        )}
      </div>
    </>
  );
};
