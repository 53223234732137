import gomelius from "src/api/gomelius";
import { userForgetPasswordSuccess, userForgetPasswordError } from "./reducer";

export const userForgetPassword: any = (user, history) => async (dispatch) => {
  gomelius
    .post(null, `Auth/ResetarSenha/${user.Email}`)
    .then(() => {
      dispatch(
        userForgetPasswordSuccess(
          "Um link foi enviado para o seu e-mail. Verifique neste e-mail os passos para recuperar sua senha."
        )
      );
    })
    .catch((error: any) => {
      dispatch(userForgetPasswordError(error));
    });
};
