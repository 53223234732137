// NotificacaoSlice.ts
import { createSlice } from "@reduxjs/toolkit";

interface NotificacaoState {
  atualizar: boolean;
}

export const initialState: NotificacaoState = {
  atualizar: false,
};

const NotificacaoSlice = createSlice({
  name: "Notificacao",
  initialState,
  reducers: {
    atualizar(state) {
      state.atualizar = true;
    },
  },
});

export const { atualizar } = NotificacaoSlice.actions;

export default NotificacaoSlice.reducer;
