import classNames from "classnames";
import { mapToCssModules } from "src/SmartR/Utils/utils";

interface cardPropTypes {
  children?: React.ReactNode;
  className?: string;
  id?: string;
  title?: string;
}
export function Card(props: cardPropTypes) {
  const { id, className, title, children, ...attributes } = props;

  const classes = mapToCssModules(classNames(className, "card"));
  return (
    <>
      <div id={id} className={classes} {...attributes}>
        <div className="card-body">
          {title && <h4 className="card-title mb-4">{title}</h4>}
          {children}
        </div>
      </div>
    </>
  );
}
