import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

//Import Icons

//import withRouter
import withRouter from "../Common/withRouter";
import { menuItems } from "../CommonForBoth/Menu";
import classNames from "classnames";

const Navbar = (props: any) => {
  const path = useLocation();

  const [email, setemail] = useState<boolean>(false);
  const [dashoboard, setdashoboard] = useState<boolean>(false);

  const pathName = path.pathname;

  const FuncaoItem = ({ funcao }) => {
    return (
      <Link to={funcao.link} className="dropdown-item">
        {funcao.icon && <i className={`mdi ${funcao.icon} nav-icon`} />}
        <span className="menu-item">{funcao.label}</span>
        {funcao.badge && (
          <span className={"badge rounded-pill " + funcao.badgecolor}>
            {funcao.badge}
          </span>
        )}
      </Link>
    );
  };

  const ModuloItem = ({ modulo }) => {
    return (
      <div className="dropdown">
        <Link
          to="/#"
          className="dropdown-item dropdown-toggle arrow-none"
          onClick={(e) => {
            e.preventDefault();
            setemail(!email);
          }}
        >
          {modulo.icon && <i className={`mdi ${modulo.icon}`} />}
          <span>{modulo.label}</span>
          <div className="arrow-down"></div>
        </Link>
        <div className="dropdown-menu">
          {(modulo.funcoes || [])
            .filter((funcao) => funcao.menu)
            .map((funcao, key) => {
              return <FuncaoItem key={key} funcao={funcao} />;
            })}
        </div>
      </div>
    );
  };

  const GrupoItem = ({ grupo }) => {
    return (
      <>
        <li className="nav-item dropdown">
          <Link
            className="nav-link dropdown-toggle arrow-none"
            to="/#"
            onClick={(e) => {
              e.preventDefault();
              setdashoboard(!dashoboard);
            }}
          >
            {grupo.icon && <i className={`mdi ${grupo.icon}`} />}
            <span>{grupo.label}</span> <div className="arrow-down"></div>
          </Link>
          <div className="dropdown-menu">
            {(grupo.funcoes || [])
              .filter((funcao) => funcao.menu)
              .map((funcao, key) => {
                return <FuncaoItem key={key} funcao={funcao} />;
              })}
            {(grupo.modulos || []).map((modulo, key) => {
              return <ModuloItem key={key} modulo={modulo} />;
            })}
          </div>
        </li>
      </>
    );
  };

  useEffect(() => {
    var matchingMenuItem = null;
    var ul: any = document.getElementById("navigation");
    var items: any = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });

  function activateParentDropdown(item: any) {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <div className="collapse navbar-collapse" id="topnav-menu-content">
              <ul className="navbar-nav">
                {(menuItems || []).map((item, key) => {
                  return <GrupoItem key={key} grupo={item} />;
                })}
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Navbar);
