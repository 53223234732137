import React, { useState, useEffect, useRef } from "react";
import { Icon } from "@ailibs/feather-react-ts";
import "./Search.css"; // Importe os estilos CSS
import gomelius from "src/api/gomelius";
import { Util } from "src/util";
import { CNPJ, CPF, TELEFONE } from "src/constants";

const Search = (props) => {
  const [isSearch, setSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [clientList, setClientList] = useState([]);
  const inputRef = useRef(null); // Ref para o input

  useEffect(() => {
    // Definir o foco no input quando o componente for renderizado
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [isSearch]); // Executado apenas na montagem do componente

  const handleInputChange = async (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Verifica se a consulta possui pelo menos 3 caracteres antes de pesquisar
    if (query.length >= 2) {
      fetchClients(query);
    } else {
      setClientList([]); // Limpa a lista de clientes se a consulta for menor que 3 caracteres
    }
  };

  const fetchClients = async (query: string) => {
    try {
      const lista: any = await gomelius.obterLista(
        `Cliente/PesquisaRapida/${query}`
      );
      setClientList(lista);
    } catch (error) {
      console.error("Erro ao buscar clientes:", error);
    }
  };

  const handleResultClick = (id) => {
    // Redireciona para a rota /CRM/:id ao clicar em um resultado da pesquisa
    window.location.href = `/CRM/${id}`;
    setSearch(false); // Fecha o dropdown de resultados ao clicar
    setSearchQuery(""); // Limpa o campo de pesquisa
    setClientList([]); // Limpa a lista de resultados
  };

  const highlightText = (text, query) => {
    // Verifica se o texto contém a consulta e destaca o trecho correspondente
    const regex = new RegExp(`(${query})`, "gi");
    return text.replace(regex, "<strong>$1</strong>");
  };

  return (
    <>
      <div className="dropdown d-inline-block d-none d-lg-block">
        <button
          type="button"
          className="btn header-item"
          id="page-header-search-dropdown"
          onClick={() => setSearch(!isSearch)}
        >
          <Icon name="search" className="icon-sm" />
        </button>
        <div
          className={
            isSearch
              ? "dropdown-menu dropdown-menu-lg search dropdown-menu-end p-0 show"
              : "dropdown-menu dropdown-menu-lg search dropdown-menu-end p-0"
          }
          aria-labelledby="page-header-search-dropdown"
        >
          <form className="p-2">
            <div className="search-box">
              <div className="position-relative">
                <input
                  ref={inputRef} // Referência para o input
                  type="text"
                  className="form-control rounded bg-light border-0"
                  placeholder="Digite o nome para pesquisar o cliente..."
                  value={searchQuery}
                  onChange={handleInputChange}
                />
                <i className="mdi mdi-magnify search-icon"></i>
              </div>
            </div>
          </form>
          {clientList.length > 0 && (
            <div className="search-results">
              <ul>
                {clientList.map((cliente) => (
                  <li
                    className={cliente.situacao === 2 ? "result-inactive" : ""}
                    key={cliente.id}
                    onClick={() => handleResultClick(cliente.id)}
                  >
                    <span
                      className="nome"
                      dangerouslySetInnerHTML={{
                        __html: highlightText(cliente.nome, searchQuery),
                      }}
                    />

                    {cliente.numeroDocumento && (
                      <small className="numero-documento">
                        {Util.applyMask(
                          cliente.numeroDocumento,
                          cliente.tipo === 1 ? CPF : CNPJ
                        )}
                      </small>
                    )}
                    {cliente.telefone && (
                      <small className="telefone">
                        {Util.applyMask(cliente.telefone, TELEFONE)}
                      </small>
                    )}
                    {cliente.email && (
                      <small
                        className="email"
                        dangerouslySetInnerHTML={{
                          __html: highlightText(cliente.email, searchQuery),
                        }}
                      />
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Search;
