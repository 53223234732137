import React, { useState } from "react";
import ImageTypes from "./types"; // Importa o enumerador
import "./index.scss"; // Importa o SCSS

export const ImageUploader = ({
  width = 468,
  height = 295,
  label = "Click here and select the image",
  onImageSelected = (file: any) => {},
  onClosePreview = () => {},
}) => {
  const [preview, setPreview] = useState(null);

  // Função que lida com a seleção de imagem
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && Object.values(ImageTypes).includes(file.type)) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Image = reader.result;
        setPreview(base64Image); // Exibe a imagem em preview (Base64)

        // Chama o callback passando a imagem em Base64
        onImageSelected(base64Image);
      };
      reader.readAsDataURL(file);
    } else {
      alert("Unsupported file type. We only accept JPEG, PNG, and TIFF.");
    }
  };

  // Função que lida com o fechamento da visualização da imagem
  const handleClosePreview = () => {
    setPreview(null);
    onClosePreview(); // Chama o callback ao fechar a visualização
  };

  return (
    <div
      className="image-uploader"
      style={{ width: `${width}px`, height: `${height}px` }}
    >
      {preview ? (
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
          <img
            src={preview}
            alt="Logo Preview"
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
          <button
            className="close-button" // Estilo vindo do CSS separado
            onClick={handleClosePreview}
          >
            X
          </button>
        </div>
      ) : (
        <>
          <input
            type="file"
            id="logo-uploader"
            accept={Object.values(ImageTypes).join(",")}
            onChange={handleImageChange}
          />
          <label htmlFor="logo-uploader" style={{ lineHeight: `${height}px` }}>
            {label}
          </label>
        </>
      )}
    </div>
  );
};
