import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Tooltip,
  TooltipPosition,
} from "src/components";
import { useCallback, useState } from "react";
export interface DropdownOptionListOptionPropTypes {
  toolTip?: string;
  iconClassName?: string;
  text?: string;
  link?: string;
  visible?: boolean;
  enabled?: boolean;
  onClickHandler?: any;
}
export interface DropdownOptionListPropTypes {
  toolTip?: string;
  iconClassName?: string;
  options: DropdownOptionListOptionPropTypes[];
}

export function DropdownOptionList(props: DropdownOptionListPropTypes) {
  const { iconClassName = "mdi-plus-box-outline", toolTip, options } = props;

  const [menuAberto, setMenuAberto] = useState<boolean>(false);

  const handleItemClick = useCallback(
    (event: React.MouseEvent, option: DropdownOptionListOptionPropTypes) => {
      if (option.onClickHandler) {
        option.onClickHandler(event);
      } else if (option.link) {
        window.location.href = option.link;
      }
    },
    []
  );

  return (
    <>
      <Dropdown isOpen={menuAberto} onToggle={() => setMenuAberto(!menuAberto)}>
        <DropdownToggle tag="a" className="text-reset">
          <span className="text-muted">
            {toolTip ? (
              <Tooltip position={TooltipPosition.Top} text={toolTip}>
                <i className={`icon mdi ${iconClassName} ms-1 font-size-16`} />
              </Tooltip>
            ) : (
              <i className={`icon mdi ${iconClassName} ms-1 font-size-16`} />
            )}
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {options.map((option, index) => (
            <DropdownItem
              key={index}
              className="text-reset"
              icon={option.iconClassName}
              tooltip={option.toolTip}
              visible={option.visible}
              enabled={option.enabled}
              onClick={(event) => handleItemClick(event, option)}
            >
              {option.text}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
}
