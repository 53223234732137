import React from "react";

import {
  changeLayout,
  changeLayoutWidth,
  changeLayoutPosition,
  changelayoutMode,
  changeTopbarTheme,
  changeSidebarType,
  changeSidebarTheme,
  openConfiguration,
} from "../../slices/thunks";

//constants
import {
  layoutTypes,
  layoutTheme,
  layoutWidthTypes,
  layoutPositions,
  topBarThemeTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes,
} from "../../constants/layout";

//SimpleBar
import SimpleBar from "simplebar-react";

import { Link } from "react-router-dom";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

const RightSidebar = () => {
  const dispatch = useDispatch();

  const selectLayoutProperties = createSelector(
    (state: any) => state.Layout,
    (layout: any) => ({
      layoutType: layout.layoutType,
      layoutMode: layout.layoutMode,
      layoutWidth: layout.layoutWidth,
      layoutPosition: layout.layoutPosition,
      topbarTheme: layout.topbarTheme,
      leftSideBarType: layout.leftSideBarType,
      leftSideBarTheme: layout.leftSideBarTheme,
    }),
  );
  // Inside your component
  const {
    layoutType,
    layoutMode,
    layoutWidth,
    layoutPosition,
    topbarTheme,
    leftSideBarType,
    leftSideBarTheme,
  } = useSelector(selectLayoutProperties);
  const onClose = () => {
    dispatch(openConfiguration(false));
  };
  return (
    <React.Fragment>
      <div>
        <SimpleBar className="vh-100">
          <div className="rightbar-title d-flex align-items-center bg-dark p-3">
            <h5 className="m-0 me-2 text-white">Personalizar</h5>
            <Link to="#" onClick={onClose} className="right-bar-toggle ms-auto">
              <i className="mdi mdi-close noti-icon"></i>
            </Link>
          </div>

          <hr className="m-0" />

          <div className="p-4">
            <h6 className="mb-3">Estrutura</h6>
            <div className="form-check form-check-inline">
              <input
                type="radio"
                id="radioVertical"
                name="layout"
                value={layoutTypes.VERTICAL}
                className="form-check-input"
                checked={layoutType === layoutTypes.VERTICAL}
                onChange={(e) => {
                  if (e.target.checked) {
                    dispatch(changeLayout(e.target.value, true));
                  }
                }}
              />
              <label htmlFor="radioVertical" className="form-check-label">
                Vertical
              </label>
            </div>
            {"   "}
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="layout"
                id="layout-horizontal"
                value={layoutTypes.HORIZONTAL}
                checked={layoutType === layoutTypes.HORIZONTAL}
                onChange={(e) => {
                  if (e.target.checked) {
                    dispatch(changeLayout(e.target.value, true));
                  }
                }}
              />
              <label className="form-check-label" htmlFor="layout-horizontal">
                Horizontal
              </label>
            </div>
            <h6 className="mt-4 mb-3 pt-2">Tema</h6>
            <div className="form-check form-check-inline">
              <input
                type="radio"
                id="radioThemeLight"
                name="radioTheme"
                value={layoutTheme.LIGHTMODE}
                className="form-check-input"
                checked={layoutMode === layoutTheme.LIGHTMODE}
                onChange={(e) => {
                  if (e.target.checked) {
                    dispatch(changelayoutMode(e.target.value, true));
                  }
                }}
              />
              <label className="form-check-label" htmlFor="radioThemeLight">
                Claro
              </label>
            </div>
            {"   "}
            <div className="form-check form-check-inline">
              <input
                type="radio"
                id="radioThemeDark"
                name="radioTheme"
                value={layoutTheme.DARKMODE}
                className="form-check-input"
                checked={layoutMode === layoutTheme.DARKMODE}
                onChange={(e) => {
                  if (e.target.checked) {
                    dispatch(changelayoutMode(e.target.value, true));
                  }
                }}
              />
              <label className="form-check-label" htmlFor="radioThemeDark">
                Escuro
              </label>
            </div>

            <h6 className="mt-4 mb-3">Estilo</h6>
            <div className="form-check form-check-inline">
              <input
                type="radio"
                id="radioFluid"
                name="radioWidth"
                value={layoutWidthTypes.FLUID}
                className="form-check-input"
                checked={layoutWidth === layoutWidthTypes.FLUID}
                onChange={(e) => {
                  if (e.target.checked) {
                    dispatch(changeLayoutWidth(e.target.value, true));
                  }
                }}
              />
              <label htmlFor="radioFluid" className="form-check-label">
                Fluído
              </label>
            </div>
            {"   "}
            <div className="form-check form-check-inline">
              <input
                type="radio"
                id="radioBoxed"
                name="radioWidth"
                value={layoutWidthTypes.BOXED}
                className="form-check-input"
                checked={layoutWidth === layoutWidthTypes.BOXED}
                onChange={(e) => {
                  if (e.target.checked) {
                    dispatch(changeLayoutWidth(e.target.value, true));
                  }
                }}
              />
              <label htmlFor="radioBoxed" className="form-check-label">
                Caixa
              </label>
            </div>
            <h6 className="mt-4 mb-3">Posição</h6>
            <div className="form-check form-check-inline">
              <input
                type="radio"
                id="layout-position-fixed"
                name="layout-position"
                value={layoutPositions.SCROLLABLE_FALSE}
                className="form-check-input"
                checked={layoutPosition === layoutPositions.SCROLLABLE_FALSE}
                onChange={(e) => {
                  if (e.target.checked) {
                    dispatch(changeLayoutPosition(e.target.value, true));
                  }
                }}
              />
              <label
                className="form-check-label"
                htmlFor="layout-position-fixed"
              >
                Fixo
              </label>
            </div>

            <div className="form-check form-check-inline">
              <input
                type="radio"
                id="layout-position-scrollable"
                name="layout-position"
                value={layoutPositions.SCROLLABLE_TRUE}
                className="form-check-input"
                checked={layoutPosition === layoutPositions.SCROLLABLE_TRUE}
                onChange={(e) => {
                  if (e.target.checked) {
                    dispatch(changeLayoutPosition(e.target.value, true));
                  }
                }}
              />
              <label
                className="form-check-label"
                htmlFor="layout-position-scrollable"
              >
                Deslizável
              </label>
            </div>

            <h6 className="mt-4 mb-3">Barra Superior Tema</h6>

            <div className="form-check form-check-inline">
              <input
                type="radio"
                id="topbar-color-light"
                name="topbar-color"
                value={topBarThemeTypes.LIGHT}
                className="form-check-input"
                checked={topbarTheme === topBarThemeTypes.LIGHT}
                onChange={(e) => {
                  if (e.target.checked) {
                    dispatch(changeTopbarTheme(e.target.value, true));
                  }
                }}
              />
              <label className="form-check-label" htmlFor="topbar-color-light">
                Claro
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                type="radio"
                id="topbar-color-dark"
                name="topbar-color"
                value={topBarThemeTypes.DARK}
                className="form-check-input"
                checked={topbarTheme === topBarThemeTypes.DARK}
                onChange={(e) => {
                  if (e.target.checked) {
                    dispatch(changeTopbarTheme(e.target.value, true));
                  }
                }}
              />
              <label className="form-check-label" htmlFor="topbar-color-dark">
                Escuro
              </label>
            </div>
            {layoutType === "vertical" ? (
              <React.Fragment>
                <h6 className="mt-4 mb-3 sidebar-setting">Barra Lateral</h6>

                <div className="form-check sidebar-setting">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="sidebar-size"
                    id="sidebar-size-default"
                    value={leftSidebarTypes.DEFAULT}
                    checked={leftSideBarType === leftSidebarTypes.DEFAULT}
                    onChange={(e) => {
                      if (e.target.checked) {
                        dispatch(changeSidebarType(e.target.value, true));
                      }
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="sidebar-size-default"
                  >
                    Padrão
                  </label>
                </div>
                <div className="form-check sidebar-setting">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="sidebar-size"
                    id="sidebar-size-compact"
                    value={leftSidebarTypes.COMPACT}
                    checked={leftSideBarType === leftSidebarTypes.COMPACT}
                    onChange={(e) => {
                      if (e.target.checked) {
                        dispatch(changeSidebarType(e.target.value, true));
                      }
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="sidebar-size-compact"
                  >
                    Compacto
                  </label>
                </div>
                <div className="form-check sidebar-setting">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="sidebar-size"
                    id="sidebar-size-small"
                    value={leftSidebarTypes.ICON}
                    checked={leftSideBarType === leftSidebarTypes.ICON}
                    onChange={(e) => {
                      if (e.target.checked) {
                        dispatch(changeSidebarType(e.target.value, true));
                      }
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="sidebar-size-small"
                  >
                    Pequeno
                  </label>
                </div>
                <h6 className="mt-4 mb-3 sidebar-setting">
                  Tema Barra Lateral
                </h6>

                <div className="form-check sidebar-setting">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="sidebar-color"
                    id="sidebar-color-light"
                    value={leftSideBarThemeTypes.LIGHT}
                    checked={leftSideBarTheme === leftSideBarThemeTypes.LIGHT}
                    onChange={(e) => {
                      if (e.target.checked) {
                        dispatch(changeSidebarTheme(e.target.value, true));
                      }
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="sidebar-color-light"
                  >
                    Claro
                  </label>
                </div>
                <div className="form-check sidebar-setting">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="sidebar-color"
                    id="sidebar-color-dark"
                    value={leftSideBarThemeTypes.DARK}
                    checked={leftSideBarTheme === leftSideBarThemeTypes.DARK}
                    onChange={(e) => {
                      if (e.target.checked) {
                        dispatch(changeSidebarTheme(e.target.value, true));
                      }
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="sidebar-color-dark"
                  >
                    Escuro
                  </label>
                </div>
                <div className="form-check sidebar-setting">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="sidebar-color"
                    id="sidebar-color-brand"
                    value={leftSideBarThemeTypes.COLORED}
                    checked={leftSideBarTheme === leftSideBarThemeTypes.COLORED}
                    onChange={(e) => {
                      if (e.target.checked) {
                        dispatch(changeSidebarTheme(e.target.value, true));
                      }
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="sidebar-color-brand"
                  >
                    Azul
                  </label>
                </div>
              </React.Fragment>
            ) : null}
          </div>
        </SimpleBar>
      </div>
      <div className="rightbar-overlay" />
    </React.Fragment>
  );
};

export default RightSidebar;
