export const GramaticaHelper = {
  obterArtigoGramatical: (palavra) => {
    const vogais = ["a", "e", "i", "o", "u"];

    if (!palavra) {
      return "";
    }

    const primeiraLetra = palavra[0].toLowerCase();

    if (vogais.includes(primeiraLetra)) {
      return "a";
    } else {
      return "o";
    }
  },
};
