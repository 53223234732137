import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { useDropdownContext } from "../Dropdown";
import { usePopper } from "react-popper";
import { mapToCssModules } from "src/SmartR/Utils/utils";
import "./index.scss";
interface dropdownMenuPropTypes {
  children?: React.ReactNode;
  className?: string;
  dir?: string;
}

export function DropdownMenu(props: dropdownMenuPropTypes) {
  const { className, dir = "left", children, ...attributes } = props;

  const { isOpen, referenceElement } = useDropdownContext();
  const [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>(null);

  const { styles, attributes: popperAttributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: dir === "left" ? "bottom-start" : "bottom-end",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 8], // Desloca o popper 8px para baixo
          },
        },
        {
          name: "preventOverflow",
          options: {
            boundary: "clippingParents", // Evita que o popper ultrapasse a borda da viewport
          },
        },
        {
          name: "flip",
          options: {
            fallbackPlacements: ["top-start", "top-end"], // Alternativas se o espaço estiver limitado
          },
        },
        {
          name: "computeStyles",
          options: {
            adaptive: true, // Adapta os estilos para melhorar a renderização
          },
        },
      ],
    }
  );

  const classes = mapToCssModules(
    classNames(className, "dropdown-menu", { show: isOpen })
  );

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      ref={setPopperElement}
      style={styles.popper}
      {...popperAttributes.popper}
      role="menu"
      dir={dir}
      className={classes}
      {...attributes}
    >
      {children}
    </div>,
    document.body
  );
}
