import { useCallback, useRef, useState } from "react";
import "./index.scss";
import classNames from "classnames";

import React from "react";
export enum TooltipPosition {
  Top = 1,
  Bottom = 2,
  Left = 3,
  Right = 4,
}
export interface TooltipPropTypes {
  className?: string;
  text?: string;
  position?: TooltipPosition;
  children?: React.ReactNode;
}

export function Tooltip(props: TooltipPropTypes) {
  const { className, children, text, position = TooltipPosition.Top } = props;
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipElementRef = useRef(null);

  const handleTooltipMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleTooltipMouseLeave = () => {
    setShowTooltip(false);
  };

  function mapToCssModules(className = "") {
    return className
      .split(" ")
      .map((c) => c)
      .join(" ");
  }
  const tooltipRef = useCallback(
    (node) => {
      if (node !== null) {
        const overlayRect = tooltipElementRef.current.getBoundingClientRect();
        const tooltipWidth =
          node.getBoundingClientRect().width < 250
            ? node.getBoundingClientRect().width
            : 250;
        const tooltipRect = node.getBoundingClientRect();
        let top, left;

        switch (position) {
          case TooltipPosition.Top:
            top = overlayRect.top - tooltipRect.height - 10;
            left =
              overlayRect.left + overlayRect.width / 2 - tooltipRect.width / 2;
            break;
          case TooltipPosition.Bottom:
            top = overlayRect.top + overlayRect.height + 10;
            left =
              overlayRect.left + overlayRect.width / 2 - tooltipRect.width / 2;
            break;
          case TooltipPosition.Left:
            top =
              overlayRect.top + overlayRect.height / 2 - tooltipRect.height / 2;
            left = overlayRect.left - tooltipRect.width - 10;
            break;
          case TooltipPosition.Right:
            top =
              overlayRect.top + overlayRect.height / 2 - tooltipRect.height / 2;
            left = overlayRect.left + overlayRect.width + 10;
            break;
          default:
            break;
        }

        node.style.top = `${top}px`;
        node.style.left = `${left}px`;
        node.style.visibility = "visible";
      }
    },
    [position]
  );
  let tooltipClass =
    position === TooltipPosition.Top
      ? "top"
      : position === TooltipPosition.Bottom
      ? "bottom"
      : position === TooltipPosition.Left
      ? "left"
      : "right";
  tooltipClass = mapToCssModules(
    classNames(className, "tooltip", tooltipClass, "show")
  );
  const toolTipDiv = showTooltip ? (
    <div
      ref={tooltipRef}
      style={{ visibility: "hidden" }}
      className={tooltipClass}
    >
      {text}
    </div>
  ) : null;
  const childrenWithEvents = React.isValidElement(children)
    ? React.cloneElement(children as React.ReactElement<any>, {
        onMouseEnter: handleTooltipMouseEnter,
        onMouseLeave: handleTooltipMouseLeave,
        ref: tooltipElementRef,
      })
    : children;

  return (
    <>
      {childrenWithEvents}
      {toolTipDiv}
    </>
  );
}
