import React, { createContext, useContext, useState, ReactNode } from "react";

// Definir o tipo do contexto
interface LayoutContextType {
  layoutReady: boolean;
  setLayoutReady: React.Dispatch<React.SetStateAction<boolean>>;
}

// Inicializar o contexto com um valor padrão ou `undefined`
const LayoutContext = createContext<LayoutContextType | undefined>(undefined);

// Custom hook para usar o contexto
export const useLayoutContext = () => {
  const context = useContext(LayoutContext);

  // Adicionar uma verificação para garantir que o contexto foi fornecido
  if (!context) {
    throw new Error("useLayoutContext must be used within a LayoutProvider");
  }

  return context;
};

// Definir as props para o provider
interface LayoutProviderProps {
  children: ReactNode;
}

export const LayoutProvider: React.FC<LayoutProviderProps> = ({ children }) => {
  const [layoutReady, setLayoutReady] = useState(false);

  const value = {
    layoutReady,
    setLayoutReady,
  };

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};
