export interface FuncaoProps {
  id: number;
  novidade?: any;
  label: string;
  icon?: string;
  link?: string;
  badge?: string;
  badgecolor?: string;
  description?: string;
  menu?: boolean;
}
export interface ModuloProps {
  id: number;
  label: string;
  icon?: string;
  novidade?: any;
  funcoes: FuncaoProps[];
}
export interface GrupoProps {
  label: string;
  icon?: string;
  modulos?: ModuloProps[];
  funcoes?: FuncaoProps[];
}
const menuItems: Array<GrupoProps> = require("src/menu.json");

export { menuItems };
