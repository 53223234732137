import { Fragment, useState } from "react";
import classNames from "classnames";
import { mapToCssModules } from "src/SmartR/Utils/utils";
import { Util } from "src/util";
import { AlignType, ColumnType, TableColumnProps, TableProps } from "./types";
import config from "src/config";

export const Table = ({
  columns,
  columnsDetail,
  dataDetailProperty,
  showHeader = true,
  showDetailHeader = true,
  enableHoverEffect = true,
  enableHoverEffectDetail = true,
  singleRecordTable = false,
  uniqueHeaderSingleRecordTable = false,
  data = [],
  className,
  classNameDetail,
}: TableProps) => {
  const [sortedColumn, setSortedColumn] = useState<TableColumnProps>(null);
  const [isSortedDesc, setIsSortedDesc] = useState(false);

  const getAlignClassName = (type: AlignType) => {
    if (type) {
      switch (type) {
        case AlignType.CENTER:
          return "text-center";
        case AlignType.END:
          return "text-end";
      }
    }
    return null;
  };
  const getDefaultValue = (type?: ColumnType) => {
    if (type) {
      switch (type) {
        case ColumnType.FLOAT:
        case ColumnType.INT:
          return 0;
        case ColumnType.DATE:
          return "0001-01-01";
      }
    }
    return "";
  };
  const generateSortingIndicator = (column: TableColumnProps) => {
    if (sortedColumn && sortedColumn.accessor === column.accessor) {
      return isSortedDesc ? " 🔽" : " 🔼";
    }
    return null;
  };

  const handleSort = (column: TableColumnProps) => {
    if (column.disableFilters) return;
    if (sortedColumn && sortedColumn.accessor === column.accessor) {
      setIsSortedDesc(!isSortedDesc);
    } else {
      setSortedColumn(column);
      setIsSortedDesc(false);
    }
  };

  const sortedData = [...data].sort((a, b) => {
    if (!sortedColumn) return 0;

    const valueA = a.hasOwnProperty(sortedColumn.accessor)
      ? a[sortedColumn.accessor]
      : getDefaultValue(sortedColumn.type);
    const valueB = b.hasOwnProperty(sortedColumn.accessor)
      ? b[sortedColumn.accessor]
      : getDefaultValue(sortedColumn.type);

    if (sortedColumn.type === ColumnType.DATE) {
      const dateA = new Date(valueA);
      const dateB = new Date(valueB);
      if (dateA < dateB) return isSortedDesc ? 1 : -1;
      if (dateA > dateB) return isSortedDesc ? -1 : 1;
      return 0;
    }

    if (typeof valueA === "number" && typeof valueB === "number") {
      if (valueA < valueB) return isSortedDesc ? 1 : -1;
      if (valueA > valueB) return isSortedDesc ? -1 : 1;
      return 0;
    }

    if (valueA < valueB) return isSortedDesc ? 1 : -1;
    if (valueA > valueB) return isSortedDesc ? -1 : 1;
    return 0;
  });

  const isMinOrDefaultValue = (value: any, type?: ColumnType) => {
    if (type) {
      switch (type) {
        case ColumnType.FLOAT:
        case ColumnType.INT:
          return parseInt(value) === 0;
        case ColumnType.DATE:
          return (
            value === "0001-01-01" ||
            (value instanceof Date &&
              value.toISOString().startsWith("0001-01-01"))
          );
      }
    }
    return false;
  };
  const renderCellValue = (
    record,
    column: TableColumnProps,
    parent: any = null
  ) => {
    let value = column.accessor ? record[column.accessor] : null;
    if (value === null || value === undefined) {
      value = getDefaultValue(column.type);
    }
    if (
      column.hideMinOrDefaultValue &&
      isMinOrDefaultValue(value, column.type)
    ) {
      value = null;
    }
    if (column.renderCell) {
      return column.renderCell({
        record,
        value,
        parent,
      });
    }

    if (value && value !== undefined && value !== null) {
      if (column.sourceList && Array.isArray(column.sourceList)) {
        const sourceValueProperty =
          column.sourceValueProperty ??
          config.component.table.sourceValueProperty;
        const sourceDescriptionProperty =
          column.sourceDescriptionProperty ??
          config.component.table.sourceDescriptionProperty;

        if (sourceValueProperty && sourceDescriptionProperty) {
          const foundItem = column.sourceList.find(
            (item) => item[sourceValueProperty] === value
          );

          if (foundItem) {
            value = foundItem[sourceDescriptionProperty];
            if (column.displayBadge) {
              const sourceBadgeProperty =
                column.sourceBadgeProperty ??
                config.component.table.sourceBadgeProperty;
              if (sourceBadgeProperty) {
                const badgeClasses = mapToCssModules(
                  classNames(
                    config.component.table.badgeClassName,
                    foundItem[sourceBadgeProperty]
                  )
                );
                return <div className={badgeClasses}>{value}</div>;
              }
            }
            return value;
          }
        }
      }

      if (column.format) {
        const formatOptions = {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        };

        if (column.format.match(/[cdfnp]/i)) {
          const decimalPlaces =
            column.format.length > 1 ? parseInt(column.format.substring(1)) : 0;
          formatOptions.minimumFractionDigits = decimalPlaces;
          formatOptions.maximumFractionDigits = decimalPlaces;
        }

        switch (column.format.charAt(0)) {
          case "d":
            return new Date(value).toLocaleDateString("pt-BR");
          case "D":
            return new Date(value).toLocaleDateString("pt-BR", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            });
          case "t":
            return new Date(value).toLocaleTimeString("pt-BR");
          case "T":
            return new Date(value).toLocaleTimeString("pt-BR", {
              hour12: true,
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            });
          case "c":
          case "C":
            return parseFloat(value).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
              ...formatOptions,
            });
          case "f":
          case "F":
            return parseFloat(value).toLocaleString("pt-BR", formatOptions);
          case "n":
          case "N":
            return parseInt(value, 10).toLocaleString("pt-BR", formatOptions);
          case "p":
          case "P":
            return `${parseFloat(value).toFixed(2)}%`;
          default:
            return value;
        }
      }

      if (column.mask) {
        return typeof column.mask === "string"
          ? Util.applyMask(value, column.mask)
          : column.mask(record);
      }
      switch (column.type) {
        case ColumnType.FLOAT:
          return parseFloat(value).toFixed(2);
        case ColumnType.DATE:
          return Util.getFormattedDate(value);
        case ColumnType.DATETIME:
          return Util.getFormattedDateTime(value);
        case ColumnType.INT:
          return parseInt(value, 10);
        default: {
          return value;
        }
      }
    }
  };

  const classes = mapToCssModules(
    classNames(
      className,
      "table table-bordered",
      enableHoverEffect ? "table-hover" : ""
    )
  );

  const classesDetail = mapToCssModules(
    classNames(
      classNameDetail,
      "table table-bordered",
      enableHoverEffectDetail ? "table-hover" : ""
    )
  );
  const renderTableHeader = () => {
    return (
      <>
        <div className="table-responsive react-table">
          <table role="table" className={classes}>
            <thead className="table-light table-nowrap">
              <tr role="row">
                {columns.map((column, index) => (
                  <th
                    key={index}
                    role="columnheader"
                    className={classNames(
                      column.headerClassName,
                      getAlignClassName(column.headerAlign),
                      {
                        "header-filter": !column.disableFilters,
                      }
                    )}
                    style={{
                      ...(column.width ? { width: column.width } : {}),
                    }}
                    onClick={
                      !singleRecordTable ? () => handleSort(column) : null
                    }
                  >
                    {column.header}
                    {!singleRecordTable && generateSortingIndicator(column)}
                  </th>
                ))}
              </tr>
            </thead>
          </table>
        </div>
      </>
    );
  };
  const renderTable = (data) => {
    return (
      <>
        <div className="table-responsive react-table">
          <table role="table" className={classes}>
            {showHeader && !uniqueHeaderSingleRecordTable && (
              <thead className="table-light table-nowrap">
                <tr role="row">
                  {columns.map((column, index) => (
                    <th
                      key={`th-header-${index}`}
                      role="columnheader"
                      className={classNames(
                        column.headerClassName,
                        getAlignClassName(column.headerAlign),
                        {
                          "header-filter": !column.disableFilters,
                        }
                      )}
                      style={{
                        ...(column.width ? { width: column.width } : {}),
                      }}
                      onClick={
                        !singleRecordTable ? () => handleSort(column) : null
                      }
                    >
                      {column.header}
                      {!singleRecordTable && generateSortingIndicator(column)}
                    </th>
                  ))}
                </tr>
              </thead>
            )}

            <tbody>
              {data.map((record, index) => (
                <Fragment key={`fragement-${index}`}>
                  <tr key={`tr-${index}`} role="row">
                    {columns.map((column, colIndex) => (
                      <td
                        key={`td-${colIndex}`}
                        role="cell"
                        className={classNames(
                          column.contentClassName,
                          getAlignClassName(column.contentAlign)
                        )}
                        style={{
                          ...((!showHeader ||
                            (showHeader && uniqueHeaderSingleRecordTable)) &&
                          column.width
                            ? { width: column.width }
                            : {}),
                        }}
                      >
                        {renderCellValue(record, column)}
                      </td>
                    ))}
                  </tr>
                  {columnsDetail && columnsDetail.length > 0 && (
                    <tr key={`tr-detail-${index}`} role="row" className="tr-detail">
                      <td colSpan={columns.length}>
                        <table role="table" className={classesDetail}>
                          {showDetailHeader && (
                            <thead className="table-light table-nowrap">
                              <tr key={`tr-detail-${index}`} role="row">
                                {columnsDetail.map((columnDetail, index) => (
                                  <th
                                    key={`td-detail-${index}`}
                                    role="columnheader"
                                    className={classNames(
                                      columnDetail.headerClassName,
                                      getAlignClassName(
                                        columnDetail.headerAlign
                                      ),
                                      {
                                        "header-filter":
                                          !columnDetail.disableFilters,
                                      }
                                    )}
                                    style={{
                                      ...(columnDetail.width
                                        ? { width: columnDetail.width }
                                        : {}),
                                    }}
                                    onClick={() => handleSort(columnDetail)}
                                  >
                                    {columnDetail.header}
                                    {generateSortingIndicator(columnDetail)}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                          )}

                          <tbody>
                            {record[dataDetailProperty].map(
                              (recordDetail, index) => (
                                <tr key={index} role="row">
                                  {columnsDetail.map(
                                    (columnDetail, colDetailIndex) => (
                                      <td
                                        key={colDetailIndex}
                                        role="cell"
                                        className={classNames(
                                          columnDetail.contentClassName,
                                          getAlignClassName(
                                            columnDetail.contentAlign
                                          )
                                        )}
                                        style={{
                                          ...((!showHeader ||
                                            (showHeader &&
                                              uniqueHeaderSingleRecordTable)) &&
                                          columnDetail.width
                                            ? { width: columnDetail.width }
                                            : {}),
                                        }}
                                      >
                                        {renderCellValue(
                                          recordDetail,
                                          columnDetail,
                                          record
                                        )}
                                      </td>
                                    )
                                  )}
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  };
  return !singleRecordTable ? (
    <Fragment>{renderTable(sortedData)}</Fragment>
  ) : (
    <Fragment>
      {uniqueHeaderSingleRecordTable && renderTableHeader()}
      {sortedData.map((record, index) => (
        <Fragment key={`fra-${index}`}>{renderTable([record])}</Fragment>
      ))}
    </Fragment>
  );
};
