import { mapEnumValorParaBadge, mapEnumValorParaDescricao } from "src/enums";

export function SituacaoRegistro(situacao: number, enumerador: any) {
  const descricao = mapEnumValorParaDescricao(enumerador, situacao);
  const badge = mapEnumValorParaBadge(enumerador, situacao);
  return (
    <>
      <div className={`${badge} badge bg-link rounded-pill font-size-12`}>
        {descricao}
      </div>
    </>
  );
}
