import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Navigate, useLocation } from "react-router-dom";

export const AuthGuard = ({ children }) => {
  const { pathname } = useLocation();

  const dadosAuthSelector = createSelector(
    (state) => state.autenticacao,
    (autenticacao) => ({
      autenticado: autenticacao.autenticado,
      renovarSessao: autenticacao.renovarSessao,
      usuario: autenticacao.usuario,
    })
  );
  const { autenticado, renovarSessao } = useSelector(dadosAuthSelector);

  let authenticated = autenticado;
  let renewSession = renovarSessao;
  return (
    <>
      {authenticated ? (
        <>{children}</>
      ) : renewSession ? (
        <Navigate replace to="/Renovar" state={{ from: pathname }}/>
      ) : (
        <Navigate replace to="/Login" state={{ from: pathname }} />
      )}
    </>
  );
};

export default AuthGuard;
