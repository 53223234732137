import React, {
  useState,
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import classNames from "classnames";
import configSmartR from "src/configSmartR";
import {
  CharacterCasing,
  IconPosition,
  Input,
  TooltipPosition,
  ValidationMessage,
} from "../types";
import { ValidationResult } from "../ValidationResult";
import {
  applyMask,
  getDefaultHasIcon,
  getDefaultIcon,
  getDefaultIconPosition,
  getEditorAttributes,
  getValueAsType,
  getValueType,
  mapToCssModules,
  removeMask,
  validateEditorInputValue,
} from "../Utils/utils";
import { Column } from "../Column/Column";
import { Row } from "../Row/Row";
import { EditorPropType } from "./EditorPropType";
import { Label } from "../Label/Label";
import { CheckboxGroup } from "../CheckboxGroup/CheckboxGroup";
import { EditorInput } from "../EditorInput/EditorInput";
import { Radio } from "../Radio/Radio";
import Select from "../Select/Select";
import { File } from "../File/File";
import { EditorButton } from "../EditorButton/EditorButton";

export interface EditorRef {
  validate: () => Promise<ValidationResult[]>;
  getId: () => string;
  getName: () => string;
  getTitle: () => string;
  getValue: () => any;
  getSubmit: () => boolean;
  setErrorList: (list: ValidationResult[]) => void;
  forwardRef?: (ref: EditorRef | null) => void;
}

export const Editor = forwardRef<EditorRef, EditorPropType>((props, ref) => {
  const {
    type = Input.Text,
    className,
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
    size,

    labelXs,
    labelSm,
    labelMd,
    labelLg,
    labelXl,
    labelXxl,
    labelSize,

    children,
    id,
    name,
    createRow = false,
    createColumn = true,
    title,
    checkBoxText = "",
    required = false,
    value,
    placeholder,
    hasTitle = true,
    titleBold,
    enabled = true,
    readOnly = false,
    inline = false,
    horizontal = false,
    rows,
    input,
    valueType = getValueType(props.type),
    characterCasing = CharacterCasing.Upper,
    maxFileSize,
    maxFilesPlaceholder,
    max,
    min,
    mask,
    getMaskedValue = false,
    enableShowPassword = configSmartR.showPasswordIcon,
    showPasswordIconPosition = IconPosition.End,
    showPasswordIcon = configSmartR.icons.showPassword,
    hidePasswordIcon = configSmartR.icons.hidePassword,
    hasIcon = getDefaultHasIcon(props.type),
    iconPosition = getDefaultIconPosition(props.type),
    appendIcon = getDefaultIcon(props.type),
    prependIcon = getDefaultIcon(props.type),
    invalid,
    tooltip,
    toolTipPosition = TooltipPosition.Top,
    note,
    noteClassName,
    invalidMessage,
    requiredMessage,
    accept,
    fileUrlDownload,
    fileDownloadName,
    prependButtons,
    appendButtons,
    optionsId = configSmartR.optionsId,
    optionsDescription = configSmartR.optionsDescription,
    optionsFirstSelected = configSmartR.optionsFirstSelected,
    optionsNoneSelectedValue = configSmartR.optionsNoneSelectedValue,
    optionsNoneSelecteText = configSmartR.optionsNoneSelecteText,
    options,
    optionsFilter = false,
    optionsMultiple = false,
    optionsMultipleSeparatorValue = configSmartR.optionsMultipleSeparatorValue,
    optionsMultipleSeparatorDescription = configSmartR.optionsMultipleSeparatorDescription,
    optionsLimiteDescriptionSelected = configSmartR.optionsLimiteDescriptionSelected,
    optionsFilterPlaceholder = configSmartR.texts.optionsFilterPlaceholder,
    thousandsSeparator = configSmartR.thousandsSeparator,
    decimalSeparator = configSmartR.decimalSeparator,
    decimalPlaces = configSmartR.decimalPlaces,
    submit = true,
    state,
    dispatchState,
    formState,
    dispatchFormState,
    listFormState,
    listFormIndex,
    dispatchListFormState,
    validateOnBlur = configSmartR.validateOnBlur,
    validateOnChange = false,
    validateDefaultOnBlur = true,
    validateDefaultOnChange = true,
    validateDefaultOnSubmit = configSmartR.validateOnSubmit,
    customValidationOnBlur,
    customValidationOnChange,
    customValidationOnSubmit,
    onDownloadFileClick,
    onDeleteFileClick,
    onChange,
    onBlur,
    showValidationResultOnSubmit = configSmartR.validateOnSubmit,
    ...attributes
  } = props;
  const inputRadioRefs = useRef([]);
  const inputRef = useRef<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  >(null);
  const [validationList, setValidationList] = useState<ValidationResult[]>();
  const [isInvalid, setIsInvalid] = useState(invalid);
  const [showPassword, setShowPassword] = useState(false);
  const editorName = name || id;
  let editorValue = value;
  let editorMask = mask;

  if (!editorValue) {
    editorValue = getStateValue();
  }
  function getStateValue() {
    let value;
    if (editorName) {
      if (formState) {
        value = formState[editorName];
      } else if (
        listFormState &&
        listFormIndex !== undefined &&
        listFormIndex < listFormState.length
      ) {
        value = listFormState[listFormIndex][editorName];
      }
    } else if (state && state !== null && state !== undefined) {
      value = state;
    }
    return value;
  }
  function setStateValue(inputName, value) {
    if (inputName && formState && dispatchFormState) {
      dispatchFormState((prevState) => ({
        ...prevState,
        [inputName]: value,
      }));
    } else if (
      listFormState &&
      dispatchListFormState &&
      listFormIndex !== undefined &&
      listFormIndex < listFormState.length
    ) {
      const newState = [...listFormState];
      newState[listFormIndex] = {
        ...newState[listFormIndex],
        [inputName]: value,
      };
      dispatchListFormState(newState);
    } else if (dispatchState) {
      dispatchState((prevState) => value);
    }
  }
  function validateInput(currentValue: any) {
    let list: ValidationResult[] = validateEditorInputValue(
      currentValue,
      props,
      editorMask
    );
    setIsInvalid(list.length > 0);
    return list;
  }
  function getName() {
    return editorName;
  }
  function getId() {
    return id;
  }
  function getSubmit() {
    return submit;
  }
  function getTitle() {
    return title;
  }

  function setErrorList(list: ValidationResult[]) {
    setValidationList(list);
    setIsInvalid(list.length > 0);
  }
  function getValue() {
    let currentValue;

    if (inputRef?.current) {
      switch (type) {
        case Input.Radio:
          {
            for (const ref of inputRadioRefs.current) {
              if (ref.current instanceof HTMLInputElement) {
                if (ref.current.checked) {
                  currentValue = ref.current.value;
                }
              }
            }
          }
          break;
        case Input.CheckBox:
          {
            if (inputRef.current instanceof HTMLInputElement) {
              currentValue = inputRef?.current?.checked;
            }
          }
          break;
        case Input.File:
          {
            if (inputRef.current instanceof HTMLInputElement) {
              currentValue = inputRef?.current?.files;
            }
          }
          break;
        default:
          {
            currentValue = inputRef?.current?.value;
          }
          break;
      }
    } else {
      currentValue = getStateValue();
    }

    if (
      (editorMask ||
        type === Input.Money ||
        type === Input.Decimal ||
        type === Input.Percent) &&
      currentValue &&
      !getMaskedValue
    ) {
      currentValue = removeMask(
        type,
        editorMask,
        currentValue,
        thousandsSeparator,
        decimalSeparator
      );
    }

    return getValueAsType(
      valueType,
      currentValue,
      optionsMultipleSeparatorValue
    );
  }
  async function validate(): Promise<ValidationResult[]> {
    let list: ValidationResult[] = [];
    const currentValue = getValue();
    if (validateDefaultOnSubmit) {
      list = validateInput(currentValue);
    }
    if (customValidationOnSubmit) {
      const customValidationResults = await customValidationOnSubmit(
        currentValue
      );
      list = [...list, ...customValidationResults];
    }
    if (showValidationResultOnSubmit) {
      setValidationList(list);
      setIsInvalid(list.length > 0);
    }
    return Promise.resolve(list);
  }

  useImperativeHandle(ref, () => ({
    validate,
    getName,
    getId,
    getTitle,
    getValue,
    getSubmit,
    setErrorList,
  }));

  const handleInputChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value, files } = event.target;

      let newValue: any = files ?? value;

      if (
        editorMask ||
        type === Input.Money ||
        type === Input.Decimal ||
        type === Input.Percent
      ) {
        newValue = applyMask(
          type,
          editorMask,
          newValue,
          thousandsSeparator,
          decimalSeparator,
          decimalPlaces
        );
      }

      if (inputRef.current && inputRef.current.type !== "file") {
        inputRef.current.value = newValue || "";
      }

      const changedValueType = getValueAsType(
        valueType,
        newValue,
        optionsMultipleSeparatorValue
      );

      if (validateOnChange) {
        let list: ValidationResult[] = [];
        if (validateDefaultOnChange) {
          list = validateInput(changedValueType);
        }
        if (customValidationOnChange) {
          const customValidationResults = await customValidationOnChange(
            changedValueType
          );
          list = [...list, ...customValidationResults];
        }
        setValidationList(list);
        setIsInvalid(list.length > 0);
      }

      if (editorMask && newValue && !getMaskedValue) {
        newValue = removeMask(
          type,
          editorMask,
          newValue,
          thousandsSeparator,
          decimalSeparator
        );
      }
      const stateValue = getValueAsType(
        valueType,
        newValue,
        optionsMultipleSeparatorValue
      );
      setStateValue(name, stateValue);

      if (onChange) {
        onChange(
          event,
          getValueAsType(valueType, newValue, optionsMultipleSeparatorValue),
          id,
          name,
          type
        );
      }
    },
    [
      validateOnChange,
      onChange,
      customValidationOnChange,
      validateDefaultOnChange,
      editorMask,
      formState,
      state,
      listFormState,
    ]
  );

  const handleInputBlur = useCallback(
    async (event: React.FocusEvent<HTMLInputElement>) => {
      const { name, value, files } = event.target;
      let newValue: any = files ?? value;
      let validationValue = newValue;
      let isValid = true;
      if (validateOnBlur) {
        if (editorMask && validationValue && !getMaskedValue) {
          validationValue = removeMask(
            type,
            editorMask,
            validationValue,
            thousandsSeparator,
            decimalSeparator
          );
        }
        validationValue = getValueAsType(
          valueType,
          validationValue,
          optionsMultipleSeparatorValue
        );
        let list: ValidationResult[] = [];
        if (validateDefaultOnBlur) {
          list = validateInput(validationValue);
        }
        if (customValidationOnBlur) {
          const customValidationResults = await customValidationOnBlur(
            validationValue
          );
          list = [...list, ...customValidationResults];
        }
        setValidationList(list);
        setIsInvalid(list.length > 0);
        isValid = list.length === 0;
      }
      if (onBlur) {
        onBlur(event, validationValue, id, name, type);
      }
    },
    [
      validateOnBlur,
      editorMask,
      customValidationOnBlur,
      validateDefaultOnBlur,
      formState,
      state,
      listFormState,
      onBlur,
    ]
  );

  const onShowPasswordHandler = () => {
    setShowPassword(!showPassword);
  };

  let editorInput = null;
  let titleLabel = null;
  let noteLabel = null;

  let inputType = "input";
  let classes = configSmartR.classes.input;

  if (hasTitle && title) {
    titleLabel = (
      <Label
        id={id}
        htmlFor={id}
        title={title}
        titleBold={titleBold}
        required={required}
        tooltip={tooltip}
        toolTipPosition={toolTipPosition}
        horizontal={horizontal}
        size={horizontal ? labelSize : null}
        xs={horizontal ? labelXs : null}
        sm={horizontal ? labelSm : null}
        md={horizontal ? labelMd : null}
        lg={horizontal ? labelLg : null}
        xl={horizontal ? labelXl : null}
        xxl={horizontal ? labelXxl : null}
      ></Label>
    );
  }

  switch (type) {
    case Input.Select:
      {
        inputType = "select";
      }
      break;
    case Input.Radio:
      {
        classes = `${configSmartR.classes.radio}`;
      }
      break;
    case Input.CheckBox:
      {
        classes = `${configSmartR.classes.checkbox}`;
      }
      break;
    case Input.LongText:
      {
        inputType = "textarea";
      }
      break;
    case Input.Mobile:
    case Input.Phone:
    case Input.Fax:
      {
        if (!editorMask && configSmartR.phoneMask) {
          editorMask = configSmartR.phoneMask;
        }
      }
      break;
  }

  let innerChildren = null;
  let dataSource = [];
  if (type !== Input.Select) {
    if (editorValue) {
      if (type !== Input.Date || !(editorValue instanceof Date)) {
        if (
          editorValue &&
          (editorMask ||
            type === Input.Money ||
            type === Input.Decimal ||
            type === Input.Percent)
        ) {
          editorValue = applyMask(
            type,
            editorMask,
            editorValue,
            thousandsSeparator,
            decimalSeparator,
            decimalPlaces
          );
        }
      } else {
        try {
          var day = ("0" + editorValue.getDate()).slice(-2);
          var month = ("0" + (editorValue.getMonth() + 1)).slice(-2);
          var dateValue = editorValue.getFullYear() + "-" + month + "-" + day;
          editorValue = String(dateValue);
        } catch {}
      }
    }
  } else if (options) {
    dataSource =
      options && typeof options[Symbol.iterator] === "function"
        ? [...options]
        : [];
    if (
      dataSource.every((element) => {
        const type = typeof element;
        return type !== "object" && type !== "function";
      })
    ) {
      dataSource = dataSource.map((value, index) => {
        const obj = {};
        obj[optionsId] = value;
        obj[optionsDescription] = value;
        return obj;
      });
    }
    if (!optionsFirstSelected) {
      let noneSelectOption = {};
      noneSelectOption[optionsId] = optionsNoneSelectedValue;
      noneSelectOption[optionsDescription] = optionsNoneSelecteText;
      dataSource.unshift(noneSelectOption);
      if (!editorValue && (editorValue === undefined || editorValue === null)) {
        editorValue = optionsNoneSelectedValue;
      }
    } else {
      if (!String(editorValue) && dataSource.length > 0) {
        editorValue = dataSource[0][optionsId];
      }
    }
  }

  if (type === Input.Select && !optionsFilter) {
    innerChildren = dataSource.map((option) => {
      return React.createElement(
        "option",
        { value: option[optionsId], key: option[optionsId] },
        option[optionsDescription]
      );
    });
  }

  classes = mapToCssModules(
    classNames(
      className,
      classes,
      isInvalid ? configSmartR.classes.invalid : null
    )
  );

  const editorAttributes = getEditorAttributes(
    props,
    isInvalid,
    editorValue,
    showPassword
  );

  switch (type) {
    case Input.CheckBox:
      {
        editorInput = (
          <CheckboxGroup
            id={id}
            checkBoxText={checkBoxText}
            children={
              <EditorInput
                type={type}
                inputType={inputType}
                editorAttributes={editorAttributes}
                onBlurEvent={handleInputBlur}
                onChangeEvent={handleInputChange}
                inputRef={inputRef}
                children={innerChildren}
                className={classes}
              ></EditorInput>
            }
          ></CheckboxGroup>
        );
      }
      break;
    case Input.Radio:
      {
        editorInput = (
          <Radio
            inline={inline}
            id={id}
            editorAttributes={editorAttributes}
            inputRadioRefs={inputRadioRefs}
            onBlurEvent={handleInputBlur}
            onChangeEvent={handleInputChange}
            options={options}
            optionsDescription={optionsDescription}
            optionsFirstSelected={optionsFirstSelected}
            optionsId={optionsId}
            optionsNoneSelectedValue={optionsNoneSelectedValue}
            value={editorValue}
            optionsNoneSelecteText={optionsNoneSelecteText}
            className={classes}
            inputRef={inputRef}
          ></Radio>
        );
      }
      break;
    case Input.Select:
      {
        if (!optionsFilter && !optionsMultiple) {
          editorInput = (
            <EditorInput
              type={type}
              inputType={inputType}
              editorAttributes={editorAttributes}
              onBlurEvent={handleInputBlur}
              onChangeEvent={handleInputChange}
              inputRef={inputRef}
              children={innerChildren}
              className={classes}
            ></EditorInput>
          );
        } else {
          editorInput = (
            <Select
              placeholder={placeholder}
              searchPlaceholder={optionsFilterPlaceholder}
              editorAttributes={editorAttributes}
              onBlurEvent={handleInputBlur}
              onChangeEvent={handleInputChange}
              options={options}
              optionsDescription={optionsDescription}
              optionsFirstSelected={optionsFirstSelected}
              optionsFilter={optionsFilter}
              optionsMultiple={optionsMultiple}
              optionsMultipleSeparatorValue={optionsMultipleSeparatorValue}
              optionsMultipleSeparatorDescription={
                optionsMultipleSeparatorDescription
              }
              optionsLimiteDescriptionSelected={
                optionsLimiteDescriptionSelected
              }
              optionsId={optionsId}
              optionsNoneSelectedValue={optionsNoneSelectedValue}
              value={editorValue}
              optionsNoneSelecteText={optionsNoneSelecteText}
              className={classes}
              inputRef={inputRef}
            ></Select>
          );
        }
      }
      break;

    case Input.File:
      {
        editorInput = (
          <File
            id={id}
            name={editorName}
            editorAttributes={editorAttributes}
            onDownloadFileClick={onDownloadFileClick}
            onDeleteFileClick={onDeleteFileClick}
            fileUrlDownload={fileUrlDownload}
            fileDownloadName={fileDownloadName}
            placeholder={placeholder}
            maxFilesPlaceholder={maxFilesPlaceholder}
            onBlurEvent={handleInputBlur}
            onChangeEvent={handleInputChange}
            inputRef={inputRef}
            className={classes}
          ></File>
        );
      }
      break;

    default:
      {
        editorInput = (
          <EditorInput
            type={type}
            inputType={inputType}
            editorAttributes={editorAttributes}
            onBlurEvent={handleInputBlur}
            onChangeEvent={handleInputChange}
            inputRef={inputRef}
            children={innerChildren}
            className={classes}
            {...attributes}
          ></EditorInput>
        );
      }
      break;
  }

  if (type === Input.Hidden) {
    return editorInput;
  }

  if (note) {
    const noteClasses = mapToCssModules(
      classNames(configSmartR.classes.labelNote, noteClassName)
    );
    noteLabel = <label className={noteClasses}>{note}</label>;
  }

  let invalidElementList = [];
  if (
    (invalid && invalidMessage) ||
    (validationList && validationList.length > 0)
  ) {
    if (invalidMessage) {
      invalidElementList.push(
        React.createElement(
          configSmartR.invalidFeedbackTag,
          {
            key: 0,
            className: configSmartR.classes.validationMessageError,
          },
          String(invalidMessage)
        )
      );
    }
    validationList?.map((item, index) => {
      let messageClassName = null;
      switch (item.Type) {
        case ValidationMessage.Error:
          {
            messageClassName = configSmartR.classes.validationMessageError;
          }
          break;

        case ValidationMessage.Success:
          {
            messageClassName = configSmartR.classes.validationMessageSuccess;
          }
          break;
        case ValidationMessage.Warning:
          {
            messageClassName = configSmartR.classes.validationMessageWarning;
          }
          break;
      }
      invalidElementList.push(
        React.createElement(
          configSmartR.invalidFeedbackTag,
          {
            key: index + 1,
            className: messageClassName,
          },
          String(item.Message)
        )
      );
    });
  }
  const prependIconComponent =
    hasIcon &&
    (iconPosition === IconPosition.Both ||
      iconPosition === IconPosition.Start) ? (
      <div className={configSmartR.classes.inputGroupPrependIcon}>
        <span className={configSmartR.classes.inputGroupIcon}>
          <i className={prependIcon}></i>
        </span>
      </div>
    ) : null;

  const appendIconComponent =
    hasIcon &&
    (iconPosition === IconPosition.Both ||
      iconPosition === IconPosition.End) ? (
      <div className={configSmartR.classes.inputGroupAppendIcon}>
        <span className={configSmartR.classes.inputGroupIcon}>
          <i className={appendIcon}></i>
        </span>
      </div>
    ) : null;

  const showPasswordIconComponent =
    type === Input.Password && enableShowPassword ? (
      <div
        className={
          showPasswordIconPosition === IconPosition.Start
            ? configSmartR.classes.inputGroupPrependIcon
            : configSmartR.classes.inputGroupAppendIcon
        }
      >
        <span
          className={configSmartR.classes.inputGroupShowPasswordIcon}
          onClick={onShowPasswordHandler}
        >
          <i className={showPassword ? hidePasswordIcon : showPasswordIcon}></i>
        </span>
      </div>
    ) : null;

  const prependButtonElements = prependButtons?.map((button, index) => (
    <EditorButton key={index} {...button} />
  ));

  const appendButtonElements = appendButtons?.map((button, index) => (
    <EditorButton key={index} {...button} />
  ));

  let inputGroup = (
    <div className={configSmartR.classes.inputGroup}>
      {prependButtonElements}
      {configSmartR.prependIconAfterInput && prependIconComponent}
      {(showPasswordIconPosition === IconPosition.Start ||
        showPasswordIconPosition === IconPosition.Both) &&
        showPasswordIconComponent}
      {editorInput}
      {!configSmartR.prependIconAfterInput && prependIconComponent}
      {(showPasswordIconPosition === IconPosition.End ||
        showPasswordIconPosition === IconPosition.Both) &&
        showPasswordIconComponent}
      {appendIconComponent}
      {appendButtonElements}
    </div>
  );

  if (!hasIcon && !(prependButtonElements || appendButtonElements)) {
    inputGroup = editorInput;
  }

  const classControlGroup = mapToCssModules(
    classNames(
      configSmartR.classes.controlGroup,
      isInvalid ? configSmartR.classes.invalid : "",
      enabled && type !== Input.File
        ? ""
        : configSmartR.classes.controlGroupDisabledControl
    )
  );
  if (!horizontal) {
    let section = React.createElement(
      "div",
      { className: classControlGroup },
      titleLabel,
      inputGroup,
      noteLabel,
      invalidElementList
    );
    let column = (
      <Column size={size} xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
        {section}
      </Column>
    );

    if (createRow) {
      return <Row>{column}</Row>;
    } else if (createColumn) {
      return column;
    } else {
      return section;
    }
  } else {
    inputGroup = (
      <Column size={size} xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
        {inputGroup}
      </Column>
    );
    return (
      <Row className={configSmartR.classes.horizontalFormRow}>
        {titleLabel} {inputGroup} {noteLabel} {invalidElementList}
      </Row>
    );
  }
});
