import React from "react";

import { Outlet } from "react-router-dom";

//redux
import { useSelector } from "react-redux";

// layouts Format
import VerticalLayout from "./VerticalLayout";
import HorizontalLayout from "./HorizontalLayout/index";

// Import scss
import "../assets/scss/theme.scss";
import "../assets/scss/gomelius.scss";

import { createSelector } from "reselect";
import NotificacaoHub from "./organisms/NotificacaoHub";

function getLayout(layoutType: string) {
  let layoutCls: Object = VerticalLayout;
  switch (layoutType) {
    case "horizontal":
      layoutCls = HorizontalLayout;
      break;
    default:
      layoutCls = VerticalLayout;
      break;
  }
  return layoutCls;
}

const LayoutAutenticado = () => {
  const selectLayoutProperties = createSelector(
    (state: any) => state.Layout,
    (layout: any) => ({
      layoutType: layout.layoutType,
    })
  );

  const { layoutType } = useSelector(selectLayoutProperties);

  const Layout: any = getLayout(layoutType);

  return (
    <React.Fragment>
      <NotificacaoHub />
      <Layout>
        <Outlet />
      </Layout>
    </React.Fragment>
  );
};

export default LayoutAutenticado;
