export const CepHelper = {
  obterEndereco: (cep) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
        if (!response.ok) {
          throw new Error("CEP não encontrado.");
        }

        const data = await response.json();
        resolve(data);
      } catch (error) {
        console.error("Erro ao consultar o CEP:", error.message);
        reject(error);
      }
    });
  },
};
