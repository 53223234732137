import React, { forwardRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, ChildrenForm, FileForm, FormRef } from "src/SmartR";

import gomelius from "src/api/gomelius";

import { Card, Loading, PaginaLayout } from "src/components";
interface Dependencia {
  urlGetList: string;
  dispatch: React.Dispatch<React.SetStateAction<any[]>>;
  filter?: any;
  handleList?: any;
  onLoaded?: any;
}

interface PaginaCadastroProps {
  id?: string;
  funcao?: number;
  item: string;
  tituloMensagem?: string;
  saveMessage?: string;
  titulo: string;
  modulo: string;
  urlSubmit?: string;
  urlGet?: string;
  urlBack?: string;
  children?: any;
  obterUnico?: boolean;
  podeFavorito?: boolean;
  childrenTabs?: boolean;
  perguntarNovoRegistro?: boolean;
  mostrarMensagemSucesso?: boolean;
  dispatchForm?: any;
  classeBotaoConfirmar?: string;
  textoBotaoConfirmar?: string;
  dependencias?: Dependencia[];
  subCadastros?: ChildrenForm[];
  arquivos?: FileForm[];
  onRegistroCarregado?: any;
  onRegistroSalvo?: any;
  tratarSalvoManual?: any;

  onReady?: any;
  modal?: boolean;
  beforeSubmitHandler?: (form: any) => Promise<any>;
  customValidation?: (record: any) => Promise<boolean>;
}

export const PaginaCadastro = forwardRef<FormRef, PaginaCadastroProps>(
  (
    {
      id,
      funcao,
      titulo,
      tituloMensagem = "Registro",
      modulo,
      item,
      urlGet,
      urlSubmit,
      urlBack,
      dispatchForm,
      childrenTabs = false,
      podeFavorito = true,
      perguntarNovoRegistro = true,
      mostrarMensagemSucesso = true,
      obterUnico = false,
      saveMessage,
      classeBotaoConfirmar = "btn-primary",
      textoBotaoConfirmar = "Confirmar",
      dependencias,
      subCadastros,
      arquivos,
      onRegistroCarregado,
      onRegistroSalvo,
      tratarSalvoManual = true,
      modal = false,
      onReady,
      children,
      beforeSubmitHandler,
      customValidation,
    }: PaginaCadastroProps,
    ref
  ) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const handleCancelar = () => {
      navigate(`/${urlBack}`);
    };

    useEffect(() => {
      const fetchData = async () => {
        const promises = [];
        if (id) {
          promises.push(gomelius.obterRegistro(id, urlGet));
        } else if (obterUnico) {
          promises.push(gomelius.obter(urlGet));
        }
        if (dependencias) {
          dependencias.forEach((dependencia) => {
            if (!dependencia.filter) {
              promises.push(gomelius.obterLista(dependencia.urlGetList));
            } else {
              promises.push(
                gomelius.obterListaFiltro(
                  dependencia.filter,
                  dependencia.urlGetList
                )
              );
            }
          });
        }

        if (id || obterUnico) {
          subCadastros?.forEach((subCadastro) => {
            if (id) {
              promises.push(
                gomelius.obterListaSubCadastro(id, subCadastro.urlGetList)
              );
            } else {
              promises.push(gomelius.obterLista(subCadastro.urlGetList));
            }
          });
        }

        try {
          const results = await Promise.all(promises);

          dependencias?.forEach((dependencia, index) => {
            let lista = results[index + (id ? 1 : 0)];
            if (dependencia.handleList) {
              lista = dependencia.handleList(lista);
            }
            if (dependencia.onLoaded) {
              dependencia.onLoaded(lista);
            }
            dependencia.dispatch(lista);
          });
          if (id) {
            subCadastros?.forEach(async (subCadastro, index) => {
              let lista =
                results[
                  index +
                    ((id ? 1 : 0) + (dependencias ? dependencias.length : 0))
                ];
              const childrenPromises = [];
              if (subCadastro.loadRecordComplete && subCadastro.urlGet) {
                lista.forEach((lazy, index) => {
                  childrenPromises.push(
                    gomelius.obterChildren(id, lazy.id, subCadastro.urlGet)
                  );
                });
              }
              if (childrenPromises.length > 0) {
                const resultsChildren = await Promise.all(childrenPromises);
                lista = [];
                resultsChildren.forEach((record, index) => {
                  lista.push(record);
                });
              }
              if (subCadastro.handleListOnLoad) {
                lista = subCadastro.handleListOnLoad(lista);
              }
              if (subCadastro.onLoaded) {
                subCadastro.onLoaded(lista);
              }
              subCadastro.dispatch(lista);
            });
          }
          if ((id || obterUnico) && onRegistroCarregado) {
            onRegistroCarregado(results[0]);
          } else if ((id || obterUnico) && dispatchForm) {
            dispatchForm(results[0]);
          }
          if (onReady) {
            onReady(id ? results[0] : null);
          }
          setIsLoading(false);
        } catch (error) {
          console.error(error);
        }
      };

      fetchData();
    }, []);
    const container = (
      <>
        {isLoading ? (
          <Loading />
        ) : (
          <Card>
            <Form
              className={!childrenTabs ? "px-4 py-3" : ""}
              title={tituloMensagem}
              routeBack={urlBack}
              id={id}
              postSuccessMessage={id ? null : saveMessage}
              putSuccessMessage={id ? saveMessage : null}
              childrenFormList={subCadastros}
              childrenFileList={arquivos}
              urlSubmit={urlSubmit}
              onSuccess={onRegistroSalvo}
              manualHandleSuccess={tratarSalvoManual}
              beforeSubmitHandler={beforeSubmitHandler}
              customValidation={customValidation}
              askNewRecord={perguntarNovoRegistro}
              showSuccessMessage={mostrarMensagemSucesso}
              uniqueRecord={obterUnico}
              ref={ref}
            >
              {children}
              {!modal && (
                <footer>
                  {urlBack && (
                    <button
                      type="button"
                      className="btn btn-danger btn-cancel-register"
                      onClick={handleCancelar}
                    >
                      Cancelar
                    </button>
                  )}

                  <button
                    type="submit"
                    className={`btn ${classeBotaoConfirmar} btn-submit-register`}
                  >
                    {textoBotaoConfirmar}
                  </button>
                </footer>
              )}
            </Form>
          </Card>
        )}
      </>
    );
    return (
      <React.Fragment>
        {!modal ? (
          <PaginaLayout
            titulo={titulo}
            modulo={modulo}
            item={item}
            temFavorito={podeFavorito && !id}
            funcao={funcao}
          >
            {container}
          </PaginaLayout>
        ) : (
          <>{container}</>
        )}
      </React.Fragment>
    );
  }
);
