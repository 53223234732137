import configSmartR from "src/configSmartR";
import React from "react";
import { mapToCssModules } from "../Utils/utils";
import classNames from "classnames";
import { TooltipPosition } from "../types";
import { Tooltip } from "../Tooltip/Tooltip";

export interface editorButtonPropTypes {
  tag?: string;
  className?: string;
  text?: string;
  tooltip?: string;
  toolTipPosition?: TooltipPosition;
  icon?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function EditorButton(props: editorButtonPropTypes) {
  const {
    className,
    tag = configSmartR.editorButtonTag,
    icon,
    text,
    tooltip,
    toolTipPosition = TooltipPosition.Top,
    onClick,
    ...attributes
  } = props;
  const classes = mapToCssModules(
    classNames(configSmartR.classes.inputGroupButton, className)
  );

  const innerContent = (
    <>
      {icon && <i className={icon}></i>}
      {text}
    </>
  );
  let content = React.createElement(
    tag,
    {
      ...attributes,
      className: classes,
      onClick: onClick,
      ref: {},
    },
    innerContent
  );
  if (tooltip) {
    content = (
      <Tooltip position={toolTipPosition} text={tooltip}>
        {content}
      </Tooltip>
    );
  }
  return (
    <>
      <div className={configSmartR.classes.inputGroupAppendButton}>
        {content}
      </div>
    </>
  );
}
