import { useDispatch } from "react-redux";
import { SwalAlert } from "src/util";

import { removerServidor, atualizarRegistro } from "src/slices/thunks";
import { DropdownOptionList } from "src/components";
import { DropdownOptionListOptionPropTypes } from "../DropdownOptionList";
import { GramaticaHelper } from "src/helpers";

interface AcaoRegistroListaPropTypes {
  registro?: any;
  titulo: string;
  descricao: string;
  routeEdit?: string;
  origem: string;
  onEditClick?: any;
  onDeleteClick?: any;
  onInativarClick?: any;
  onReativarClick?: any;
  canEdit?: boolean;
  canInactive?: boolean;
  canReactive?: boolean;
  canDelete?: boolean;
  AcoesExtra?: DropdownOptionListOptionPropTypes[];
}
export function AcaoRegistroLista(props: AcaoRegistroListaPropTypes) {
  const {
    registro,
    titulo,
    descricao,
    routeEdit,
    origem,
    onDeleteClick,
    onEditClick,
    onReativarClick,
    onInativarClick,
    canEdit = true,
    canInactive = true,
    canReactive = true,
    canDelete = true,
    AcoesExtra = [],
  } = props;

  const dispatch = useDispatch();
  const handleDeleteClick = () => {
    SwalAlert.confirmar(
      "Atenção",
      `Tem certeza que deseja <strong>deletar</strong> ${GramaticaHelper.obterArtigoGramatical(
        titulo
      )} ${titulo.toLocaleLowerCase()} abaixo<br><br><strong>${descricao}</strong>`
    )
      .then(() => {
        if (onDeleteClick) {
          onDeleteClick(registro.id);
        } else {
          dispatch(
            removerServidor({
              origem: origem,
              titulo: titulo,
              id: registro.id,
            })
          );
        }
      })
      .catch(() => {});
  };
  const handleInativarClick = () => {
    SwalAlert.confirmar(
      "Atenção",
      `Tem certeza que deseja <strong>inativar</strong> ${GramaticaHelper.obterArtigoGramatical(
        titulo
      )} ${titulo.toLocaleLowerCase()} abaixo<br><br><strong>${descricao}</strong>`
    )
      .then(() => {
        if (onInativarClick) {
          onInativarClick(registro.id);
        } else {
          dispatch(
            atualizarRegistro({
              origem: origem,
              titulo: titulo,
              id: registro.id,
              status: 2,
            })
          );
        }
      })
      .catch(() => {});
  };
  const handleReativarClick = () => {
    SwalAlert.confirmar(
      "Atenção",
      `Tem certeza que deseja <strong>reativar</strong> ${GramaticaHelper.obterArtigoGramatical(
        titulo
      )} ${titulo.toLocaleLowerCase()} abaixo<br><br><strong>${descricao}</strong>`
    )
      .then(() => {
        if (onReativarClick) {
          onReativarClick(registro.id);
        } else {
          dispatch(
            atualizarRegistro({
              origem: origem,
              titulo: titulo,
              id: registro.id,
              status: 1,
            })
          );
        }
      })
      .catch(() => {});
  };

  return (
    <>
      <DropdownOptionList
        options={[
          {
            text: "Editar",
            iconClassName: "mdi mdi-file-document-edit-outline text-success",
            link:
              !onEditClick && routeEdit ? `/${routeEdit}/${registro.id}` : null,
            onClickHandler: onEditClick,
            visible: canEdit && registro.situacao === 1,
          },
          {
            text: "Reativar",
            iconClassName: "mdi mdi-check-box-outline text-primary",
            onClickHandler: handleReativarClick,
            visible: canReactive && registro.situacao === 2,
          },
          {
            text: "Inativar",
            iconClassName: "mdi mdi-block-helper text-warning",
            onClickHandler: handleInativarClick,
            visible: canInactive && registro.situacao === 1,
          },
          {
            text: "Excluir",
            iconClassName: "mdi mdi-trash-can-outline text-danger",
            onClickHandler: handleDeleteClick,
            visible: canDelete,
          },
          ...AcoesExtra,
        ]}
      />
    </>
  );
}
