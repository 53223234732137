const configSmartR = {
  hasInputGroup: true,
  translateToken: "GomeliusDev",
  translateText: false,
  columnTag: "div",
  invalidFeedbackTag: "div",
  editorButtonTag: "span",
  validateOnBlur: true,
  validateOnSubmit: true,
  prependIconAfterInput: true,
  showPasswordIcon: true,
  optionsId: "id",
  optionsDescription: "descricao",
  optionsNoneSelectedValue: "",
  optionsFirstSelected: false,
  optionsNoneSelecteText: "Nenhum selecionado",
  filePlaceholderText: "Selecione o arquivo...",
  thousandsSeparator: ".",
  decimalSeparator: ",",
  decimalPlaces: 2,
  phoneMask: "(00) 00000-0000",
  phonePlaceholder: "(00) 00000-0000",
  optionsLimiteDescriptionSelected: 3,
  optionsMultipleSeparatorValue: ",",
  optionsMultipleSeparatorDescription: ", ",
  toolTipWidth: 250,
  maxFiles: 10,
  maxFilesPlaceholder: 5,
  maxFileSize: 300,
  // maxFileSize: 52428800,
  texts: {
    optionsFilterPlaceholder: "digite para pesquisar...",
    optionsMultipleSelectAll: "Selecionar todos",
    optionsMultipleSelectNone: "Selecionar nenhum",
    optionsMultipleSelected: "Selecionados",
    optionsMultipleNoneSelected: "Nenhum selecionado",
    downloadFileTooltip: "Clique aqui para baixar o arquivo",
    deleteFileTooltip: "Clique aqui para excluir o arquivo",
    uploadFileTooltip: "Clique aqui selecionar o arquivo",
    totalSelectedFiles: "Arquivos selecionados",
  },
  classes: {
    form: "",
    controlGroup: "form-group",
    controlGroupDisabledControl: "disabled",
    horizontalFormRow: "mb-1",
    horizontalLabel: "col-form-label",
    radioGroupList: "radio-group",
    radioGroup: "form-check",
    radioGroupInLine: "form-check form-check-inline",
    input: "form-control",
    label: "form-label float-label",
    radio: "form-check-input",
    checkbox: "form-check-input",
    checkboxGroup: "form-check",
    checkboxLabel: "form-check-label",
    radioLabel: "form-check-label",
    radioOptionsGroup: "radio-options-group",
    required: "required",
    titleBold: "title-bold",
    inputGroup: "input-group",
    inputGroupAppendFile: "input-group-append file",
    inputGroupAppendButton: "input-group-append button",
    inputGroupAppendIcon: "input-group-append",
    inputGroupPrependIcon: "input-group-prepend",
    inputGroupIcon: "input-group-text inside-input",
    inputGroupButton: "input-group-text",
    inputGroupFileUploadIcon: "input-group-text upload",
    inputGroupFileDeleteIcon: "input-group-text delete",
    inputGroupFileDownloadIcon: "input-group-text download",
    inputGroupShowPasswordIcon: "input-group-text inside-input show-password",
    labelNote: "form-text text-muted note",
    invalid: "is-invalid",
    validationMessageError: "error-validation-message",
    validationMessageSuccess: "success-validation-message",
    validationMessageWarning: "warning-validation-message",
    tooltipTop: "smart-tooltip top",
    tooltipBottom: "smart-tooltip bottom",
    tooltipLeft: "smart-tooltip left",
    tooltipRight: "smart-tooltip right",
    tooltipOverlay: "tooltip-overlay",
    select: {
      container: "select-filter form-control",
      innerContainer: "select-box",
      optionsContainer: "options-container",
      dropdown: "options",
      option: "option",
      searchInput: "search-input",
      selectAll: "options-select-label",
    },
  },
  icons: {
    money: "fas fa-dollar-sign",
    percent: "fas fa-percent",
    date: "fas fa-calendar",
    email: "fas fa-envelope",
    phone: "fas fa-phone",
    mobile: "fas fa-mobile-alt",
    fax: "fas fa-fax",
    password: "fas fa-lock",
    time: "fas fa-clock",
    card: "fas fa-credit-card",
    fastSearch: "fas fa-search",
    note: "fas fa-info",
    tooltip: "tooltip-icon fas fa-info-circle",
    delete: "fas fa-trash",
    download: "fas fa-cloud-download-alt",
    upload: "fas fa-cloud-upload-alt",
    showPassword: "fas fa-eye",
    hidePassword: "fas fa-eye-slash",
    moneyPositionStart: true,
    percentPositionStart: true,
    datePositionStart: true,
    emailPositionStart: true,
    phonePositionStart: true,
    mobilePositionStart: true,
    faxPositionStart: true,
    passwordPositionStart: true,
    timePositionStart: true,
    cardPositionStart: true,
    fastSearchPositionStart: true,
    notePositionStart: true,
  },
  Messages: {
    minCharacter: "Informe no mínimo 1 caractere",
    minCharacters: "Informe no mínimo %s1 caracteres",
    maxCharacter: "Informe no máximo 1 caractere",
    maxCharacters: "Informe no máximo %s1 caracteres",
    minMaxCharacters: "Este valor deve ter entre %s1 e %s2 caracteres",

    minNumber: "O valor informado deve ser igual ou maior que %s1",
    maxNumber: "O valor informado deve ser igual ou menor que %s1",
    minMaxNumber: "Este número deve estar entre %s1 e %s2",

    email: "Informe um e-mail válido",
    required: "Esta informação é obrigatória",
    date: "Informe uma data válida",
    number: "Informe um número válido",

    totalFilesAllowedExceeded: "Selecione no máximo %s1 arquivos",
    fileSizeExceededMaxAllowed:
      "O tamanho do arquivo %s1 é maior que o máximo permitido que é de %s2",
  },
};

export default configSmartR;
