import React, {
  createContext,
  useContext,
  useEffect,
  useCallback,
} from "react";
export type BroadcastMessage = {
  id?: string;
  type: string;
  subType?: string;
  data?: any;
  identifier?: string;
};

// Definindo a estrutura do contexto com o tipo específico da mensagem
type BroadcastContextType = {
  sendMessage: (message: BroadcastMessage) => void;
};

// Criando o contexto com um valor padrão
const BroadcastContext = createContext<BroadcastContextType>({
  sendMessage: () => {}, // Função no-op
});

export const BroadcastProvider = ({ children }) => {
  const channelName = "notificacao";
  const channel = new BroadcastChannel(channelName);

  const sendMessage = useCallback(
    (message: BroadcastMessage) => {
      channel.postMessage(message);
    },
    [channel]
  );

  useEffect(() => {
    return () => {
      channel.close(); // Fecha o canal ao desmontar o componente
    };
  }, [channel]);

  return (
    <BroadcastContext.Provider value={{ sendMessage }}>
      {children}
    </BroadcastContext.Provider>
  );
};

export const useBroadcast = () => {
  return useContext(BroadcastContext);
};
export default BroadcastContext;
