import { Suspense } from "react";
import { Loading } from "./Loading";

export const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  );
};
