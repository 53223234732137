import classNames from "classnames";
import { createContext, useState, useContext, useEffect, useRef } from "react";
import { mapToCssModules } from "src/SmartR/Utils/utils";

type DropdownToggleHandler =
  | React.MouseEventHandler<any>
  | React.KeyboardEventHandler<any>;

interface DropdownContextProps {
  isOpen: boolean;
  onToggle: DropdownToggleHandler;
  referenceElement: HTMLElement | null;
  setReferenceElement: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>; // Adiciona a função setOpen
}

const DropdownContext = createContext<DropdownContextProps>({
  isOpen: false,
  onToggle: () => {},
  referenceElement: null,
  setReferenceElement: () => {},
  setOpen: () => {},
});

interface dropdownPropTypes {
  children?: React.ReactNode;
  className?: string;
  isOpen?: boolean;
  id?: string;
  onToggle?: DropdownToggleHandler;
  onShow?: () => void;
  onHide?: () => void;
}

export function Dropdown(props: dropdownPropTypes) {
  const {
    id,
    className,
    isOpen,
    onToggle,
    onShow,
    onHide,
    children,
    ...attributes
  } = props;
  const [open, setOpen] = useState<boolean>(isOpen || false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  );
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (open && onShow) {
      onShow();
    } else if (!open && onHide) {
      onHide();
    }
  }, [open, onShow, onHide]);

  useEffect(() => {
    if (open) {
      const handleClickOutside = (event: MouseEvent) => {
        const target = event.target as Element;
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(target) &&
          !target.closest(".dropdown-item") &&
          !target.closest(".dropdown-menu")
        ) {
          setOpen(false);
        }
      };

      const handleScroll = () => {
        setOpen(false);
      };

      document.addEventListener("mousedown", handleClickOutside);
      window.addEventListener("scroll", handleScroll);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [open]);

  function handleToggle(event: any): void {
    if (onToggle) {
      onToggle(event);
    }
    setOpen(!open);
  }

  const classes = mapToCssModules(classNames(className, "dropdown"));

  return (
    <DropdownContext.Provider
      value={{
        isOpen: open,
        onToggle: handleToggle,
        referenceElement,
        setReferenceElement,
        setOpen,
      }}
    >
      <div ref={dropdownRef} id={id} className={classes} {...attributes}>
        {children}
      </div>
    </DropdownContext.Provider>
  );
}

export const useDropdownContext = () => useContext(DropdownContext);
