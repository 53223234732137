import React from "react";
import configSmartR from "src/configSmartR";

interface RadioPropTypes {
  id?: string;
  options: any[];
  optionsId: string;
  optionsDescription: string;
  optionsFirstSelected: boolean;
  optionsNoneSelectedValue: any;
  optionsNoneSelecteText: string;
  value: any;
  inline: boolean;
  inputRadioRefs: React.MutableRefObject<any[]>;
  editorAttributes: Record<string, any>;
  onBlurEvent: (event: React.FocusEvent<HTMLInputElement>) => Promise<void>;
  onChangeEvent: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  inputRef: React.MutableRefObject<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  >;
  className?: string;
}

export function Radio(props: RadioPropTypes) {
  const {
    id,
    options,
    optionsId,
    optionsDescription,
    optionsFirstSelected,
    optionsNoneSelectedValue,
    optionsNoneSelecteText,
    editorAttributes,
    value,
    inline,
    inputRadioRefs,
    onBlurEvent,
    onChangeEvent,
    inputRef,
    className,

    ...attributes
  } = props;
  let editorValue = value;
  const radioLabelAttributes: Record<string, string> = {};
  const dataSource =
    options && typeof options[Symbol.iterator] === "function"
      ? [...options]
      : [];
  if (!optionsFirstSelected) {
    let noneSelectOption = {};
    noneSelectOption[optionsId] = optionsNoneSelectedValue;
    noneSelectOption[optionsDescription] = optionsNoneSelecteText;
    dataSource.unshift(noneSelectOption);
    if (!editorValue) {
      editorValue = optionsNoneSelectedValue;
    }
  } else {
    if (!editorValue && dataSource.length > 0) {
      editorValue = dataSource[0][optionsId];
    }
  }

  const innerChildren = dataSource.map((option, index) => {
    if (id) {
      radioLabelAttributes["htmlFor"] = `${id}-opt-${index + 1}`;
      editorAttributes["id"] = `${id}-opt-${index + 1}`;
    }
    editorAttributes["value"] = option[optionsId];
    editorAttributes["defaultChecked"] = option[optionsId] === editorValue;
    const radioLabel = React.createElement(
      "label",
      {
        ...radioLabelAttributes,
        className: configSmartR.classes.radioLabel,
      },
      option[optionsDescription],
    );
    const radioRef = React.createRef();
    inputRadioRefs.current[index] = radioRef;

    const radioInput = React.createElement("input", {
      ...attributes,
      ...editorAttributes,
      className: className,
      onChange: onChangeEvent,
      onBlur: onBlurEvent,
      ref: radioRef,
    });

    const radioGroup = React.createElement(
      "div",
      {
        key: index,
        className: !inline
          ? configSmartR.classes.radioGroup
          : configSmartR.classes.radioGroupInLine,
      },
      radioInput,
      radioLabel,
    );

    return radioGroup;
  });

  const optionsGroup = React.createElement(
    "div",
    {
      className: configSmartR.classes.radioGroupList,
      ref: inputRef,
    },
    innerChildren,
  );

  return optionsGroup;
}
