import React, { useState, useEffect } from "react";

//import withRouter
import withRouter from "../../Common/withRouter";

// users
import avatar from "../../../assets/images/users/avatar.png";

//redux
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { createSelector } from "reselect";
import {
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
} from "src/components";
import { Icon } from "@ailibs/feather-react-ts";
import { openConfiguration } from "src/slices/thunks";

const ProfileMenu = (props: any) => {
  const dispatch = useDispatch();

  const dadosAuthSelector = createSelector(
    (state: any) => state.autenticacao,
    (autenticacao: any) => ({
      usuario: autenticacao.usuario,
      versao: autenticacao.versao,
    })
  );
  const { usuario, versao } = useSelector(dadosAuthSelector);

  const urlAvatar = usuario.temFoto
    ? `https://aetmaster.blob.core.windows.net/usuario-avatar/${usuario.id}.png?v=${versao}`
    : avatar;
  const configuracaoHandler = () => {
    dispatch(openConfiguration(true));
  };
  return (
    <React.Fragment>
      <Dropdown>
        <DropdownToggle className="btn header-item user text-start d-flex align-items-center">
          <Icon name="user" className="icon-sm" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end profile pt-0" dir="left">
          <div className="profile-cover-bg">
            <div className="text-center ">
              <img
                src={urlAvatar}
                className="rounded-circle avatar-lg mt-2"
                alt=""
              ></img>
            </div>
            <div className="p-3 border-bottom">
              <h6 className="mb-0 text-white text-center">{usuario.nome}</h6>
              <p className="mb-0 font-size-11 text-white fw-semibold text-center">
                {usuario.email}
              </p>
            </div>
          </div>
          <DropdownItem icon="mdi-account-circle text-muted">
            <Link to="/Perfil"> Perfil</Link>
          </DropdownItem>
          <DropdownItem
            icon="mdi-cogs text-muted"
            className="menu-item-color"
            onClick={configuracaoHandler}
          >
            Configuração
          </DropdownItem>
          <DropdownItem icon="mdi-message-text-outline text-muted">
            <Link to="/chat"> Mensagens</Link>
          </DropdownItem>
          <DropdownItem icon="mdi-calendar-check-outline text-muted">
            <Link to="/kanban-board"> Tarefas</Link>
          </DropdownItem>
          <DropdownItem icon="mdi-lifebuoy text-muted">
            <Link to="/pages-faqs"> Suporte</Link>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem icon="mdi-logout text-muted">
            <Link to="/Logout">Logout</Link>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withRouter(ProfileMenu);
