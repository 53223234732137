import React from "react";

export interface TabProps {
  children?: React.ReactNode;
  title: string;
  identificador?: string;
  className?: string;
  isActive?: boolean; // Propriedade que indica se a aba está ativa
  onEnter?: () => void; // Evento disparado quando a aba é ativada
  onLeave?: () => void; // Evento disparado quando a aba é desativada
}

export const Tab: React.FC<TabProps> = ({
  children,
  identificador,
  className,
  isActive,
}) => {
  return (
    <div
      className={`tab-pane fade ${isActive ? "show active" : ""} ${className}`}
    >
      {children}
    </div>
  );
};
