import "./index.scss";

interface LoadingProps {
  height?: string;
  fontSize?: string;
  text?: string;
}

export const Loading = ({
  height = "70vh",
  fontSize = "5vw",
  text = "Carregando...",
}: LoadingProps) => {
  return (
    <div className="loading-base" style={{ height }}>
      <div className="bg-overlay bg-white"></div>
      <div className="loading-container">
        <div className="loading-text" style={{ fontSize }}>
          {text.split("").map((letter, index) => (
            <span key={index}>{letter}</span>
          ))}
        </div>
      </div>
    </div>
  );
};
