import classNames from "classnames";
import React, { useState, useCallback } from "react";
import { useDropdownContext } from "../Dropdown";
import { mapToCssModules } from "src/SmartR/Utils/utils";

interface dropdownTogglePropTypes {
  children?: React.ReactNode;
  className?: string;
  caret?: boolean;
  id?: string;
  tag?: string;
}

export function DropdownToggle(props: dropdownTogglePropTypes) {
  const {
    id,
    className,
    caret,
    tag = "button",
    children,
    ...attributes
  } = props;

  const {
    isOpen,
    onToggle: contextToggle,
    setReferenceElement,
  } = useDropdownContext();

  const handleClick = (event: any) => {
    contextToggle(event);
  };

  const classes = mapToCssModules(
    classNames(className, "dropdown-toggle", { caret, show: isOpen })
  );

  const dynamicAttributes = {
    id,
    onClick: handleClick,
    "aria-expanded": isOpen,
    className: classes,
    ...attributes,
  };

  const ref = useCallback(
    (node: HTMLElement | null) => {
      if (node) setReferenceElement(node);
    },
    [setReferenceElement]
  );

  return React.createElement(tag, { ...dynamicAttributes, ref }, children);
}
