import classNames from "classnames";
import React, { useRef, useState } from "react";
import { Form } from "src/SmartR";
import { mapToCssModules } from "src/SmartR/Utils/utils";

import {
  BotaoFiltroLista,
  BotaoNovoRegistro,
  PaginaLayout,
  TableColumnProps,
} from "src/components";

import TableContainer from "src/components/Common/TableContainer";
import { useDropdownContext } from "src/components/atoms/Dropdown";

interface PaginaListaProps {
  item: string;
  titulo: string;
  modulo: string;
  urlNovo?: string;
  urlFetch?: string;
  urlDelete?: string;
  urlReativar?: string;
  urlInativar?: string;
  children?: any;
  filtroState?: any;
  colunas?: TableColumnProps[];
  colunasDetalhe?: TableColumnProps[];
  propriedadeDetalhe?: string;
  mostrarCabecalho?: boolean;
  mostrarCabecalhoDetalhe?: boolean;
  habilitarEfeitoHover?: boolean;
  habilitarEfeitoHoverDetalhe?: boolean;
  mostrarRegistroUnicoTabela?: boolean;
  cabecalhoUnicoRegistroUnico?: boolean;
  funcao: number;
  formClassName?: string;
  tabelaClassName?: string;
  tabelaDetalheClassName?: string;
  onNovoHandle?: any;
  onRegistroCarregado?: any;
}

export const PaginaLista = ({
  funcao,
  titulo,
  modulo,
  item,
  urlNovo,
  urlDelete,
  urlFetch,
  urlInativar,
  urlReativar,
  colunas,
  colunasDetalhe,
  propriedadeDetalhe,
  mostrarCabecalho = true,
  mostrarCabecalhoDetalhe = true,
  habilitarEfeitoHover = true,
  habilitarEfeitoHoverDetalhe = true,
  mostrarRegistroUnicoTabela = false,
  cabecalhoUnicoRegistroUnico = false,
  children,
  formClassName = "form-w-sm",
  tabelaClassName,
  tabelaDetalheClassName,
  onNovoHandle,
  filtroState,
}: PaginaListaProps) => {
  const { setOpen } = useDropdownContext();
  const [isLoading, setIsLoading] = useState(true);
  const [filtro, setFiltro] = useState(filtroState);

  const handleSubmit = (data: any) => {
    setFiltro(data);
  };

  const classes = mapToCssModules(classNames(tabelaClassName, "context-menu"));

  return (
    <React.Fragment>
      <PaginaLayout
        funcao={funcao}
        titulo={titulo}
        modulo={modulo}
        item={item}
        temFavorito={true}
      >
        {!isLoading ? (
          <>
            {urlNovo || onNovoHandle ? (
              <BotaoNovoRegistro onClickHandle={onNovoHandle} path={urlNovo} />
            ) : null}
            {children ? (
              <BotaoFiltroLista>
                <Form
                  className={`px-4 py-3 filter-form ${formClassName}`}
                  onSubmit={handleSubmit}
                >
                  {children}
                  <button type="submit" className="btn btn-primary float-end">
                    Confirmar
                  </button>
                </Form>
              </BotaoFiltroLista>
            ) : null}
          </>
        ) : null}

        <div className="card">
          <div className="card-body">
            <TableContainer
              className={classes}
              classNameDetail={tabelaDetalheClassName}
              columns={colunas}
              columnsDetail={colunasDetalhe}
              dataDetailProperty={propriedadeDetalhe}
              showHeader={mostrarCabecalho}
              showDetailHeader={mostrarCabecalhoDetalhe}
              enableHoverEffect={habilitarEfeitoHover}
              enableHoverEffectDetail={habilitarEfeitoHoverDetalhe}
              singleRecordTable={mostrarRegistroUnicoTabela}
              uniqueHeaderSingleRecordTable={cabecalhoUnicoRegistroUnico}
              filterForm={filtro}
              urlFetch={urlFetch}
              urlDelete={urlDelete}
              urlInactive={urlInativar}
              urlReactive={urlReativar}
              onLoadingStart={() => {
                setIsLoading(true);
                setOpen(false);
              }}
              onLoadingEnd={() => {
                setIsLoading(false);
              }}
            />
          </div>
        </div>
      </PaginaLayout>
    </React.Fragment>
  );
};
