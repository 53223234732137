import React, { useCallback, useEffect, useRef } from "react";

//Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { Link, useLocation } from "react-router-dom";

//WithRouter
import withRouter from "../Common/withRouter";

import { menuItems } from "../CommonForBoth/Menu";

import classNames from "classnames";

const ModuloItem = ({ modulo }) => {
  return (
    <li>
      <Link to="/#" className={classNames("has-arrow", "waves-effect")}>
        {modulo.icon && <i className={`icon mdi ${modulo.icon} nav-icon`}></i>}
        <span className="menu-item">{modulo.label}</span>
        {modulo.badge && (
          <span className={"badge rounded-pill " + modulo.badgecolor}>
            {modulo.badge}
          </span>
        )}
      </Link>
      <ul className="sub-menu">
        {(modulo.funcoes || [])
          .filter((funcao) => funcao.menu)
          .map((funcao, key) => {
            return <FuncaoItem key={key} funcao={funcao} />;
          })}
      </ul>
    </li>
  );
};

const FuncaoItem = ({ funcao }) => {
  return (
    <li>
      <Link to={funcao.link}>
        {funcao.icon && <i className={`icon mdi ${funcao.icon} nav-icon`} />}
        <span className="menu-item">{funcao.label}</span>
        {funcao.badge && (
          <span className={"badge rounded-pill " + funcao.badgecolor}>
            {funcao.badge}
          </span>
        )}
      </Link>
    </li>
  );
};

const GrupoItem = ({ grupo }) => {
  return (
    <>
      <li className="menu-title" data-key="t-layouts">
        {grupo.label}
      </li>
      {(grupo.funcoes || [])
        .filter((funcao) => funcao.menu)
        .map((funcao, key) => {
          return <FuncaoItem key={key} funcao={funcao} />;
        })}
      {(grupo.modulos || []).map((modulo, key) => {
        return <ModuloItem key={key} modulo={modulo} />;
      })}
    </>
  );
};

const SidebarContent = (props: any) => {
  const ref = useRef<any>();

  const activateParentDropdown = useCallback((item: any) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }
    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement.closest("ul");
      if (parent2 && parent2.id !== "side-menu") {
        parent2.classList.add("mm-show");
        const parent3 = parent2.parentElement;
        if (parent3) {
          parent3.classList.add("mm-active");
          var childAnchor = parent3.querySelector(".has-arrow");
          var childDropdown = parent3.querySelector(".has-dropdown");
          if (childAnchor) childAnchor.classList.add("mm-active");
          if (childDropdown) childDropdown.classList.add("mm-active");

          const parent4 = parent3.parentElement;
          if (parent4 && parent4.id !== "side-menu") {
            parent4.classList.add("mm-show");
            const parent5 = parent4.parentElement;
            if (parent5 && parent5.id !== "side-menu") {
              parent5.classList.add("mm-active");
              const childanchor = parent5.querySelector(".is-parent");
              if (childanchor && parent5.id !== "side-menu") {
                childanchor.classList.add("mm-active");
              }
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items: any) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul: any = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  const scrollElement = useCallback(
    (item: any) => {
      if (item && ref && ref.current) {
        const currentPosition = item.offsetTop;
        if (currentPosition > window.innerHeight) {
          ref.current.getScrollElement().scrollTop = currentPosition - 300;
          window.scrollTo(0, currentPosition - 300);
        }
      }
    },
    [ref],
  );

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    activeMenu();
  }, [activeMenu]);

  return (
    <React.Fragment>
      <SimpleBar ref={ref} className="sidebar-menu-scroll" id="nav-scroll">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {(menuItems || []).map((item, key) => {
              return <GrupoItem key={key} grupo={item} />;
            })}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default withRouter(SidebarContent);
