import axios from "axios";
import jwtDecode from "jwt-decode";
import { AdminUtil } from "src/util/AdminUtil";
const API_BASE_URL = process.env.REACT_APP_API_BASE;

axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.get["Access-Control-Allow-Origin"] = "*";

const tokenValido = (tokenJWT) => {
  if (!tokenJWT) return false;

  const decodedToken: any = jwtDecode(tokenJWT);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

const getTokenJWT = (validarToken: boolean = true) => {
  const tokenJWT = window.localStorage.getItem("tokenJWT");
  if (!tokenJWT || (validarToken && !tokenValido(tokenJWT))) {
    return null;
  }
  return tokenJWT;
};
const podeRenovarTokenJWT = () => {
  const tokenJWT = window.localStorage.getItem("tokenJWT");
  if (!tokenJWT) return false;

  const decodedToken: any = jwtDecode(tokenJWT);
  const currentTime = Date.now() / 1000;

  // Verifica se o token ainda está válido
  if (decodedToken.exp > currentTime) {
    return true;
  }

  // Verifica se o token expirou a menos de 24 horas
  const timeSinceExpiration = currentTime - decodedToken.exp;
  const twentyFourHoursInSeconds = 24 * 60 * 60; // 86400 segundos

  return timeSinceExpiration <= twentyFourHoursInSeconds;
};

const getUsuarioSessao = (validarToken: boolean = true) => {
  const tokenJWT = getTokenJWT(validarToken);
  if (!tokenJWT) {
    return null;
  }
  const decodedToken: any = jwtDecode(tokenJWT);

  const toCamelCase = (str) => {
    return str.charAt(0).toLowerCase() + str.slice(1);
  };
  const dadosUsuario = JSON.parse(decodedToken.usuario);
  const userDataCamelCase = {};
  for (const key in dadosUsuario) {
    if (dadosUsuario.hasOwnProperty(key)) {
      userDataCamelCase[toCamelCase(key)] = dadosUsuario[key];
    }
  }
  return userDataCamelCase;
};
const getUsuarioVersao = (validarToken: boolean = true) => {
  const tokenJWT = getTokenJWT();
  if (!tokenJWT) {
    return null;
  }
  const decodedToken: any = jwtDecode(tokenJWT);
  return decodedToken.versao;
};
const salvarSessao = (tokenJWT) => {
  if (tokenJWT) {
    localStorage.setItem("tokenJWT", tokenJWT); //alterar para cookie http only
  } else {
    localStorage.removeItem("tokenJWT");
  }
};
const obterResponse = (response: any) => {
  return !(
    Object.hasOwn(response, "data") &&
    response.request &&
    response.headers
  )
    ? response
    : response.data;
};
const gomelius = {
  autenticarUsuario: (email, senha) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_BASE_URL}Auth/AutenticarUsuario`, {
          email: email,
          senha: senha,
        })
        .then((response: any) => {
          const usuarioId = response.usuarioId;
          const chavePreAutorizacao = response.chavePreAutorizacao;
          axios
            .post(`${API_BASE_URL}Auth/ListaClienteUsuario`, {
              usuarioId: usuarioId,
              chavePreAutorizacao: chavePreAutorizacao,
            })
            .then((response: any) => {
              if (response.length === 1) {
                const clienteSistema = response[0];

                gomelius
                  .autenticarUsuarioCliente(
                    usuarioId,
                    chavePreAutorizacao,
                    clienteSistema
                  )
                  .then(function (dados: any) {
                    resolve({
                      selecionarEmpresa: false,
                      usuario: dados.usuario,
                      versao: dados.versao,
                    });
                  })
                  .catch(function (error) {
                    resolve(error);
                  });
              } else {
                resolve({
                  selecionarEmpresa: true,
                  usuario: null,
                  usuarioId: usuarioId,
                  versao: null,
                  chavePreAutorizacao: chavePreAutorizacao,
                  lista: response,
                });
              }
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  autenticarUsuarioCliente: (
    usuarioId,
    chavePreAutorizacao,
    clienteSistema
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_BASE_URL}Auth/AutenticarUsuarioCliente`, {
          id: clienteSistema.id,
          usuarioId: usuarioId,
          clienteId: clienteSistema.clienteId,
          chavePreAutorizacao: chavePreAutorizacao,
        })
        .then((response: any) => {
          salvarSessao(response.token);
          const decodedToken: any = jwtDecode(response.token);

          gomelius
            .obter("Usuario/Listafavorito")
            .then((lista: any) => {
              AdminUtil.salvarListaFavorito(lista);
            })
            .catch(() => {
              AdminUtil.salvarListaFavorito([]);
            });

          gomelius
            .obter("Usuario/ObterConfiguracao")
            .then((configuracao: any) => {
              AdminUtil.salvarConfiguracao(configuracao);
              resolve({
                usuario: response.usuario,
                versao: decodedToken.versao,
              });
            })
            .catch(function () {
              resolve({
                usuario: response.usuario,
                versao: decodedToken.versao,
              });
            });

          gomelius
            .obter("Notificacao/TotalNaoLido")
            .then((resp: any) => {
              localStorage.setItem("TotalNotificacao", resp.data ?? 0);
            })
            .catch(() => {
              localStorage.setItem("TotalNotificacao", "0");
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  atualizarToken: () => {
    return new Promise((resolve, reject) => {
      const url = `${API_BASE_URL}Auth/AtualizarToken`;
      const config = {
        headers: { Authorization: `Bearer ${getTokenJWT()}` },
      };
      axios
        .post(url, null, config)
        .then((response: any) => {
          const decodedToken: any = jwtDecode(response.token);
          salvarSessao(response.token);
          resolve({ usuario: response.usuario, versao: decodedToken.versao });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  renovarToken: (senha) => {
    return new Promise((resolve, reject) => {
      const usuario: any = getUsuarioSessao(false);
      const token: any = getTokenJWT(false);
      if (usuario && token) {
        axios
          .post(`${API_BASE_URL}Auth/RenovarToken`, {
            usuarioId: usuario.id,
            clienteId: usuario.clienteId,
            email: usuario.email,
            senha: senha,
            token: token,
          })
          .then((response: any) => {
            salvarSessao(response.token);
            const decodedToken: any = jwtDecode(response.token);

            gomelius
              .obter("Usuario/Listafavorito")
              .then((lista: any) => {
                AdminUtil.salvarListaFavorito(lista);
              })
              .catch(() => {
                AdminUtil.salvarListaFavorito([]);
              });

            gomelius
              .obter("Usuario/ObterConfiguracao")
              .then((configuracao) => {
                AdminUtil.salvarConfiguracao(configuracao);
                resolve({
                  usuario: response.usuario,
                  versao: decodedToken.versao,
                });
              })
              .catch(function (error) {
                resolve({
                  usuario: response.usuario,
                  versao: decodedToken.versao,
                });
              });
            gomelius
              .obter("Notificacao/TotalNaoLido")
              .then((resp: any) => {
                localStorage.setItem("TotalNotificacao", resp.data ?? 0);
              })
              .catch(() => {
                localStorage.setItem("TotalNotificacao", "0");
              });
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject("Não tem usuário na sessão, deverá realizar o login.");
      }
    });
  },
  getToken: () => {
    const token = getTokenJWT();
    return token;
  },
  obterUsuarioAutenticado: (validarToken: boolean = true) => {
    return new Promise((resolve, reject) => {
      const tokenJWT = getTokenJWT(validarToken);
      if (tokenJWT) {
        const usuario = getUsuarioSessao(validarToken);
        const versao = getUsuarioVersao(validarToken);
        resolve({ usuario: usuario, versao: versao });
      } else {
        reject({ renovarSessao: podeRenovarTokenJWT() });
      }
    });
  },
  logout: () => {
    salvarSessao(null);
  },
  obterRegistro: (id, endPoint) => {
    const config = {
      headers: { Authorization: `Bearer ${getTokenJWT()}` },
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_BASE_URL}${endPoint}/${id}`, config)
        .then((response: any) => {
          resolve(obterResponse(response));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  obterOK: (endPoint: string): Promise<void> => {
    const config: any = {
      headers: { Authorization: `Bearer ${getTokenJWT()}` },
    };

    return new Promise<void>((resolve, reject) => {
      axios
        .get(`${API_BASE_URL}${endPoint}`, config)
        .then(() => {
          resolve(); // Resolve a promise sem retornar valor
        })
        .catch(() => {
          reject(); // Rejeita a promise sem retornar valor
        });
    });
  },
  obterBoolean: (endPoint) => {
    const config = {
      headers: { Authorization: `Bearer ${getTokenJWT()}` },
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_BASE_URL}${endPoint}`, config)
        .then((response: any) => {
          resolve(Boolean(obterResponse(response)));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  obter: (endPoint) => {
    const config = {
      headers: { Authorization: `Bearer ${getTokenJWT()}` },
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_BASE_URL}${endPoint}`, config)
        .then((response: any) => {
          resolve(obterResponse(response));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  obterChildren: (id, childrenId, endPoint) => {
    const config = {
      headers: { Authorization: `Bearer ${getTokenJWT()}` },
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_BASE_URL}${endPoint}/${id}/${childrenId}`, config)
        .then((response: any) => {
          resolve(obterResponse(response));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  novoRegistro: (data, endPoint) => {
    const config = {
      headers: { Authorization: `Bearer ${getTokenJWT()}` },
    };
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_BASE_URL}${endPoint}`, data, config)
        .then((response: any) => {
          resolve(obterResponse(response));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  post: (data, endPoint) => {
    const config = {
      headers: { Authorization: `Bearer ${getTokenJWT()}` },
    };
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_BASE_URL}${endPoint}`, data, config)
        .then((response: any) => {
          resolve(obterResponse(response));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  postChildren: (id, data, endPoint) => {
    const config = {
      headers: { Authorization: `Bearer ${getTokenJWT()}` },
    };
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_BASE_URL}${endPoint}/${id}`, data, config)
        .then((response: any) => {
          resolve(obterResponse(response));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  postChildrenForm: (id, data, endPoint) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getTokenJWT()}`,
        "Content-Type": "multipart/form-data",
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_BASE_URL}${endPoint}/${id}`, data, config)
        .then((response: any) => {
          resolve(obterResponse(response));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  put: (id, data, endPoint) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getTokenJWT()}`,
      },
    };
    const url = id
      ? `${API_BASE_URL}${endPoint}/${id}`
      : `${API_BASE_URL}${endPoint}`;
    return new Promise((resolve, reject) => {
      axios
        .put(url, data, config)
        .then((response: any) => {
          resolve(obterResponse(response));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  putChildren: (id, childrenId, data, endPoint) => {
    const config = {
      headers: { Authorization: `Bearer ${getTokenJWT()}` },
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`${API_BASE_URL}${endPoint}/${id}/${childrenId}`, data, config)
        .then((response: any) => {
          resolve(obterResponse(response));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  delete: (id, endPoint) => {
    const config = {
      headers: { Authorization: `Bearer ${getTokenJWT()}` },
    };
    return new Promise((resolve, reject) => {
      axios
        .delete(`${API_BASE_URL}${endPoint}/${id}`, config)
        .then((response: any) => {
          resolve(obterResponse(response));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteChildren: (id, childrenId, endPoint) => {
    const config = {
      headers: { Authorization: `Bearer ${getTokenJWT()}` },
    };
    return new Promise((resolve, reject) => {
      axios
        .delete(`${API_BASE_URL}${endPoint}/${id}/${childrenId}`, config)
        .then((response: any) => {
          resolve(obterResponse(response));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  obterListaFiltro: (form, endPoint, useHTTPPOST = false) => {
    function addQueryString(url, params) {
      if (url.includes("?")) {
        return `${url}&${params}`;
      } else {
        return `${url}?${params}`;
      }
    }
    function serializeForm(obj) {
      const partes = [];
      for (const chave in obj) {
        if (
          obj.hasOwnProperty(chave) &&
          obj[chave] !== null &&
          obj[chave] !== undefined &&
          obj[chave] !== ""
        ) {
          partes.push(
            `${encodeURIComponent(chave)}=${encodeURIComponent(obj[chave])}`
          );
        }
      }
      return partes.join("&");
    }
    return new Promise((resolve, reject) => {
      const url = `${API_BASE_URL}${endPoint}`;
      const config = {
        headers: { Authorization: `Bearer ${getTokenJWT()}` },
      };
      if (!useHTTPPOST) {
        let urlRequest = url;
        if (form) {
          const queryForm = serializeForm(form);
          urlRequest = addQueryString(url, queryForm);
        }

        axios
          .get(urlRequest, config)
          .then((response: any) => {
            resolve(obterResponse(response));
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        axios
          .post(url, form, config)
          .then((response: any) => {
            resolve(obterResponse(response));
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  },
  obterLista: (endPoint) => {
    return new Promise((resolve, reject) => {
      const url = `${API_BASE_URL}${endPoint}`;
      const config = {
        headers: { Authorization: `Bearer ${getTokenJWT()}` },
      };
      axios
        .get(url, config)
        .then((response: any) => {
          resolve(obterResponse(response));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  obterListaSubCadastro: (id, endPoint) => {
    return new Promise((resolve, reject) => {
      const url = `${API_BASE_URL}${endPoint}/${id}`;
      const config = {
        headers: { Authorization: `Bearer ${getTokenJWT()}` },
      };
      axios
        .get(url, config)
        .then((response: any) => {
          resolve(obterResponse(response));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default gomelius;
