import { Link } from "react-router-dom";

function Pagina404() {
  return (
    <>
      <h1>Nada encontrado</h1>
    </>
  );
}

export default Pagina404;
