import React, { useEffect, useState, useCallback } from "react";
import {
  DropdownToggle,
  DropdownMenu,
  NotificacaoCard,
  Dropdown,
} from "src/components";
import SimpleBar from "simplebar-react";
import withRouter from "../../Common/withRouter";
import { Icon } from "@ailibs/feather-react-ts";
import { Column, Row } from "src/SmartR";
import gomelius from "src/api/gomelius";
import { BroadcastMessage } from "src/contexts";
const NotificationDropdown = (props: any) => {

  const channel = new BroadcastChannel("notificacao");
  const [totalPagina, setTotalPagina] = useState(0);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [menu, setMenu] = useState(false);
  const [carregando, setCarregando] = useState(true);
  const [lista, setLista] = useState([]);

  const scrollableNodeRef = React.createRef();

  const handleScroll = useCallback(
    (event) => {
      const element = event.currentTarget;
      if (
        Math.abs(
          element.scrollHeight - element.scrollTop - element.clientHeight
        ) < 20
      ) {
        if (!carregando && paginaAtual < totalPagina) {
          setPaginaAtual((prevPaginaAtual) => prevPaginaAtual + 1);
          setCarregando(true);
        }
      }
    },
    [carregando, paginaAtual, totalPagina]
  );

  useEffect(() => {
    channel.onmessage = (event) => {
      const message = event.data as BroadcastMessage;
      if (message.type === "notificacao") {
        const totalNotificacao =
          window.localStorage.getItem("TotalNotificacao");
        if (totalNotificacao) {
          setTotalRegistros(parseInt(totalNotificacao));
        }
      }
    };
    return () => {
      channel.close(); // Fecha o canal ao desmontar
    };
  }, []);
  useEffect(() => {
    if (menu && carregando) {
      const filter = {
        pagina: paginaAtual,
        registrosPagina: 10,
      };
      gomelius
        .obterListaFiltro(filter, "Notificacao/Filtro")
        .then((info: any) => {
          setTotalPagina(info.totalPagina);
          setTotalRegistros(info.totalRegistro);
          setLista((lista) => [...lista, ...info.lista]);
        })
        .finally(() => {
          setCarregando(false);
        });
    }
  }, [menu, carregando, paginaAtual]);

  useEffect(() => {
    const element = scrollableNodeRef.current;
    if (element instanceof HTMLElement) {
      element.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (element instanceof HTMLElement) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  if (totalRegistros === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <Dropdown onShow={() => setMenu(true)} onHide={() => setMenu(false)}>
        <DropdownToggle
          className="btn header-item noti-icon"
          id="page-header-notifications-dropdown"
        >
          <Icon name="bell" className="icon-sm" />
          <span className="noti-dot bg-danger"></span>
        </DropdownToggle>

        <DropdownMenu
          className="dropdown-menu-lg dropdown-menu-end p-0"
          dir="left"
        >
          <div className="p-3">
            <Row className="align-items-center">
              <Column>
                <h5 className="m-0 font-size-15"> Notificações</h5>
              </Column>
            </Row>
          </div>

          <SimpleBar
            scrollableNodeProps={{ ref: scrollableNodeRef }}
            style={{ maxHeight: "230px" }}
          >
            <>
              {lista.map((notificacao) => (
                <NotificacaoCard dados={notificacao} key={notificacao.id} />
              ))}
              {carregando && (
                <p className="pt-3 text-center fw-bold">Carregando...</p>
              )}
            </>
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withRouter(NotificationDropdown);
