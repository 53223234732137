export const OffCanvas = (props) => {
  const { children, direction, isOpen } = props;

  return (
    <>
      <div style={{ position: "relative", zIndex: "1050", display: "block" }}>
        <div
          className={`offcanvas offcanvas-${direction} ${
            isOpen ? "fade show" : ""
          }`}
          tabIndex={-1}
          role="dialog"
          style={{ visibility: isOpen ? "visible" : "hidden" }}
          aria-labelledby="offcanvasExampleLabel"
        >
          {children}
        </div>
        {isOpen ? (
          <div
            className={`offcanvas-backdrop  ${isOpen ? "fade show" : ""}`}
          ></div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
