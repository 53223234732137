import React from "react";

import Breadcrumbs from "../../Common/Breadcrumb";

interface PaginaLayoutProps {
  item: string;
  titulo: string;
  modulo: string;
  temFavorito?: boolean;
  children?: any;
  funcao?: number;
}

export const PaginaLayout = ({
  funcao,
  titulo,
  modulo,
  item,
  temFavorito = false,
  children,
}: PaginaLayoutProps) => {
  document.title = `${titulo} | AET Master`;

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            funcao={funcao}
            modulo={modulo}
            titulo={titulo}
            item={item}
            temFavorito={temFavorito}
          />
          <div id="base-loading">{children}</div>
        </div>
      </div>
    </React.Fragment>
  );
};
