import "./index.scss";
export const ValidacaoSenha = ({ senha }) => {
  const validations = [
    { text: "Mínimo 8 caracteres", isValid: (senha?? "").length >= 8 },
    { text: "Pelo menos uma letra maiúscula", isValid: /[A-Z]/.test(senha) },
    { text: "Pelo menos um número", isValid: /\d/.test(senha) },
    {
      text: "Pelo menos um caractere especial(!@#$%^&*(),.?\":{}|<>)",
      isValid: /[!@#$%^&*(),.?":{}|<>]/.test(senha),
    },
  ];

  return (
    <ul className="password-validation-list">
      {validations.map((validation, index) => (
        <li
          key={index}
          className={`password-validation-item ${
            validation.isValid ? "valid" : "invalid"
          }`}
        >
          <i
            className={`icon mdi ${
              validation.isValid ? "mdi-check-circle" : "mdi-close-circle"
            } ms-1`}
          />{" "}
          {validation.text}
        </li>
      ))}
    </ul>
  );
};
