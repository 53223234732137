import React from "react";
import "./index.scss";

interface TreeWithCheckboxProps {
  source: any[];
  onSelectionChange: (updatedItems: any[], selected: boolean) => void;
  selectedItemId?: number; // Propriedade para identificar o item selecionado
  descriptionProp: string; // Nome da propriedade que contém a descrição
  subItemsProp: string; // Nome da propriedade que contém os subitens (módulos/funções)
  iconProp?: string; // Propriedade para o ícone de cada item
  checkedProp?: string; // Propriedade para saber se o item vem marcado
  defaultOpenIcon?: string; // Ícone padrão para árvore aberta
  defaultCloseIcon?: string; // Ícone padrão para árvore fechada
}

interface TreeWithCheckboxState {
  selectedItems: string[]; // Armazena o identificador único de itens selecionados
  collapsedItems: { [key: string]: boolean }; // Controle dos itens minimizados
}

export class TreeWithCheckbox extends React.Component<
  TreeWithCheckboxProps,
  TreeWithCheckboxState
> {
  constructor(props: TreeWithCheckboxProps) {
    super(props);

    const preselectedItems = this.getPreselectedItems(
      props.source,
      props.subItemsProp,
      props.checkedProp
    );

    this.state = {
      selectedItems: preselectedItems,
      collapsedItems: {},
    };
  }

  // Obtém os IDs pré-selecionados
  getPreselectedItems = (
    items: any[],
    subItemsProp: string,
    checkedProp?: string,
    parentId: string = ""
  ): string[] => {
    let preselected: string[] = [];

    items.forEach((item) => {
      const uniqueId = `${parentId}_${item.id}`; // Cria um identificador único com o nível da árvore

      if (checkedProp && item[checkedProp]) {
        preselected.push(uniqueId);
      }

      if (item[subItemsProp] && item[subItemsProp].length > 0) {
        preselected = [
          ...preselected,
          ...this.getPreselectedItems(
            item[subItemsProp],
            subItemsProp,
            checkedProp,
            uniqueId // Passa o identificador pai para os subitens
          ),
        ];
      }
    });

    return preselected;
  };
  handleCheckboxChange = (id: string, item: any, childrenIds: string[]) => {
    const { selectedItems } = this.state;
    let updatedSelectedItems = [...selectedItems];
    let modifiedItems: any[] = [];
    const isSelected = selectedItems.includes(id);

    const updateItemSelection = (
      currentItem: any,
      isSelected: boolean
    ): any => {
      // Verifica se o item já está em modifiedItems
      const existingItem = modifiedItems.find(
        (modifiedItem) => modifiedItem.id === currentItem.id
      );

      if (!existingItem) {
        // Atualiza a seleção no próprio item
        const updatedItem = { ...currentItem, selecionado: !isSelected };
        modifiedItems.push(updatedItem); // Adiciona o item à lista de modificados
        return updatedItem;
      }
      return currentItem; // Se o item já foi modificado, retorna o item original
    };

    // Atualiza o item clicado
    updateItemSelection(item, isSelected);

    if (isSelected) {
      // Se o item já está selecionado, removemos ele e seus filhos e os adicionamos à lista de modificados
      updatedSelectedItems = updatedSelectedItems.filter((itemId) => {
        const isToRemove = itemId === id || childrenIds.includes(itemId);
        if (isToRemove) {
          const childItem = this.findItemById(this.props.source, itemId);
          if (childItem) {
            updateItemSelection(childItem, true); // Marca como removido
          }
        }
        return !isToRemove;
      });
    } else {
      // Se o item não está selecionado, adicionamos ele e seus filhos
      updatedSelectedItems = [
        ...new Set([
          ...updatedSelectedItems,
          id,
          ...childrenIds.filter(
            (childId) => !updatedSelectedItems.includes(childId)
          ),
        ]),
      ];

      // Adiciona os itens filhos modificados na lista de modificados
      childrenIds.forEach((childId) => {
        const childItem = this.findItemById(this.props.source, childId); // Função para encontrar o item pelo ID
        if (childItem) {
          updateItemSelection(childItem, isSelected);
        }
      });
    }

    // Atualiza o estado e dispara o callback de seleção com os objetos modificados
    this.setState({ selectedItems: updatedSelectedItems }, () => {
      this.props.onSelectionChange(modifiedItems, !isSelected); // Retorna os objetos correspondentes aos itens alterados
    });
  };

  // Função auxiliar para encontrar um item pelo ID
  findItemById = (
    items: any[],
    searchId: string,
    parentId: string = ""
  ): any | null => {
    let result: any = null;

    for (const item of items) {
      const uniqueId = `${parentId}_${item.id}`;

      if (uniqueId === searchId) {
        return item;
      }

      if (
        item[this.props.subItemsProp] &&
        item[this.props.subItemsProp].length > 0
      ) {
        result = this.findItemById(
          item[this.props.subItemsProp],
          searchId,
          uniqueId
        );
        if (result) {
          return result;
        }
      }
    }

    return result;
  };

  // Função auxiliar para mapear os IDs selecionados de volta para os objetos completos
  mapSelectedItemsToObjects = (
    items: any[],
    selectedIds: string[],
    parentId: string = ""
  ): any[] => {
    let result: any[] = [];
    items.forEach((item) => {
      const uniqueId = `${parentId}_${item.id}`;
      if (selectedIds.includes(uniqueId)) {
        // Se o item está selecionado, o adicionamos ao resultado
        result.push(item);
      }

      // Verifica os subitens (recursivamente)
      if (
        item[this.props.subItemsProp] &&
        item[this.props.subItemsProp].length > 0
      ) {
        const subItems = this.mapSelectedItemsToObjects(
          item[this.props.subItemsProp],
          selectedIds,
          uniqueId
        );
        result = [...result, ...subItems];
      }
    });

    return result;
  };

  // Função para atualizar apenas o item e seus subitens
  buildUpdatedItems = (
    item: any,
    selectedItems: string[],
    parentId: string = ""
  ): any[] => {
    const uniqueId = `${parentId}_${item.id}`;
    const isSelected = selectedItems.includes(uniqueId);

    const updatedItem = {
      ...item,
      selecionado: isSelected,
      [this.props.subItemsProp]: item[this.props.subItemsProp]
        ? item[this.props.subItemsProp].map((subItem: any) =>
            this.buildUpdatedItems(subItem, selectedItems, uniqueId)
          )
        : [],
    };

    return updatedItem;
  };

  // Obtém todos os IDs dos filhos diretos
  getChildrenIds = (
    item: any,
    subItemsProp: string,
    parentId: string = ""
  ): string[] => {
    const uniqueId = `${parentId}_${item.id}`; // Identificador único para o item
    let childrenIds: string[] = []; // Coletar apenas os filhos diretos

    if (item[subItemsProp] && item[subItemsProp].length > 0) {
      item[subItemsProp].forEach((child: any) => {
        const childId = `${parentId}_${child.id}`; // Corrigir a lógica do ID
        childrenIds.push(childId); // Adiciona o filho direto
        if (child[subItemsProp] && child[subItemsProp].length > 0) {
          // Recursivamente obtém os IDs dos filhos sem duplicar o ID do pai
          const subChildIds = this.getChildrenIds(
            child,
            subItemsProp,
            `${parentId}_${child.id}`
          );
          childrenIds = [...childrenIds, ...subChildIds];
        }
      });
    }

    return childrenIds;
  };

  // Alterna o estado de colapso/expansão
  toggleCollapse = (id: string) => {
    this.setState((prevState) => ({
      collapsedItems: {
        ...prevState.collapsedItems,
        [id]: !prevState.collapsedItems[id],
      },
    }));
  };

  // Renderiza os itens da árvore recursivamente
  renderItems = (items: any[], level: number = 0, parentId: string = "") => {
    const {
      descriptionProp,
      subItemsProp,
      iconProp,
      defaultOpenIcon,
      defaultCloseIcon,
    } = this.props;
    const { selectedItems, collapsedItems } = this.state;

    return items.map((item) => {
      const uniqueId = `${parentId}_${item.id}`; // Identificador único com o nível da árvore
      const childrenIds = this.getChildrenIds(item, subItemsProp, uniqueId); // Obtém apenas os filhos diretos
      const isSelected = selectedItems.includes(uniqueId);

      return (
        <div key={uniqueId}>
          <div
            className="tree-item tree-item-level"
            style={{ marginLeft: level * 20 }}
          >
            {item[subItemsProp] && item[subItemsProp].length > 0 && (
              <button
                type="button"
                className="tree-toggle-btn"
                onClick={() => this.toggleCollapse(uniqueId)}
              >
                {collapsedItems[uniqueId]
                  ? defaultOpenIcon || "+"
                  : defaultCloseIcon || "-"}
              </button>
            )}

            <input
              type="checkbox"
              className="tree-checkbox"
              checked={isSelected} // Marca o checkbox se o item estiver selecionado
              onChange={() =>
                this.handleCheckboxChange(uniqueId, item, childrenIds)
              } // Marca/desmarca o item e seus filhos
            />
            {/* Ícone do item */}
            {iconProp && item[iconProp] && (
              <span className="tree-icon">
                <i className={item[iconProp]}></i>
              </span>
            )}
            <span className="tree-label">{item[descriptionProp]}</span>
          </div>

          {/* Renderizar Subitens se o Módulo/Função não estiver minimizado */}
          {item[subItemsProp] &&
            item[subItemsProp].length > 0 &&
            !collapsedItems[uniqueId] && (
              <div className="tree-subitems">
                {this.renderItems(item[subItemsProp], level + 1, uniqueId)}
              </div>
            )}
        </div>
      );
    });
  };

  render() {
    const { source } = this.props;
    return <div>{this.renderItems(source)}</div>;
  }
}
