import classNames from "classnames";
import { mapToCssModules } from "src/SmartR/Utils/utils";

export interface HeaderDividerProps {
  title?: string;
  actionTitle?: string;
  actionRoute?: string;
  actionClassName?: string;
  actionOnClick?: any;
  hasAction?: boolean;
}
export const HeaderDivider = ({
  title,
  actionTitle,
  actionRoute,
  actionOnClick,
  actionClassName,
  hasAction = true,
}: HeaderDividerProps) => {
  let classes = mapToCssModules(
    classNames("fw-medium", actionClassName ? actionClassName : "text-reset"),
  );

  return (
    <>
      <div className="d-flex flex-wrap">
        <h5 className="font-size-16 me-3 text-muted text-uppercase header-title">
          {title}
        </h5>
        {hasAction && actionTitle ? (
          <div className="ms-auto">
            <a
              className={classes}
              href={actionRoute ? actionRoute : "#!"}
              onClick={actionOnClick ? actionOnClick : null}
            >
              {actionTitle}
            </a>
          </div>
        ) : null}
      </div>
      <hr className="mt-2"></hr>
    </>
  );
};
