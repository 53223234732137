import classNames from "classnames";
import { mapToCssModules } from "src/SmartR/Utils/utils";
import { Icon } from "@ailibs/feather-react-ts";
import { Link } from "react-router-dom";

interface BotaoNovoRegistroPropTypes {
  path?: string;
  className?: string;
  onClickHandle?: any;
}
export function BotaoNovoRegistro(props: BotaoNovoRegistroPropTypes) {
  const { className, onClickHandle, path = "" } = props;

  const classes = mapToCssModules(
    classNames(className, "btn btn-success btn-sm btn-new-record"),
  );
  return (
    <>
      <Link to={path} onClick={onClickHandle} className={classes}>
        <Icon name="plus" className="icon-md" />
      </Link>
    </>
  );
}
