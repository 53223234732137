import { changeBodyAttribute, manageBodyClass } from "./utils";

//constants
import {
  layoutTypes,
  layoutWidthTypes,
  layoutTheme,
  leftSidebarTypes,
} from "../../constants/layout";

// action
import {
  changeLayoutAction,
  changeTopbarThemeAction,
  changeSidebarThemeAction,
  changeLayoutWidthAction,
  changeSidebarTypeAction,
  changeLayoutModeAction,
  changeLayoutPositionAction,
  openConfigurationAction,
} from "./reducer";
import gomelius from "src/api/gomelius";
import { AdminUtil } from "src/util/AdminUtil";

const saveConfiguration = (key: string, value: string, save: boolean) => {
  try {
    let config = AdminUtil.obterConfiguracao();
    config[key] = value;
    AdminUtil.salvarConfiguracao(config);
    if (save) {
      gomelius
        .post(null, `Usuario/SalvarConfiguracao/${key}/${value}`)
        .then()
        .catch();
    }
  } catch (error) {
    console.log(error);
  }
};
export const openConfiguration: any =
  (value: boolean) => async (dispatch: any) => {
    dispatch(openConfigurationAction(value));
  };

export const changeLayout: any =
  (layoutType: any, save: boolean = false) =>
  async (dispatch: any) => {
    saveConfiguration("Layout", layoutType, save);

    if (layoutType === layoutTypes.HORIZONTAL) {
      dispatch(changeLayoutAction(layoutTypes.HORIZONTAL));
      document.body.removeAttribute("data-sidebar");
      changeBodyAttribute("data-sidebar-size", leftSidebarTypes.DEFAULT);
    } else {
      dispatch(changeLayoutAction(layoutTypes.VERTICAL));
    }

    changeBodyAttribute("data-layout", layoutType);
  };

export const changelayoutMode: any =
  (layoutMode: any, save: boolean = false) =>
  async (dispatch: any) => {
    saveConfiguration("Tema", layoutMode, save);
    saveConfiguration("BarraSuperiorTema", layoutMode, save);

    try {
      dispatch(changeLayoutModeAction(layoutMode));
      if (layoutMode === layoutTheme.LIGHTMODE) {
        changeBodyAttribute("data-bs-theme", layoutMode);
        changeBodyAttribute("data-topbar", layoutMode);
        dispatch(changeTopbarThemeAction(layoutTheme.LIGHTMODE));
        changeBodyAttribute("data-sidebar", layoutTheme.LIGHTMODE);
      } else if (layoutMode === "dark") {
        changeBodyAttribute("data-bs-theme", layoutMode);
        changeBodyAttribute("data-topbar", layoutMode);
        dispatch(changeTopbarThemeAction(layoutTheme.DARKMODE));
        changeBodyAttribute("data-sidebar", layoutTheme.DARKMODE);
      }
    } catch (error) {}
  };

export const changeTopbarTheme: any =
  (topbarTheme: any, save: boolean = false) =>
  async (dispatch: any) => {
    saveConfiguration("BarraSuperiorTema", topbarTheme, save);

    changeBodyAttribute("data-topbar", topbarTheme);
    dispatch(changeTopbarThemeAction(topbarTheme));
  };

export const changeSidebarTheme: any =
  (sidebarTheme: any, save: boolean = false) =>
  async (dispatch: any) => {
    saveConfiguration("BarraLateralTema", sidebarTheme, save);

    changeBodyAttribute("data-sidebar", sidebarTheme);
    dispatch(changeSidebarThemeAction(sidebarTheme));
  };

export const changeLayoutWidth: any =
  (width: any, save: boolean = false) =>
  async (dispatch: any) => {
    saveConfiguration("LarguraBarraLateral", width, save);

    dispatch(changeLayoutWidthAction(width));
    if (width === layoutWidthTypes.BOXED) {
      saveConfiguration("TipoBarraLateral", leftSidebarTypes.COMPACT, save);
      dispatch(changeSidebarTypeAction(leftSidebarTypes.COMPACT));
      changeBodyAttribute("data-layout-size", width);
    } else {
      saveConfiguration("TipoBarraLateral", leftSidebarTypes.DEFAULT, save);
      dispatch(changeSidebarTypeAction(leftSidebarTypes.DEFAULT));
      changeBodyAttribute("data-layout-size", width);
    }
  };

export const changeLayoutPosition: any =
  (layoutPosition: any, save: boolean = false) =>
  async (dispatch: any) => {
    saveConfiguration("Scrollable", layoutPosition, save);

    dispatch(changeLayoutPositionAction(layoutPosition));

    try {
      changeBodyAttribute("data-layout-scrollable", layoutPosition);
    } catch (error) {}
  };

export const changeLeftSidebarTheme =
  (theme: layoutTheme, save: boolean = false) =>
  async (dispatch: any) => {
    saveConfiguration("TemaBarraLateral", theme, save);

    try {
      dispatch(changeBodyAttribute, "data-sidebar", theme);
    } catch (error) {}
  };

export const changeSidebarType: any =
  (sidebarType: any, save: boolean = false) =>
  async (dispatch: any) => {
    saveConfiguration("TipoBarraLateral", sidebarType, save);

    switch (sidebarType) {
      case leftSidebarTypes.COMPACT:
        manageBodyClass("sidebar-enable", "remove");
        changeBodyAttribute("data-sidebar-size", "md");
        break;
      case leftSidebarTypes.ICON:
        manageBodyClass("sidebar-enable", "remove");
        changeBodyAttribute("data-sidebar-size", "sm");
        break;
      case leftSidebarTypes.DEFAULT:
        if (window.screen.width < 992) {
          manageBodyClass("sidebar-enable", "remove");
          changeBodyAttribute("data-sidebar-size", "lg");
        } else {
          changeBodyAttribute("data-sidebar-size", "lg");
          manageBodyClass("sidebar-enable", "add");
        }
        break;
      default:
        changeBodyAttribute("data-sidebar-size", "lg");
        manageBodyClass("sidebar-enable", "remove");
        break;
    }

    dispatch(changeSidebarTypeAction(sidebarType));
  };
