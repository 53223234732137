import React, { useState, useEffect } from "react";
import { Loading } from "src/components";

interface ComparativeKPIProps {
  title: string;
  subtitle: string;
  previousSubtitle: string;
  currentValue: number;
  previousValue: number;
  prefix?: string;
  suffix?: string;
  allowDecimal?: boolean;
  useKFormat?: boolean;
  loadingText?: string;
  showLoading?: boolean;
  higherText?: string;
  lowerText?: string;
}

export function ComparativeKPI({
  title,
  currentValue,
  previousValue,
  subtitle,
  previousSubtitle,
  prefix,
  suffix,
  allowDecimal = true,
  useKFormat = false,
  loadingText = "Carregando...",
  higherText = "maior que o período anterior",
  lowerText = "menor que o último período",
  showLoading = false,
}: ComparativeKPIProps) {
  const changePercentage =
    previousValue !== 0
      ? ((currentValue - previousValue) / previousValue) * 100
      : 0;
  const textClass =
    changePercentage > 0
      ? "text-success"
      : changePercentage < 0
      ? "text-danger"
      : "";

  const formatNumber = (number: number) => {
    if (!allowDecimal) {
      return Math.round(number).toLocaleString("pt-BR");
    }
    if (useKFormat && number >= 1000) {
      return (
        (number / 1000).toLocaleString("pt-BR", {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        }) + "K"
      );
    }
    return number.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <>
      {showLoading ? (
        <Loading height="22.4vh" fontSize="1.5vw" text={loadingText} />
      ) : (
        <div className="justify-content-between">
          <div className="row">
            <h6 className="font-size-xs text-uppercase"> {title} </h6>
          </div>
          <div className="row mb-3">
            <div className="col-6 text-left">
              <h4 className={`mt-4 font-weight-bold mb-2 ${textClass}`}>
                {prefix}
                {formatNumber(currentValue)}
                {suffix}
              </h4>
              <div className="text-muted"> {subtitle} </div>
            </div>
            <div className="col-6 text-end">
              <h4 className="mt-4 font-weight-bold mb-2 text-muted">
                {prefix}
                {formatNumber(previousValue)}
                {suffix}
              </h4>
              <div className="text-muted"> {previousSubtitle} </div>
            </div>
          </div>
          {changePercentage !== 0 ? (
            <p className="text-muted">
              <span className={textClass}>
                {Math.abs(changePercentage).toFixed(1)}%
                {changePercentage > 0 ? (
                  <i className="mdi mdi-arrow-up"></i>
                ) : (
                  <i className="mdi mdi-arrow-down"></i>
                )}
              </span>
              {changePercentage > 0 ? higherText : lowerText}
            </p>
          ) : null}
        </div>
      )}
    </>
  );
}
