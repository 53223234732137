import React from "react";
import configSmartR from "src/configSmartR";

interface checkboxGroupPropTypes {
  id?: string;
  checkBoxText?: string;

  children?: React.ReactNode;
}

export function CheckboxGroup(props: checkboxGroupPropTypes) {
  const { id, children, checkBoxText } = props;
  const checkBoxLabelAttributes: Record<string, string> = {};
  if (id) {
    checkBoxLabelAttributes["htmlFor"] = id;
  }

  const checkBoxLabel = React.createElement(
    "label",
    {
      ...checkBoxLabelAttributes,
      className: configSmartR.classes.checkboxLabel,
    },
    String(checkBoxText),
  );

  return React.createElement(
    "div",
    {
      key: 0,
      className: configSmartR.classes.checkboxGroup,
    },
    children,
    checkBoxLabel,
  );
}
