import { lazy } from "react";
import { Loadable } from "../components/organisms/Loadable";

const Dashboard = Loadable(lazy(() => import("../pages/Dashboard")));

const Atividade = Loadable(lazy(() => import("../pages/Atividade")));
const RegistroAtividade = Loadable(
  lazy(() => import("../pages/Atividade/registro"))
);

const Grupo = Loadable(lazy(() => import("../pages/Grupo")));
const RegistroGrupo = Loadable(lazy(() => import("../pages/Grupo/registro")));

const Cliente = Loadable(lazy(() => import("../pages/Cliente")));
const RegistroCliente = Loadable(
  lazy(() => import("../pages/Cliente/registro"))
);

const Departamento = Loadable(lazy(() => import("../pages/Departamento")));
const RegistroDepartamento = Loadable(
  lazy(() => import("../pages/Departamento/registro"))
);

const Perfil = Loadable(lazy(() => import("../pages/Autenticacao/Perfil")));

const Motivo = Loadable(lazy(() => import("../pages/Motivo")));
const RegistroMotivo = Loadable(lazy(() => import("../pages/Motivo/registro")));

const Servico = Loadable(lazy(() => import("../pages/Servico")));
const RegistroServico = Loadable(
  lazy(() => import("../pages/Servico/registro"))
);

const Convite = Loadable(lazy(() => import("../pages/Convite")));
const RegistroConvite = Loadable(
  lazy(() => import("../pages/Convite/registro"))
);

const Fornecedor = Loadable(lazy(() => import("../pages/Fornecedor")));
const RegistroFornecedor = Loadable(
  lazy(() => import("../pages/Fornecedor/registro"))
);

const Categoria = Loadable(lazy(() => import("../pages/Categoria")));
const RegistroCategoria = Loadable(
  lazy(() => import("../pages/Categoria/registro"))
);

const Usuario = Loadable(lazy(() => import("../pages/Usuario")));

const Conta = Loadable(lazy(() => import("../pages/Conta")));
const RegistroConta = Loadable(lazy(() => import("../pages/Conta/registro")));

const UsuarioPapel = Loadable(lazy(() => import("../pages/Usuario/papel")));

const PlanoPagamento = Loadable(lazy(() => import("../pages/PlanoPagamento")));
const RegistroPlanoPagamento = Loadable(
  lazy(() => import("../pages/PlanoPagamento/registro"))
);

const ReceitaTitulo = Loadable(lazy(() => import("../pages/Receita")));
const RegistroReceitaTitulo = Loadable(
  lazy(() => import("../pages/Receita/registro"))
);

const ListaReceitaTituloRegistar = Loadable(
  lazy(() => import("../pages/Receita/listaRegistarPagamento"))
);
const ReceitaTituloRegistar = Loadable(
  lazy(() => import("../pages/Receita/registarPagamento"))
);

const ListaReceitaTituloCancelar = Loadable(
  lazy(() => import("../pages/Receita/listaCancelar"))
);
const ReceitaTituloCancelar = Loadable(
  lazy(() => import("../pages/Receita/cancelar"))
);

const ListaReceitaTituloEstornar = Loadable(
  lazy(() => import("../pages/Receita/listaEstornar"))
);
const ReceitaTituloEstornar = Loadable(
  lazy(() => import("../pages/Receita/estornar"))
);

const ListaReceitaTituloConfirmar = Loadable(
  lazy(() => import("../pages/Receita/listaConfirmar"))
);
const ReceitaTituloConfirmar = Loadable(
  lazy(() => import("../pages/Receita/confirmar"))
);

const DespesaTitulo = Loadable(lazy(() => import("../pages/Despesa")));
const RegistroDespesaTitulo = Loadable(
  lazy(() => import("../pages/Despesa/registro"))
);

const ListaDespesaTituloRegistar = Loadable(
  lazy(() => import("../pages/Despesa/listaRegistarPagamento"))
);
const DespesaTituloRegistar = Loadable(
  lazy(() => import("../pages/Despesa/registarPagamento"))
);

const ListaDespesaTituloCancelar = Loadable(
  lazy(() => import("../pages/Despesa/listaCancelar"))
);
const DespesaTituloCancelar = Loadable(
  lazy(() => import("../pages/Despesa/cancelar"))
);

const ListaDespesaTituloEstornar = Loadable(
  lazy(() => import("../pages/Despesa/listaEstornar"))
);
const DespesaTituloEstornar = Loadable(
  lazy(() => import("../pages/Despesa/estornar"))
);

const Ordem = Loadable(lazy(() => import("../pages/Ordem")));
const RegistroOrdem = Loadable(lazy(() => import("../pages/Ordem/registro")));

const Supervisor = Loadable(lazy(() => import("../pages/Supervisor")));
const RegistroSupervisor = Loadable(
  lazy(() => import("../pages/Supervisor/registro"))
);

const Agente = Loadable(lazy(() => import("../pages/Agente")));
const RegistroAgente = Loadable(lazy(() => import("../pages/Agente/registro")));

const Vendedor = Loadable(lazy(() => import("../pages/Vendedor")));
const RegistroVendedor = Loadable(
  lazy(() => import("../pages/Vendedor/registro"))
);

const VeiculoTrator = Loadable(lazy(() => import("../pages/Trator")));
const RegistroTrator = Loadable(lazy(() => import("../pages/Trator/registro")));

const Carreta = Loadable(lazy(() => import("../pages/Carreta")));
const RegistroCarreta = Loadable(
  lazy(() => import("../pages/Carreta/registro"))
);

const Engenheiro = Loadable(lazy(() => import("../pages/Engenheiro")));
const RegistroEngenheiro = Loadable(
  lazy(() => import("../pages/Engenheiro/registro"))
);

const Transportador = Loadable(lazy(() => import("../pages/Transportador")));
const RegistroTransportador = Loadable(
  lazy(() => import("../pages/Transportador/registro"))
);

const Marca = Loadable(lazy(() => import("../pages/Marca")));
const RegistroMarca = Loadable(lazy(() => import("../pages/Marca/registro")));

const ModeloTrator = Loadable(lazy(() => import("../pages/ModeloTrator")));
const RegistroModeloTrator = Loadable(
  lazy(() => import("../pages/ModeloTrator/registro"))
);

const Filial = Loadable(lazy(() => import("../pages/Filial")));
const RegistroFilial = Loadable(lazy(() => import("../pages/Filial/registro")));

const TabelaPreco = Loadable(lazy(() => import("../pages/TabelaPreco")));
const RegistroTabelaPreco = Loadable(
  lazy(() => import("../pages/TabelaPreco/registro"))
);
const Orcamento = Loadable(lazy(() => import("../pages/Orcamento")));
const RegistroOrcamento = Loadable(
  lazy(() => import("../pages/Orcamento/registro"))
);
const CancelarOrcamento = Loadable(
  lazy(() => import("../pages/Orcamento/cancelar"))
);

const CRMCliente = Loadable(lazy(() => import("../pages/CRM/cliente")));

const AET = Loadable(lazy(() => import("../pages/AET")));
const RegistroAET = Loadable(lazy(() => import("../pages/AET/registro")));
const Relatorio = Loadable(lazy(() => import("../pages/Relatorio")));

const Desenho = Loadable(lazy(() => import("../pages/Teste/Desenho")));
const Teste3 = Loadable(lazy(() => import("../pages/Teste/Teste3")));

const Teste = Loadable(lazy(() => import("../pages/Teste/Teste")));
const Teste2 = Loadable(lazy(() => import("../pages/Teste/Teste2")));
const TesteGabarito = Loadable(lazy(() => import("../pages/Teste/Gabarito")));

const Trecho = Loadable(lazy(() => import("../pages/Trecho")));
const RegistroTrecho = Loadable(lazy(() => import("../pages/Trecho/registro")));

const Empresa = Loadable(lazy(() => import("../pages/Empresa/registro")));

const Papel = Loadable(lazy(() => import("../pages/Papel")));
const RegistroPapel = Loadable(lazy(() => import("../pages/Papel/registro")));

const autenticadoRoutes = [
  // { path: "/Home", element: <Home /> },//adicionar elemento principal
  { path: "/Dashboard", element: <Dashboard /> },
  { path: "/CRM/:id", element: <CRMCliente /> },

  { path: "/Teste", element: <Teste /> },
  { path: "/TesteGabarito", element: <TesteGabarito /> },
  { path: "/TesteInput", element: <Teste2 /> },
  { path: "/Desenho", element: <Desenho /> },
  { path: "/Teste3", element: <Teste3 /> },

  { path: "/Perfil", element: <Perfil /> },

  { path: "/Atividade", element: <Atividade /> },
  { path: "/Atividade/Novo", element: <RegistroAtividade /> },
  { path: "/Atividade/:id", element: <RegistroAtividade /> },

  { path: "/Grupo", element: <Grupo /> },
  { path: "/Grupo/Novo", element: <RegistroGrupo /> },
  { path: "/Grupo/:id", element: <RegistroGrupo /> },

  { path: "/Cliente", element: <Cliente /> },
  { path: "/Cliente/Novo", element: <RegistroCliente /> },
  { path: "/Cliente/:id", element: <RegistroCliente /> },

  { path: "/Departamento", element: <Departamento /> },
  { path: "/Departamento/Novo", element: <RegistroDepartamento /> },
  { path: "/Departamento/:id", element: <RegistroDepartamento /> },
  { path: "/Motivo", element: <Motivo /> },
  { path: "/Motivo/Novo", element: <RegistroMotivo /> },
  { path: "/Motivo/:id", element: <RegistroMotivo /> },
  { path: "/Servico", element: <Servico /> },
  { path: "/Servico/Novo", element: <RegistroServico /> },
  { path: "/Servico/:id", element: <RegistroServico /> },
  { path: "/Convite", element: <Convite /> },
  { path: "/Convite/Novo", element: <RegistroConvite /> },
  { path: "/Convite/:id", element: <RegistroConvite /> },
  { path: "/Fornecedor", element: <Fornecedor /> },
  { path: "/Fornecedor/Novo", element: <RegistroFornecedor /> },
  { path: "/Fornecedor/:id", element: <RegistroFornecedor /> },
  { path: "/Categoria", element: <Categoria /> },
  { path: "/Categoria/Novo", element: <RegistroCategoria /> },
  { path: "/Categoria/:id", element: <RegistroCategoria /> },

  { path: "/Usuario", element: <Usuario /> },
  { path: "/Usuario/Papel/:id", element: <UsuarioPapel /> },
  { path: "/Conta", element: <Conta /> },
  { path: "/Conta/Novo", element: <RegistroConta /> },
  { path: "/Conta/:id", element: <RegistroConta /> },
  { path: "/PlanoPagamento", element: <PlanoPagamento /> },
  { path: "/PlanoPagamento/Novo", element: <RegistroPlanoPagamento /> },
  { path: "/PlanoPagamento/:id", element: <RegistroPlanoPagamento /> },

  { path: "/Receita", element: <ReceitaTitulo /> },
  { path: "/Receita/Novo", element: <RegistroReceitaTitulo /> },
  { path: "/Receita/:id", element: <RegistroReceitaTitulo /> },
  { path: "/Receita/Registrar", element: <ListaReceitaTituloRegistar /> },
  { path: "/Receita/Registrar/:id", element: <ReceitaTituloRegistar /> },
  { path: "/Receita/Cancelar", element: <ListaReceitaTituloCancelar /> },
  { path: "/Receita/Cancelar/:id", element: <ReceitaTituloCancelar /> },
  { path: "/Receita/Estornar", element: <ListaReceitaTituloEstornar /> },
  { path: "/Receita/Estornar/:id", element: <ReceitaTituloEstornar /> },
  { path: "/Receita/Confirmar", element: <ListaReceitaTituloConfirmar /> },
  { path: "/Receita/Confirmar/:id", element: <ReceitaTituloConfirmar /> },

  { path: "/Ordem", element: <Ordem /> },
  { path: "/Ordem/Novo", element: <RegistroOrdem /> },
  { path: "/Ordem/:id", element: <RegistroOrdem /> },

  { path: "/Despesa", element: <DespesaTitulo /> },
  { path: "/Despesa/Novo", element: <RegistroDespesaTitulo /> },
  { path: "/Despesa/:id", element: <RegistroDespesaTitulo /> },
  { path: "/Despesa/Registrar", element: <ListaDespesaTituloRegistar /> },
  { path: "/Despesa/Registrar/:id", element: <DespesaTituloRegistar /> },
  { path: "/Despesa/Cancelar", element: <ListaDespesaTituloCancelar /> },
  { path: "/Despesa/Cancelar/:id", element: <DespesaTituloCancelar /> },
  { path: "/Despesa/Estornar", element: <ListaDespesaTituloEstornar /> },
  { path: "/Despesa/Estornar/:id", element: <DespesaTituloEstornar /> },

  { path: "/Supervisor", element: <Supervisor /> },
  { path: "/Supervisor/Novo", element: <RegistroSupervisor /> },
  { path: "/Supervisor/:id", element: <RegistroSupervisor /> },
  { path: "/Agente", element: <Agente /> },
  { path: "/Agente/Novo", element: <RegistroAgente /> },
  { path: "/Agente/:id", element: <RegistroAgente /> },
  { path: "/Vendedor", element: <Vendedor /> },
  { path: "/Vendedor/Novo", element: <RegistroVendedor /> },
  { path: "/Vendedor/:id", element: <RegistroVendedor /> },
  { path: "/Trator", element: <VeiculoTrator /> },
  { path: "/Trator/Novo", element: <RegistroTrator /> },
  { path: "/Trator/:id", element: <RegistroTrator /> },
  { path: "/Carreta", element: <Carreta /> },
  { path: "/Carreta/Novo", element: <RegistroCarreta /> },
  { path: "/Carreta/:id", element: <RegistroCarreta /> },
  { path: "/Engenheiro", element: <Engenheiro /> },
  { path: "/Engenheiro/Novo", element: <RegistroEngenheiro /> },
  { path: "/Engenheiro/:id", element: <RegistroEngenheiro /> },
  { path: "/Transportador", element: <Transportador /> },
  { path: "/Transportador/Novo", element: <RegistroTransportador /> },
  { path: "/Transportador/:id", element: <RegistroTransportador /> },
  { path: "/Marca", element: <Marca /> },
  { path: "/Marca/Novo", element: <RegistroMarca /> },
  { path: "/Marca/:id", element: <RegistroMarca /> },
  { path: "/ModeloTrator", element: <ModeloTrator /> },
  { path: "/ModeloTrator/Novo", element: <RegistroModeloTrator /> },
  { path: "/ModeloTrator/:id", element: <RegistroModeloTrator /> },
  { path: "/Filial", element: <Filial /> },
  { path: "/Filial/Novo", element: <RegistroFilial /> },
  { path: "/Filial/:id", element: <RegistroFilial /> },
  { path: "/TabelaPreco", element: <TabelaPreco /> },
  { path: "/TabelaPreco/Novo", element: <RegistroTabelaPreco /> },
  { path: "/TabelaPreco/:id", element: <RegistroTabelaPreco /> },

  { path: "/Orcamento", element: <Orcamento /> },
  { path: "/Orcamento/Novo", element: <RegistroOrcamento /> },
  { path: "/Orcamento/:id", element: <RegistroOrcamento /> },
  { path: "/Orcamento/Cancelar/:id", element: <CancelarOrcamento /> },

  { path: "/AET", element: <AET /> },
  { path: "/AET/Novo", element: <RegistroAET /> },
  { path: "/AET/:id", element: <RegistroAET /> },

  { path: "/Relatorio/:id", element: <Relatorio /> },
  { path: "/Trecho", element: <Trecho /> },
  { path: "/Trecho/Novo", element: <RegistroTrecho /> },
  { path: "/Trecho/:id", element: <RegistroTrecho /> },

  { path: "/Empresa", element: <Empresa /> },
  { path: "/Papel", element: <Papel /> },
  { path: "/Papel/Novo", element: <RegistroPapel /> },
  { path: "/Papel/:id", element: <RegistroPapel /> },
];

export default autenticadoRoutes;
