import React from "react";

import classNames from "classnames";
import { mapToCssModules } from "src/SmartR/Utils/utils";
import { Tooltip, TooltipPosition } from "src/components";
import { useDropdownContext } from "../Dropdown";

interface DropdownItemPropTypes {
  children?: React.ReactNode;
  className?: string;
  icon?: string;
  divider?: boolean;
  onClick?: any;
  tooltip?: string;
  visible?: boolean;
  enabled?: boolean;
}

export function DropdownItem(props: DropdownItemPropTypes) {
  const {
    className,
    icon,
    onClick,
    divider = false,
    tooltip,
    children,
    visible = true,
    enabled = true,
    ...attributes
  } = props;
  const { setOpen } = useDropdownContext();
  if (!visible) return null;

  const classes = mapToCssModules(classNames(className, "dropdown-item"));

  if (divider) {
    const dividerClasses = mapToCssModules(
      classNames(className, "dropdown-divider")
    );
    return <div tabIndex={-1} className={dividerClasses} {...attributes}></div>;
  }

  const handleClick = () => {
    if (!enabled) return;
    setOpen(false);
    if (onClick) onClick();
  };

  return (
    <>
      {tooltip ? (
        <Tooltip position={TooltipPosition.Top} text={tooltip}>
          <button
            tabIndex={0}
            role="menuitem"
            className={classes}
            onClick={handleClick}
            disabled={!enabled} // Desabilita o botão se o item não estiver habilitado
            {...attributes}
          >
            {icon && (
              <i className={`mdi ${icon} font-size-16 align-middle me-1`}></i>
            )}

            <span className="align-middle">{children}</span>
          </button>
        </Tooltip>
      ) : (
        <button
          tabIndex={0}
          role="menuitem"
          className={classes}
          onClick={handleClick}
          disabled={!enabled} // Desabilita o botão se o item não estiver habilitado
          {...attributes}
        >
          {icon && (
            <i className={`mdi ${icon} font-size-16 align-middle me-1`}></i>
          )}

          <span className="align-middle">{children}</span>
        </button>
      )}
    </>
  );
}
