import "./ToastNotification.css";
import { toast } from "react-toastify";

export enum Position {
  TOP_LEFT = 1,
  TOP_RIGHT = 2,
  TOP_CENTER = 3,
  BOTTOM_LEFT = 4,
  BOTTOM_RIGHT = 5,
  BOTTOM_CENTER = 6,
}

function mapPositionToToastPosition(position: Position): any {
  switch (position) {
    case Position.TOP_LEFT:
      return toast.POSITION.TOP_LEFT;
    case Position.TOP_RIGHT:
      return toast.POSITION.TOP_RIGHT;
    case Position.TOP_CENTER:
      return toast.POSITION.TOP_CENTER;
    case Position.BOTTOM_LEFT:
      return toast.POSITION.BOTTOM_LEFT;
    case Position.BOTTOM_RIGHT:
      return toast.POSITION.BOTTOM_RIGHT;
    default:
      return toast.POSITION.TOP_RIGHT;
  }
}

export const ToastNotification = {
  error: (
    content,
    title: string = null,
    position: Position = Position.TOP_RIGHT,
    onClickHandler: any = null,
    showIcon: boolean = true
  ) => {
    const toastPosition = mapPositionToToastPosition(position);

    toast.error(
      <div>
        {title ? <div className="notification-title">{title}</div> : null}
        <div>{content}</div>
      </div>,
      {
        position: toastPosition,
        onClick: onClickHandler,
        icon: showIcon,
      }
    );
  },

  success: (
    content,
    title: string = null,
    position: Position = Position.TOP_RIGHT,
    onClickHandler: any = null,
    showIcon: boolean = true,
  ) => {
    const toastPosition = mapPositionToToastPosition(position);

    toast.success(
      <div>
        {title ? <div className="notification-title">{title}</div> : null}
        <div>{content}</div>
      </div>,
      {
        position: toastPosition,
        onClick: onClickHandler,
        icon: showIcon,
      }
    );
  },

  warning: (
    content,
    title: string = null,
    position: Position = Position.TOP_RIGHT,    
    onClickHandler: any = null,
    showIcon: boolean = true,
  ) => {
    const toastPosition = mapPositionToToastPosition(position);

    toast.warning(
      <div>
        {title ? <div className="notification-title">{title}</div> : null}
        <div>{content}</div>
      </div>,
      {
        position: toastPosition,
        onClick: onClickHandler,
        icon: showIcon,
      }
    );
  },

  info: (
    content,
    title: string = null,
    position: Position = Position.TOP_RIGHT,
    onClickHandler: any = null,
    showIcon: boolean = true
  ) => {
    const toastPosition = mapPositionToToastPosition(position);

    toast.info(
      <div>
        {title ? <div className="notification-title">{title}</div> : null}
        <div>{content}</div>
      </div>,
      {
        position: toastPosition,
        onClick: onClickHandler,
        icon: showIcon,
      }
    );
  },
};
