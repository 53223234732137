import {
  removerRegistroTabela,
  removerRegistroServidor,
  atualizarStatusRegistro,
} from "./reducer";

export const removerTabela: any = (data: any) => async (dispatch: any) => {
  dispatch(removerRegistroTabela(data));
};

export const removerServidor: any = (data: any) => async (dispatch: any) => {
  dispatch(removerRegistroServidor(data));
};
export const atualizarRegistro: any =
  (data: any, status: any) => async (dispatch: any) => {
    dispatch(atualizarStatusRegistro(data));
  };
