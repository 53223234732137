import { useEffect, createContext, useReducer, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import gomelius from "src/api/gomelius";
import { Loading } from "src/components";
import { inicializar } from "src/slices/Auth/reducer";

const initialState = {
  autenticado: false,
  usuario: null,
};

const AuthContext = createContext({
  ...initialState,
});

export const AuthProvider = ({ children }) => {
  const [inicializado, setInicializado] = useState(false);
  const dispatch = useDispatch();
  const dadosAuthSelector = createSelector(
    (state: any) => state.autenticacao,
    (autenticacao) => ({
      autenticado: autenticacao.autenticado,
      usuario: autenticacao.usuario,
    })
  );
  const { autenticado, usuario } = useSelector(dadosAuthSelector);
  useEffect(() => {
    (async () => {
      await gomelius
        .obterUsuarioAutenticado()
        .then((dados: any) => {
          dispatch(
            inicializar({
              autenticado: true,
              renovarSessao: false,
              usuario: dados.usuario,
              versao: dados.versao,
            })
          );
          setInicializado(true);
        })
        .catch((dados: any) => {
          setInicializado(true);
          dispatch(
            inicializar({
              autenticado: false,
              renovarSessao: dados.renovarSessao,
              // usuario: null,
              // versao: 0,
            })
          );
        });
    })();
  }, []);
  if (!inicializado) {
    return <Loading />;
  }
  return (
    <AuthContext.Provider value={{ usuario, autenticado }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
