import Swal, { SweetAlertResult } from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const SwalAlert = {
  confirmar: (titulo: string, text: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      MySwal.fire({
        title: titulo,
        html: text,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
        allowOutsideClick: false,
        allowEscapeKey: false,
        focusConfirm: false,
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      }).then((result: SweetAlertResult) => {
        if (result.isConfirmed) {
          resolve();
        } else {
          reject();
        }
      });
    });
  },

  sucesso: (titulo: string, text: string): Promise<void> => {
    return new Promise((resolve) => {
      MySwal.fire({
        title: titulo,
        html: text,
        icon: "success",
        showConfirmButton: true,
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
        focusConfirm: false,
        customClass: {
          confirmButton: "btn btn-success",
        },
        buttonsStyling: false,
      }).then((result: SweetAlertResult) => {
        if (result.isConfirmed) {
          resolve();
        }
      });
    });
  },

  erro: (titulo: string, text: string): Promise<void> => {
    return new Promise((resolve) => {
      MySwal.fire({
        title: titulo,
        html: text,
        icon: "error",
        showConfirmButton: true,
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
        focusConfirm: false,
        customClass: {
          confirmButton: "btn btn-success",
        },
        buttonsStyling: false,
      }).then((result: SweetAlertResult) => {
        if (result.isConfirmed) {
          resolve();
        }
      });
    });
  },
};
