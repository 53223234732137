import { useEffect, useState } from "react";
import axios from "axios";
import gomelius from "src/api/gomelius";
import Renovar from "src/pages/Autenticacao/Renovar";
import { createPortal } from "react-dom";
import { useLayoutContext } from "src/contexts/layoutContext";

const Layout = ({ children }) => {
  const { setLayoutReady } = useLayoutContext();
  const [isPortalVisible, setPortalVisible] = useState(false); // Controla a visibilidade do portal
  const [onConfirmationCallback, setOnConfirmationCallback] = useState(null); // Armazena a função de confirmação
  const [portalContainer, setPortalContainer] = useState(null); // Armazena o contêiner DOM do portal

  useEffect(() => {
    // Função para criar o portal no DOM
    function createCredentialsPortal() {
      const container = document.createElement("div");
      container.id = "credentials-portal";
      document.body.appendChild(container);
      setPortalContainer(container); // Define o contêiner no estado
    }

    if (!document.getElementById("credentials-portal")) {
      createCredentialsPortal();
    }

    const axiosInterceptor = axios.interceptors.response.use(
      (response) => {
        return Object.hasOwn(response, "data") ? response.data : response;
      },
      async (error) => {
        if (error.response && error.response.status === 401) {
          if (!document.body.classList.contains("auth")) {
            return new Promise((resolve) => {
              renderCredentialsConfirmation(async () => {
                document.body.removeChild(
                  document.getElementById("credentials-portal")
                );
                setPortalVisible(false); // Remove o portal após confirmação
                const token = gomelius.getToken();
                let config = error.config;
                config.headers.Authorization = `Bearer ${token}`;
                const resposta = await axios.request(config);
                resolve(resposta);
              });
            });
          } else {
            return Promise.reject(error.response.data || error.response);
          }
        } else {
          return Promise.reject(error.response.data || error.response);
        }
      }
    );

    setLayoutReady(true);

    return () => {
      setLayoutReady(false);
      axios.interceptors.response.eject(axiosInterceptor);
      document.body.classList.remove("renovar-sessao");
      const portalElement = document.getElementById("credentials-portal");
      if (portalElement) {
        document.body.removeChild(portalElement); // Limpa o portal ao desmontar o componente
      }
    };
  }, [setLayoutReady]);

  // Função para renderizar o componente Renovar dentro do portal
  function renderCredentialsConfirmation(onConfirmation) {
    setOnConfirmationCallback(() => onConfirmation); // Define a função de callback
    setPortalVisible(true); // Exibe o portal
  }

  return (
    <>
      {children}

      {/* Renderiza o portal condicionalmente se o portalContainer existir */}
      {isPortalVisible &&
        portalContainer &&
        createPortal(
          <Renovar onConfirmar={onConfirmationCallback} from={null} />,
          portalContainer
        )}
    </>
  );
};

export default Layout;
