import { portalEnum } from "src/enums";
import "./index.scss";
import { Tooltip, TooltipPosition } from "src/components";
export enum TipoPortal {
  DNIT = 1,
  SINFRA = 2,
  DEINFRA = 3,
  GOINFRA = 4,
  DERBA = 5,
  DERSP = 6,
  DEERMG = 7,
  DERES = 8,
  DERRJ = 9,
  DERSE = 10,
  DERPR = 11,
  AGESUL = 12,
  AGETO = 13,
  DAERRS = 14,
  DERAL = 15,
  DERPE = 16,
  SETRAN = 17,
}
interface LogoOrgaoProps {
  type: TipoPortal;
  onClickHandler?: () => void;
}

export function LogoOrgao(props: LogoOrgaoProps) {
  const { type, onClickHandler } = props;

  const imagePath = require(`../../../assets/images/orgao/${TipoPortal[
    type
  ].toLowerCase()}/logo.png`);
  
  const portal = portalEnum.find((filter) => filter.id === Number(type));
  
  let classes = "logo-orgao";

  return (
    <Tooltip position={TooltipPosition.Right} text={portal.titulo}>
      <img
        src={imagePath}
        alt={TipoPortal[type]}
        onClick={onClickHandler}
        className={classes}
      />
    </Tooltip>
  );
}
