import classNames from "classnames";
import { mapToCssModules } from "src/SmartR/Utils/utils";
import { DropdownToggle } from "../../atoms/DropdownToggle";
import { Icon } from "@ailibs/feather-react-ts";
import { DropdownMenu } from "../../atoms/DropdownMenu";
import { Dropdown } from "src/components";

interface BotaoFiltroListaPropTypes {
  children?: React.ReactNode;
  className?: string;
}
export function BotaoFiltroLista(props: BotaoFiltroListaPropTypes) {
  const { className, children } = props;

  const classes = mapToCssModules(
    classNames(className, "btn btn-primary btn-sm btn-filter")
  );
  return (
    <>
      <Dropdown>
        <DropdownToggle className={classes}>
          <Icon name="filter" className="icon-md" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end" dir="left">
          {children}
        </DropdownMenu>
      </Dropdown>
    </>
  );
}
