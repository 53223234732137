import classNames from "classnames";
import React, { ReactNode, useState } from "react";
import ReactDOM from "react-dom";
import { mapToCssModules } from "src/SmartR/Utils/utils";
export enum SizeModal {
  Small = 1,
  Normal = 2,
  Large = 3,
  ExtraLarge = 4,
  FullScreen = 5,
}
interface ModalProps {
  isOpen: boolean;
  onCloseHandle?: any;
  onCancelHandle?: any;
  onConfirmHandle?: any;
  children: ReactNode;
  className?: string;
  showBackdrop?: boolean;
  canEscape?: boolean;
  showCloseButtonHeader?: boolean;
  showCloseButtonFooter?: boolean;
  showCancelButton?: boolean;
  showConfirmButton?: boolean;
  closeButtoText?: string;
  cancelButtoText?: string;
  confirmButtoText?: string;
  showFooter?: boolean;
  showHeader?: boolean;
  verticalCentered?: boolean;
  title?: string;
  customHeader?: ReactNode;
  customFooter?: ReactNode;
  size?: SizeModal;
}
export const Modal = ({
  isOpen,
  onCloseHandle,
  children,
  className,
  showBackdrop = true,
  canEscape = true,
  showCloseButtonHeader = true,
  showCloseButtonFooter = true,
  showFooter = true,
  showHeader = true,
  showCancelButton = false,
  showConfirmButton = false,
  closeButtoText = "Fechar",
  cancelButtoText = "Cancelar",
  confirmButtoText = "Confirmar",
  onCancelHandle,
  onConfirmHandle,
  verticalCentered = false,
  size = SizeModal.Normal,
  customHeader,
  customFooter,
  title,
}: ModalProps) => {
  const [isScaling, setIsScaling] = useState(false);
  if (!isOpen) return null;
  const showDefaultHeader = showHeader && !customHeader;
  const showDefaultFooter = showFooter && !customFooter;

  const startScaling = () => {
    setIsScaling(true);
    setTimeout(() => {
      setIsScaling(false);
    }, 300);
  };
  const scalingClass = isScaling ? "modal-static" : "";
  let classes = mapToCssModules(
    classNames(className, "modal fade show", scalingClass),
  );
  let extraClassesDialog = "";
  if (verticalCentered) {
    extraClassesDialog += " modal-dialog-centered";
  }
  if (size !== SizeModal.Normal) {
    switch (size) {
      case SizeModal.Small:
        {
          extraClassesDialog += " modal-sm";
        }
        break;
      case SizeModal.Large:
        {
          extraClassesDialog += " modal-lg";
        }
        break;
      case SizeModal.ExtraLarge:
        {
          extraClassesDialog += " modal-xl";
        }
        break;
      case SizeModal.FullScreen:
        {
          extraClassesDialog += " modal-fullscreen";
        }
        break;
    }
  }
  const handleBackgroundClick = (event) => {
    if (event.target === event.currentTarget) {
      if (onCloseHandle) {
        onCloseHandle();
      } else if (onCancelHandle) {
        onCancelHandle();
      }
    }
  };
  return ReactDOM.createPortal(
    <div
      tabIndex={-1}
      style={{ position: "relative", zIndex: "1050", display: "block" }}
    >
      <div
        className={classes}
        aria-modal
        role="dialog"
        tabIndex={-1}
        onClick={canEscape ? handleBackgroundClick : startScaling}
      >
        <div className={`modal-dialog ${extraClassesDialog}`} role="document">
          <div className="modal-content">
            {showDefaultHeader ? (
              <div className="modal-header">
                <h5 className="modal-title mt-0">{title}</h5>
                {showCloseButtonHeader ? (
                  <button
                    type="button"
                    className="close"
                    onClick={onCloseHandle ?? onCancelHandle}
                  >
                    <span>&times;</span>
                  </button>
                ) : null}
              </div>
            ) : null}

            {customHeader ? customHeader : null}

            <div className="modal-body">{children}</div>
            {showDefaultFooter ? (
              <div className="modal-footer">
                {showCloseButtonFooter ? (
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={onCloseHandle}
                  >
                    {closeButtoText}
                  </button>
                ) : null}
                {showCancelButton ? (
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={onCancelHandle}
                  >
                    {cancelButtoText}
                  </button>
                ) : null}
                {showConfirmButton ? (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={onConfirmHandle}
                  >
                    {confirmButtoText}
                  </button>
                ) : null}
              </div>
            ) : null}

            {customFooter ? customFooter : null}
          </div>
        </div>
      </div>
      {showBackdrop ? <div className="modal-backdrop fade show"></div> : null}
    </div>,
    document.body,
  );
};
