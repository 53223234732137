import { combineReducers } from "redux";

import layoutReducer from "./Layout/reducer";

// Forget Password
import forgetPasswordReducer from "./Auth/Forgetpwd/reducer";

// User Profile
import profileReducer from "./Auth/Profile/reducer";

import autenticacaoReducer from "./Auth/reducer";

import acaoListaReducer from "./AcaoLista/reducer";

import notifacaoReducer from "./Notificacao/reducer";

const rootReducer = combineReducers({
  autenticacao: autenticacaoReducer,
  acaoLista: acaoListaReducer,
  Layout: layoutReducer,
  forgetPassword: forgetPasswordReducer,
  profile: profileReducer,
  notificacao: notifacaoReducer,
});

export default rootReducer;
