import React from "react";

//import withRouter
import withRouter from "../../Common/withRouter";

// users
//redux
import { Link } from "react-router-dom";
import { DropdownToggle, DropdownMenu, Dropdown } from "src/components";
import { Icon } from "@ailibs/feather-react-ts";
import SimpleBar from "simplebar-react";
import { ModuloProps, menuItems } from "src/components/CommonForBoth/Menu";
import { AdminUtil } from "src/util/AdminUtil";

const FavoritoMenu = (props: any) => {
  const listaFavorito = AdminUtil.obterFavoritos();
  const modulosComFuncoes: ModuloProps[] = [];

  for (const grupo of menuItems) {    
    if (grupo.modulos) {      
      for (const modulo of grupo.modulos) {
        if (modulo.funcoes && modulo.funcoes.length > 0) {
          modulosComFuncoes.push(modulo);
        }
      }
    }
  }

  const listaMenu = modulosComFuncoes
    .map((modulo) => ({
      id: modulo.id,
      icon: modulo.icon,
      label: modulo.label,
      funcoes: modulo.funcoes.filter((funcao) =>
        listaFavorito.includes(funcao.id)
      ),
    }))
    .filter((item) => item.funcoes.length > 0);

  if (listaMenu.length === 0) {
    return <></>;
  }
  return (
    <React.Fragment>
      <Dropdown>
        <DropdownToggle className="btn header-item noti-icon d-none d-lg-block">
          <Icon name="star" className="icon-sm" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <SimpleBar style={{ height: "270px" }}>
            {listaMenu.map((modulo) => (
              <React.Fragment key={modulo.id}>
                <h5 className="text-center favorito-grupo-titulo">
                  <i className={`mdi ${modulo.icon}`}></i>
                  {modulo.label}
                </h5>
                {modulo.funcoes.map((item, key) => (
                  <React.Fragment key={key}>
                    <Link
                      className="notification-item text-reset"
                      to={item.link}
                    >
                      <div className="d-flex border-bottom align-items-center">
                        <div className="flex-shrink-0">
                          <i className={`mdi mdi-24px ${item.icon}`}></i>
                        </div>
                        <div className="flex-grow-1 ms-4">
                          <h6 className="mb-0">{item.label}</h6>
                          <p className="font-size-12 text-muted mb-0">
                            {item.description}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withRouter(FavoritoMenu);
