const ImageTypes = {
  JPEG: "image/jpeg",
  JPG: "image/jpg",
  PNG: "image/png",
  TIFF: "image/tiff",
};

Object.freeze(ImageTypes); // Evita que o enumerador seja modificado

export default ImageTypes;
