export const mapEnumValorParaDescricao = (enumerador, valor) => {
  const enumMap = enumerador.reduce((map, item) => {
    map[item.id] = item.descricao;
    return map;
  }, {});

  return enumMap[valor] || "";
};

export const mapEnumValorParaBadge = (enumerador, valor) => {
  const enumMap = enumerador.reduce((map, item) => {
    map[item.id] = item.badge;
    return map;
  }, {});

  return enumMap[valor] || "";
};

export const getEnum = (enumerador, valor) => {
  const enumObject = enumerador.find((filter) => filter.id === valor);
  return enumObject;
};

export enum TipoEdicaoRegistro {
  novo = 1,
  editar = 2,
  delete = 3,
}
export enum TipoSituacaoOrdemServico {
  Editando = 1,
  AguardandoAutorizacao = 2,
  Autorizado = 3,
  NaoIniciado = 10,
  Iniciado = 11,
  Pausado = 12,
  Finalizado = 13,
  AguardandoConferencia = 20,
  Reprovado = 21,
  Aprovado = 22,
  Liberado = 98,
  Cancelado = 99,
  Faturado = 100,
}
export enum TipoSituacaoOrcamento {
  Editando = 1,
  AguardandoAprovacacao = 2,
  Expirado = 3,
  Cancelado = 4,
  Reprovado = 5,
  Aprovado = 6,
}

export enum TipoParametro {
  Texto = 1,
  Data = 2,
  Email = 3,
  Consulta = 4,
  Inteiro = 5,
  Numero = 6,
  TextoLongo = 7,
  Senha = 8,
  SimNao = 9,
  Opcoes = 10,
  Monetario = 11,
  Percentual = 12,
  Telefone = 13,
  TelefoneMovel = 14,
  PesquisaRapida = 15,
  MultiplaSelecao = 16,
}

export enum TipoTamanhoColuna {
  Nenhum = 0,
  Tamanho1 = 1,
  Tamanho2 = 2,
  Tamanho3 = 3,
  Tamanho4 = 4,
  Tamanho5 = 5,
  Tamanho6 = 6,
  Tamanho7 = 7,
  Tamanho8 = 8,
  Tamanho9 = 9,
  Tamanho10 = 10,
  Tamanho11 = 11,
  Tamanho12 = 12,
}

export const situacaoEnum = [
  {
    id: 1,
    descricao: "Ativo",
    badge: "badge-primary-subtle",
  },
  {
    id: 2,
    descricao: "Inativo",
    badge: "badge-warning-subtle",
  },
  {
    id: 3,
    descricao: "Todos",
    badge: "badge-success-subtle",
  },
];

export const pessoaEnum = [
  {
    id: 1,
    descricao: "Física",
  },
  {
    id: 2,
    descricao: "Jurídica",
  },
];

export const estadoEnum = [
  {
    id: "AC",
    descricao: "Acre",
  },
  {
    id: "AL",
    descricao: "Alagoas",
  },
  {
    id: "AP",
    descricao: "Amapá",
  },
  {
    id: "AM",
    descricao: "Amazonas",
  },
  {
    id: "BA",
    descricao: "Bahia",
  },
  {
    id: "CE",
    descricao: "Ceará",
  },
  {
    id: "DF",
    descricao: "Distrito Federal",
  },
  {
    id: "ES",
    descricao: "Espírito Santo",
  },
  {
    id: "GO",
    descricao: "Goiás",
  },
  {
    id: "MA",
    descricao: "Maranhão",
  },
  {
    id: "MT",
    descricao: "Mato Grosso",
  },
  {
    id: "MS",
    descricao: "Mato Grosso do Sul",
  },
  {
    id: "MG",
    descricao: "Minas Gerais",
  },
  {
    id: "PA",
    descricao: "Pará",
  },
  {
    id: "PB",
    descricao: "Paraíba",
  },
  {
    id: "PR",
    descricao: "Paraná",
  },
  {
    id: "PE",
    descricao: "Pernambuco",
  },
  {
    id: "PI",
    descricao: "Piauí",
  },
  {
    id: "RJ",
    descricao: "Rio de Janeiro",
  },
  {
    id: "RN",
    descricao: "Rio Grande do Norte",
  },
  {
    id: "RS",
    descricao: "Rio Grande do Sul",
  },
  {
    id: "RO",
    descricao: "Rondônia",
  },
  {
    id: "RR",
    descricao: "Roraima",
  },
  {
    id: "SC",
    descricao: "Santa Catarina",
  },
  {
    id: "SP",
    descricao: "São Paulo",
  },
  {
    id: "SE",
    descricao: "Sergipe",
  },
  {
    id: "TO",
    descricao: "Tocantins",
  },
];
export const estadoIBGEEnum = [
  {
    id: 12,
    descricao: "Acre",
  },
  {
    id: 27,
    descricao: "Alagoas",
  },
  {
    id: 13,
    descricao: "Amapá",
  },
  {
    id: 16,
    descricao: "Amazonas",
  },
  {
    id: 29,
    descricao: "Bahia",
  },
  {
    id: 23,
    descricao: "Ceará",
  },
  {
    id: 53,
    descricao: "Distrito Federal",
  },
  {
    id: 32,
    descricao: "Espírito Santo",
  },
  {
    id: 52,
    descricao: "Goiás",
  },
  {
    id: 21,
    descricao: "Maranhão",
  },
  {
    id: 51,
    descricao: "Mato Grosso",
  },
  {
    id: 50,
    descricao: "Mato Grosso do Sul",
  },
  {
    id: 31,
    descricao: "Minas Gerais",
  },
  {
    id: 15,
    descricao: "Pará",
  },
  {
    id: 25,
    descricao: "Paraíba",
  },
  {
    id: 41,
    descricao: "Paraná",
  },
  {
    id: 26,
    descricao: "Pernambuco",
  },
  {
    id: 22,
    descricao: "Piauí",
  },
  {
    id: 33,
    descricao: "Rio de Janeiro",
  },
  {
    id: 24,
    descricao: "Rio Grande do Norte",
  },
  {
    id: 43,
    descricao: "Rio Grande do Sul",
  },
  {
    id: 11,
    descricao: "Rondônia",
  },
  {
    id: 14,
    descricao: "Roraima",
  },
  {
    id: 42,
    descricao: "Santa Catarina",
  },
  {
    id: 35,
    descricao: "São Paulo",
  },
  {
    id: 28,
    descricao: "Sergipe",
  },
  {
    id: 17,
    descricao: "Tocantins",
  },
];
export const contatoEnum = [
  {
    id: 1,
    descricao: "Outro",
  },
  {
    id: 2,
    descricao: "Gerente",
  },
  {
    id: 3,
    descricao: "Diretor",
  },
];

export const portalEnum = [
  {
    id: 1,
    descricao: "DNIT",
    engenheiro: true,
    titulo: "Departamento Nacional de Infraestrutura de Transportes",
  },
  {
    id: 2,
    descricao: "SINFRA",
    engenheiro: true,
    titulo: "Secretaria de Estado de Infraestrutura e Logística - Mato Grosso",
  },
  {
    id: 3,
    descricao: "DEINFRA",
    engenheiro: false,
    titulo: "Departamento Estadual de Infraestrutura de Santa Catarina",
  },
  {
    id: 4,
    descricao: "GOINFRA",
    engenheiro: true,
    titulo: "Agência Goiana de Infraestrutura e Transportes",
  },
  {
    id: 5,
    descricao: "DERBA",
    engenheiro: false,
    titulo: "Departamento de Estradas de Rodagem da Bahia",
  },
  {
    id: 6,
    descricao: "DERSP",
    engenheiro: true,
    titulo: "Departamento de Estradas de Rodagem de São Paulo",
  },
  {
    id: 7,
    descricao: "DEERMG",
    engenheiro: false,
    titulo: "Departamento de Edificações e Estradas de Rodagem de Minas Gerais",
  },
  {
    id: 8,
    descricao: "DERES",
    engenheiro: false,
    titulo: "Departamento de Edificações e de Rodovias do Espírito Santo",
  },
  {
    id: 9,
    descricao: "DERRJ",
    engenheiro: false,
    titulo: "Departamento de Estradas de Rodagem do Estado do Rio de Janeiro",
  },
  {
    id: 10,
    descricao: "DERSE",
    engenheiro: true,
    titulo: "Departamento Estadual de Infraestrutura Rodoviária de Sergipe",
  },
  {
    id: 11,
    descricao: "DERPR",
    engenheiro: false,
    titulo: "Departamento de Estradas de Rodagem do Paraná",
  },
  {
    id: 12,
    descricao: "AGESUL",
    engenheiro: false,
    titulo:
      "Agencia Estadual de Gestão de Empreendimentos - Mato Grosso do Sul",
  },
  {
    id: 13,
    descricao: "AGETO",
    engenheiro: false,
    titulo:
      "Agência Tocantinense de Transportes e Obras do Estado do Tocantins",
  },
  {
    id: 14,
    descricao: "DAERRS",
    engenheiro: false,
    titulo: "Departamento Autônomo de Estradas de Rodagem do Rio Grande do Sul",
  },
  {
    id: 15,
    descricao: "DERAL",
    engenheiro: false,
    titulo: "Departamento de Estradas de Rodagem do Estado de Alagoas",
  },
  {
    id: 16,
    descricao: "DERPE",
    engenheiro: false,
    titulo: "Departamento de Estradas de Rodagem de Pernambuco",
  },

  {
    id: 17,
    descricao: "SETRAN",
    engenheiro: false,
    titulo: "Secretaria Executiva de Transportes - Pará",
  },
];

export const tituloParcelaEnum = [
  {
    id: 1,
    descricao: "Pagamento Único",
  },
  {
    id: 2,
    descricao: "Mensal",
  },
  {
    id: 3,
    descricao: "Flexível",
  },
];

export const simNaoEnum = [
  {
    id: false,
    descricao: "Não",
  },
  {
    id: true,
    descricao: "Sim",
  },
];
export const simNaoTodosEnum = [
  {
    id: 0,
    descricao: "Todos",
  },
  {
    id: 1,
    descricao: "Sim",
  },
  {
    id: 2,
    descricao: "Não",
  },
];
export const fusoHorarioEnum = [
  {
    id: "Central Brazilian Standard Time",
    descricao: "Horário de Brasília",
  },
  {
    id: "UTC-02",
    descricao: "Horário Fernando de Noronha",
  },
  {
    id: "Central Standard Time",
    descricao: "Horário Rio Branco",
  },
  {
    id: "Eastern Standard Time",
    descricao: "Horário Manaus",
  },
];

export const motivoEnum = [
  {
    id: 1,
    descricao: "Cancelar Ordem",
  },
  {
    id: 2,
    descricao: "Cancelar Contas a Receber",
  },
  {
    id: 3,
    descricao: "Cancelar Contas a Pagar",
  },
];

export const servicoEnum = [
  {
    id: 1,
    descricao: "AET Federal",
  },
  {
    id: 2,
    descricao: "AET Estadual",
  },
  {
    id: 3,
    descricao: "ANTT",
  },
  {
    id: 4,
    descricao: "Projeto Técnico",
  },
];

export const categoriaEnum = [
  {
    id: 1,
    descricao: "Debito",
    badge: "badge-danger-subtle",
  },
  {
    id: 2,
    descricao: "Credito",
    badge: "badge-success-subtle",
  },
];

export const veiculoEnum = [
  {
    id: 1,
    descricao: "Trator",
  },
  {
    id: 2,
    descricao: "Reboque",
  },
  {
    id: 3,
    descricao: "Semi-Reboque",
  },
  {
    id: 4,
    descricao: "Dolly",
  },
  {
    id: 5,
    descricao: "Caminhão",
  },
  {
    id: 6,
    descricao: "Mec. Operacional",
  },
  {
    id: 7,
    descricao: "Guindaste",
  },
];

export const situacaoInviteEnum = [
  {
    id: 1,
    descricao: "Enviado",
    badge: "badge-primary-subtle",
  },
  {
    id: 2,
    descricao: "Aceito",
    badge: "badge-success-subtle",
  },
  {
    id: 3,
    descricao: "Recusado",
    badge: "badge-warning-subtle",
  },
  {
    id: 4,
    descricao: "Expirado",
    badge: "badge-danger-subtle",
  },
];

export const metodoPagamentoEnum = [
  {
    id: 1,
    descricao: "Dinheiro",
  },
  {
    id: 2,
    descricao: "Pix",
  },
  {
    id: 3,
    descricao: "Transferencia Bancaria",
  },
  {
    id: 4,
    descricao: "Cartao de Credito",
  },
  {
    id: 5,
    descricao: "Cartao de Debito",
  },
  {
    id: 6,
    descricao: "Boleto",
  },
];

export const contaEnum = [
  {
    id: 1,
    descricao: "Corrente",
  },
  {
    id: 2,
    descricao: "Poupanca",
  },
];

export const planoPagamentoEnum = [
  {
    id: 1,
    descricao: "A Vista",
  },
  {
    id: 2,
    descricao: "A Prazo",
  },
];
export const descontoAcrescimoEnum = [
  {
    id: 1,
    descricao: "Desconto",
  },
  {
    id: 2,
    descricao: "Acrescimo",
  },
];
export const entradaEnum = [
  {
    id: 1,
    descricao: "Fixa",
  },
  {
    id: 2,
    descricao: "Flexível",
  },
];
export const quantidadeParcelaEnum = [];
export const diaFixoEnum = [];

export const ordemEnum = [
  {
    id: 1,
    descricao: "Normal",
  },
  {
    id: 2,
    descricao: "Bonificada",
  },
];

export const ordemSituacaoEnum = [
  {
    id: 1,
    descricao: "Editando",
    badge: "editando-badge",
  },
  {
    id: 2,
    descricao: "Aguardando Autorização",
    badge: "aguardando-autorizacao-badge",
  },
  {
    id: 3,
    descricao: "Autorizado",
    badge: "autorizado-badge",
  },
  {
    id: 10,
    descricao: "Não Iniciado",
    badge: "nao-iniciado-badge",
  },
  {
    id: 11,
    descricao: "Iniciado",
    badge: "iniciado-badge",
  },
  {
    id: 12,
    descricao: "Pausado",
    badge: "pausado-badge",
  },
  {
    id: 13,
    descricao: "Finalizado",
    badge: "finalizado-badge",
  },
  {
    id: 20,
    descricao: "Aguardando Conferência",
    badge: "aguardando-conferencia-badge",
  },
  {
    id: 21,
    descricao: "Reprovado",
    badge: "reprovado-badge",
  },
  {
    id: 22,
    descricao: "Aprovado",
    badge: "aprovado-badge",
  },
  {
    id: 98,
    descricao: "Liberado",
    badge: "liberado-badge",
  },
  {
    id: 99,
    descricao: "Cancelado",
    badge: "cancelado-badge",
  },
  {
    id: 100,
    descricao: "Faturado",
    badge: "faturado-badge",
  },
];

export const responsavelFinanceiroEnum = [
  {
    id: true,
    descricao: "O Próprio Cliente",
  },
  {
    id: false,
    descricao: "Outro Cliente",
  },
];

export const timeEnum = [
  {
    id: 1,
    descricao: "Supervisor",
  },
  {
    id: 2,
    descricao: "Agente",
  },
];

export const novaEmpresaEnum = [
  {
    id: 1,
    descricao: "Vou utilizar em meu nome",
  },
  {
    id: 2,
    descricao: "Vou utilizar em nome da minha empresa",
  },
];

export const emailCadastradoEnum = [
  {
    id: 1,
    descricao:
      "Vou utilizar este mesmo e-mail e irei adicionar este novo cadastro como um novo acesso\\permissão",
  },
  {
    id: 2,
    descricao: "Vou informar um novo e-mail",
  },
];

export const parcelamentoEnum = [];

export const direcaoEnum = [
  {
    id: 1,
    descricao: "Hidráulica",
  },
  {
    id: 2,
    descricao: "Mecânica",
  },
];
export const tracaoEnum = [
  {
    id: 1,
    descricao: "Simples 4X2",
  },
  {
    id: 2,
    descricao: "Simples 4x4",
  },
  {
    id: 3,
    descricao: "Simples 6X2",
  },
  {
    id: 4,
    descricao: "Simples 8X2",
  },
  {
    id: 5,
    descricao: "Dupla 6X4",
  },
  {
    id: 6,
    descricao: "Dupla 8X4",
  },
  {
    id: 7,
    descricao: "Tripla 8X6",
  },
  {
    id: 8,
    descricao: "Quádrupla 8X8",
  },
];
export const carroceriaEnum = [
  { id: 1, descricao: "Aberta" },
  { id: 2, descricao: "Basculante" },
  { id: 3, descricao: "Boiadeiro" },
  { id: 4, descricao: "Cana de Açúcar" },
  { id: 5, descricao: "Container" },
  { id: 6, descricao: "Dolly" },
  { id: 7, descricao: "Fechada" },
  { id: 8, descricao: "Guindaste" },
  { id: 9, descricao: "Mecânico Operacional" },
  { id: 10, descricao: "Plataforma" },
  { id: 11, descricao: "Prancha" },
  { id: 12, descricao: "Prancha - Cegonha" },
  { id: 13, descricao: "Prancha Extensiva" },
  { id: 14, descricao: "Rodo Caçamba" },
  { id: 15, descricao: "Rollon Rollof" },
  { id: 16, descricao: "Silo" },
  { id: 17, descricao: "Subestação Móvel" },
  { id: 18, descricao: "Tanque" },
  { id: 19, descricao: "TransTora" },
  { id: 20, descricao: "VTAV" },
];

export const tituloSituacaoEnum = [
  {
    id: 1,
    descricao: "A Vencer",
    badge: "avencer-badge",
  },
  {
    id: 2,
    descricao: "Atrasado",
    badge: "atrasado-badge",
  },
  {
    id: 4,
    descricao: "Pago",
    badge: "pago-badge",
  },
  {
    id: 8,
    descricao: "Estornado",
    badge: "estornado-badge",
  },
  {
    id: 16,
    descricao: "Cancelado",
    badge: "cancelado-badge",
  },
];

export const diferencaTituloEnum = [
  {
    id: 1,
    descricao:
      "Deverá ser considerado um desconto financeiro o montante restante",
  },
  {
    id: 2,
    descricao: "Vou gerar um novo título com a diferença de valor",
  },
];
export const RNTRCEnum = [
  {
    id: 1,
    descricao: "TAC",
  },
  {
    id: 2,
    descricao: "ETC",
  },
  {
    id: 3,
    descricao: "CTC",
  },
];
export const carretaEnum = [
  {
    id: 1,
    descricao: "Reboque",
  },
  {
    id: 2,
    descricao: "Semi-Reboque",
  },
  {
    id: 3,
    descricao: "Dolly",
  },
];
export const tratorEnum = [
  {
    id: 1,
    descricao: "Trator",
  },
  {
    id: 2,
    descricao: "Caminhão",
  },
  {
    id: 3,
    descricao: "Guindaste",
  },
];
export const corEnum = [
  {
    id: 1,
    descricao: "Azul",
  },
  {
    id: 2,
    descricao: "Amarelo",
  },
  {
    id: 3,
    descricao: "Branco",
  },
  {
    id: 4,
    descricao: "Laranja",
  },
  {
    id: 5,
    descricao: "Cinza",
  },
  {
    id: 6,
    descricao: "Preto",
  },
  {
    id: 7,
    descricao: "Verde",
  },
  {
    id: 8,
    descricao: "Vermelho",
  },
  {
    id: 9,
    descricao: "Prata",
  },
  {
    id: 10,
    descricao: "Rosa",
  },
  {
    id: 11,
    descricao: "Roxo",
  },
  {
    id: 12,
    descricao: "Marrom",
  },
  {
    id: 13,
    descricao: "Bege",
  },
  {
    id: 14,
    descricao: "Dourada",
  },
  {
    id: 15,
    descricao: "Fantasia",
  },
];
export const orcamentoSituacaoEnum = [
  {
    id: 1,
    descricao: "Editando",
    badge: "editando-badge",
  },
  {
    id: 2,
    descricao: "Aguardando Aprovacao",
    badge: "aguardando-aprovacao-badge",
  },
  {
    id: 3,
    descricao: "Expirado",
    badge: "expirado-badge",
  },
  {
    id: 4,
    descricao: "Cancelado",
    badge: "cancelado-badge",
  },
  {
    id: 5,
    descricao: "Reprovado",
    badge: "reprovado-badge",
  },
  {
    id: 6,
    descricao: "Aprovado",
    badge: "aprovado-badge",
  },
];

export const notificacaoEnum = [
  {
    id: 1,
    descricao: "Execução de AET Iniciada",
    badge: "badge-success-subtle",
    badgeDescription: "Iniciado",
  },
];
export const situacaoOrgaoEnum = [
  {
    id: 1,
    descricao: "Aguardando Cadastro",
    badge: "aguardando-cadastro-badge",
  },
  {
    id: 2,
    descricao: "Cadastro Temporário",
    badge: "cadastro-temporario-badge",
  },
  {
    id: 3,
    descricao: "Aguardando Análise do Engenheiro",
    badge: "em-analise-badge",
  },
  {
    id: 4,
    descricao: "Aguardando Pagamento",
    badge: "aguardando-pagamento-badge",
  },
  {
    id: 4,
    descricao: "Aguardando Engenheiro",
    badge: "aguardando-engenheiro-badge",
  },
  {
    id: 5,
    descricao: "Aguardando Documentação",
    badge: "aguardando-documentacao-badge",
  },
  {
    id: 6,
    descricao: "Aguardando Formação de Comboio",
    badge: "aguardando-formacao-comboio-badge",
  },
  {
    id: 19,
    descricao: "Pendente",
    badge: "pendente-badge",
  },
  {
    id: 20,
    descricao: "Liberado",
    badge: "liberado-badge",
  },
];
