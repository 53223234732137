import { Util } from "src/util";

export const ValidacaoHelper = {
  CPFValido: function (cpf) {
    cpf = cpf ? Util.numberOnly(cpf) : "";
    if (
      !cpf ||
      cpf.length !== 11 ||
      cpf === "00000000000" ||
      cpf === "11111111111" ||
      cpf === "22222222222" ||
      cpf === "33333333333" ||
      cpf === "44444444444" ||
      cpf === "55555555555" ||
      cpf === "66666666666" ||
      cpf === "77777777777" ||
      cpf === "88888888888" ||
      cpf === "99999999999"
    )
      return false;
    var soma = 0;
    var resto;
    for (var i = 1; i <= 9; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(9, 10))) return false;
    soma = 0;
    for (var i = 1; i <= 10; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(10, 11))) return false;
    return true;
  },
  CNPJValido: function (cnpj) {
    // Remove todos os caracteres não numéricos
    cnpj = cnpj ? cnpj.replace(/\D/g, "") : "";

    // Verifica o comprimento e se é uma sequência de dígitos iguais
    if (cnpj.length !== 14 || /^(\d)\1+$/.test(cnpj)) {
      return false;
    }

    // Calcula os dígitos verificadores
    const calcularDigito = (cnpj, tamanho) => {
      let soma = 0;
      let pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += cnpj.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      const resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      return resultado;
    };

    // Verifica o primeiro dígito verificador
    let tamanho = 12;
    let digito1 = calcularDigito(cnpj, tamanho);
    if (digito1 != cnpj.charAt(tamanho)) {
      return false;
    }

    // Verifica o segundo dígito verificador
    tamanho = 13;
    let digito2 = calcularDigito(cnpj, tamanho);
    if (digito2 != cnpj.charAt(tamanho)) {
      return false;
    }

    return true;
  },
  EmailValido: function (email) {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(String(email));
  },
};
