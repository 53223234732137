import { createSlice } from "@reduxjs/toolkit";

interface AcaoListaStateType {
  removerTabela?: any;
  removerServidor?: any;
  inativarRegistro?: any;
  reativarRegistro?: any;
}
export const initialState: AcaoListaStateType = {
  removerTabela: "",
  removerServidor: "",
  inativarRegistro: "",
  reativarRegistro: "",
};

const acaoListaSlice = createSlice({
  name: "acaolista",
  initialState,
  reducers: {
    removerRegistroTabela(state, action) {
      state.removerTabela = action.payload;
    },
    removerRegistroServidor(state, action) {
      state.removerServidor = action.payload;
    },
    atualizarStatusRegistro(state, action) {
      const { status } = action.payload;
      if (status === 1) {
        state.reativarRegistro = action.payload;
      } else {
        state.inativarRegistro = action.payload;
      }
    },
  },
});

export const {
  removerRegistroTabela,
  removerRegistroServidor,
  atualizarStatusRegistro,
} = acaoListaSlice.actions;

export default acaoListaSlice.reducer;
